import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import {
    ANALYTICS_ACTIONS,
    ANALYTICS_ASSETS,
    ERROR_CODES, EVENT_SOURCE_NAME, IMAGE_PATHS, KEY_CODES, LOGIN_SOURCE,
} from '@airtel-tv/constants';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import {
    CryptoUtil, LanguageProviderUtil, LocationUtil, getCurrentWindowSourceName,
} from '@airtel-tv/utils';
import { validateOTPInput } from '@airtel-tv/utils/GlobalUtil';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { useNavigate } from 'react-router';
import useStyles from 'isomorphic-style-loader/useStyles';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import { screenVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import {
    isUserOnline, triggerLogin, updateTvLoginStatus, validateTvQrCode,
} from '../AuthAction';
import { disableScrollOnWheel } from '../../../utilities/CommonUtil';
import LoginSuccess from './LoginSuccess';
import styles from './QrCodeLogin.scss';
import VIEWS from '../../../constants/ViewConst';

const IMAGE_SETTINGS = { 300: 15 };
const QrCodeConfirmation = (props) => {
    const {
        isAuthenticated, triggerLoginDispatch, qrId, validateTvQrCodeDispatch, msisdn, token, otp, tvLoginStatus, updateTvLoginStatusDispatch, footerRight, queryParams,
    } = props;
    const LANGUAGE = LanguageProviderUtil.getLanguage();
    const {
        TV_LOGIN = 'Tv Login',
        CONFIRM = 'Confirm',
        ENTER_TV_CODE = 'Enter TV code displayed on your TV app to login',
    } = LANGUAGE;
    useStyles(styles);

    const QR_ID_LENGTH = 8;
    const QR_ID_REG = /^\d{8}$/;
    const INPUT_REG = /^\d{0,1}$/;
    const errorMessage = LANGUAGE[tvLoginStatus?.errorcode] || tvLoginStatus.errortitle;

    const [
        qrCode,
        setQrCode,
    ] = useState(new Array(QR_ID_LENGTH).fill(''));
    const [
        isTvLoggedIn,
        setIsTvLoggedIn,
    ] = useState(false);

    // const inputRefs = useRef([]);

    // const [
    //     activeIndex,
    //     setActiveIndex,
    // ] = useState(-100);
    const navigate = useNavigate();

    const sourcePage = useMemo(() => SOSUtil.getRouteSourceNameMappedFromCMS(getCurrentWindowSourceName()), []);

    useEffect(() => {
        const { success, secondaryDeviceLoginSuccess, deviceLimitExceeded } = tvLoginStatus;
        const isSuccess = success && secondaryDeviceLoginSuccess && deviceLimitExceeded === false;
        setIsTvLoggedIn(isSuccess);
    }, [
        tvLoginStatus,
    ]);

    const navigateHome = useCallback(() => {
        if (isTvLoggedIn) {
            updateTvLoginStatusDispatch({});
            navigate('/');
        }
    }, [
        isTvLoggedIn,
    ]);

    const msisdnNo = useMemo(() => {
        let msisdnDecrypt;
        if (msisdn && token) {
            msisdnDecrypt = CryptoUtil.AESDecryptMsdin({
                key: token,
                value: msisdn,
            });
            msisdnDecrypt = msisdnDecrypt.replace('+91', '');
        }
        return msisdnDecrypt;
    }, [
        msisdn,
        token,
    ]);

    useEffect(() => {
        if (qrId && qrId?.length === QR_ID_LENGTH && QR_ID_REG.test(qrId)) {
            setQrCode(qrId.split(''));
            // setActiveIndex(-100);
        }
        else {
            // setActiveIndex(0);
            // inputRefs?.current[0]?.focus();
        }
    }, [
        qrId,
    ]);

    useEffect(() => {
        if (!isAuthenticated) {
            triggerLoginDispatch({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.QR_CODE_LOGIN,
            });
        }
        else {
            updateTvLoginStatusDispatch({});
        }
    }, [
        isAuthenticated,
    ]);

    const handleQrCodeEntered = (e, index) => {
        const newCode = [
            ...qrCode,
        ];
        let { value } = e.target;
        value = value?.slice(-1);
        if (value?.length < 2 && INPUT_REG.test(value)) {
            updateTvLoginStatusDispatch({});
            newCode[index] = value;
            setQrCode(newCode);
            if (index < (QR_ID_LENGTH - 1) && e.target.value !== '') {
                if (e.keyCode === KEY_CODES.BACKSPACE || e.keyCode === KEY_CODES.DELETE) {
                    e.target?.previousSibling?.focus();
                }
                else {
                    e.target?.nextSibling?.focus();
                }
                // setActiveIndex(index + 1);
                // inputRefs?.current[index + 1]?.focus();
            }
            else if (index === QR_ID_LENGTH - 1 && e.target.value !== '') {
                // setActiveIndex('confirm-qr-code');
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.keyCode === KEY_CODES.DECIMAL_POINT || e.keyCode === KEY_CODES.UNIDENTIFIED) {
            e.preventDefault();
            return;
        }
        if (e.keyCode === KEY_CODES.UP_ARROW || e.keyCode === KEY_CODES.DOWN_ARROW) {
            e.preventDefault();
            return;
        }

        if (e.keyCode === KEY_CODES.TAB) {
            return;
        }

        if (e.keyCode === KEY_CODES.RIGHT_ARROW) {
            // prevent non nums
            const { nextSibling } = e.target;
            if (nextSibling) {
                nextSibling.focus();
            }

            e.preventDefault();
            return;
        }

        if (e.keyCode === KEY_CODES.LEFT_ARROW) {
            const { previousSibling } = e.target;
            if (previousSibling) {
                previousSibling.focus();
            }

            e.preventDefault();
            return;
        }

        if (!((e.keyCode >= KEY_CODES.NUMPAD_0 && e.keyCode <= KEY_CODES.NUMPAD_9) || (e.keyCode >= KEY_CODES.NUM0 && e.keyCode <= KEY_CODES.NUM9) || e.keyCode === KEY_CODES.BACKSPACE
            || e.keyCode === KEY_CODES.DELETE || e.keyCode === KEY_CODES.V || e.keyCode === KEY_CODES.C || e.keyCode === KEY_CODES.A
            || e.keyCode === KEY_CODES.X || (e.keyCode >= KEY_CODES.LEFT_ARROW && e.keyCode <= KEY_CODES.DOWN_ARROW))) {
            // prevent non nums
            e.preventDefault();
            return;
        }

        if (e.keyCode === KEY_CODES.BACKSPACE || e.keyCode === KEY_CODES.DELETE) {
            if (e.target.value) {
                e.target.value = '';
                handleQrCodeEntered(e, index);
            }
            else if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }

        if (e.target.value.length > 0) {
            // clear previous data as opt can has only 1 num in 1 text box
            e.target.value = e.key;
        }
        // let updateIndex;
        // if (e.keyCode === KEY_CODES.UP_ARROW || e.keyCode === KEY_CODES.DOWN_ARROW) {
        //     e.preventDefault();
        //     return;
        // }
        // if (e.keyCode === KEY_CODES.RIGHT_ARROW && index < QR_ID_LENGTH - 1) {
        //     updateIndex = index + 1;
        // }
        // else if (e.keyCode === KEY_CODES.LEFT_ARROW && index >= 0) {
        //     updateIndex = index - 1;
        //     e.preventDefault();
        // }
        // else if (e.keyCode === KEY_CODES.BACKSPACE && index > 0 && qrCode[index] === '') {
        //     updateIndex = index - 1;
        // }
        // else if (e.shiftKey && e.keyCode === KEY_CODES.TAB) {
        //     updateIndex = index;
        // }

        // if (updateIndex >= 0) {
        //     setActiveIndex(updateIndex);
        //     inputRefs?.current[updateIndex]?.focus();
        // }
    };

    const confirmQrCode = (e = {}) => {
        const { event } = e;
        if (event?.shiftKey && event?.keyCode === KEY_CODES.TAB) {
            // setActiveIndex(QR_ID_LENGTH - 1);
            // inputRefs?.current[QR_ID_LENGTH - 1]?.focus();
            event.preventDefault();
            return;
        }
        if (event?.keyCode && event?.keyCode !== KEY_CODES.ENTER) {
            event?.preventDefault();
            return;
        }
        const code = qrCode.join('');
        if (code?.length === QR_ID_LENGTH && QR_ID_REG.test(code)) {
            validateTvQrCodeDispatch({
                otp,
                isMultiDeviceLogin: true,
                qrLoginPayload: {
                    msisdn: msisdnNo,
                    qrId: code,
                    requireOtp: !isAuthenticated,
                    primaryDeviceLoginRequired: !isAuthenticated,
                },
            });
        }
    };

    const checkUserOnline = () => {
        const { error, isUserOnlineDispatch } = props;
        if (error === ERROR_CODES.LOCAL1007) {
            isUserOnlineDispatch();
        }
    };

    // const onInputFocus = (e, idx) => {
    //     if (idx >= 0 && idx < QR_ID_LENGTH) {
    //         setActiveIndex(idx);
    //     }
    //     e.preventDefault();
    // };

    // useEffect(() => {
    //     if (activeIndex) {
    //         inputRefs[activeIndex]?.current?.focus();
    //     }
    // }, [
    //     activeIndex,
    // ]);

    const closeTvCodeScreen = () => {
        navigate(ROUTE_PATHS.ROOT);
    };


    useEffect(() => {
        screenVisibleEvent(EVENT_SOURCE_NAME.TV_CODE_SCREEN, false, {
            source_page: sourcePage,
            qr_code: qrId,
        });
        window.addEventListener('online', () => checkUserOnline());

        return () => {
            window.removeEventListener('online', () => checkUserOnline());
        };
    }, []);
    // return null;

    useEffect(() => {
        if (errorMessage) {
            screenVisibleEvent(EVENT_SOURCE_NAME.TV_CODE_SCREEN, false, {
                action: ANALYTICS_ACTIONS.FAIL,
                asset_name: ANALYTICS_ASSETS.ENTER_TV_CODE,
                source_page: sourcePage,
                qr_code: qrCode?.join(''),
            });
        }
    }, [
        errorMessage,
    ]);

    return (
        <div
            className=" qr-login-inner "
        >
            {queryParams?.viewType === VIEWS.WEB_VIEW ? (
                <AnalyticsButtonComponent
                    className="qr-login-cross"
                    id="cross-btn"
                    onClick={closeTvCodeScreen}
                >
                    <ThumborImage
                        src={IMAGE_PATHS.WHITE_CLOSE_BUTTON}
                        imageSettings={IMAGE_SETTINGS}
                    />
                </AnalyticsButtonComponent>
            ) : null}
            {/* use class (disable) when want to disable confirm button */}
            {isTvLoggedIn ? (
                <LoginSuccess
                    sourcePage={sourcePage}
                    qrCode={qrCode?.join('')}
                    navigateHomeCallback={navigateHome}
                />
            ) : (
                <div>
                    <div className="head-title-wrapper">
                        <ThumborImage
                            src={IMAGE_PATHS.TV_LOGIN_ICON}
                        />
                        <span className="login-text">{TV_LOGIN}</span>
                        <span className="tv-code-text">{ENTER_TV_CODE}</span>
                    </div>


                    <div
                        className="form-group form-group--otp qr-login-otp-box"
                    >
                        <div
                            className={`d-flex justify-content-center align-items-center ${errorMessage ? 'error-occurred' : ''}`}
                        >
                            {qrCode.map((value, idx) => (
                                <input
                                    id={`otp-${idx}-letter`}
                                    value={value}
                                    type="number"
                                    autoFocus={idx === 0 && !qrId}
                                    // className={`input-custom input-custom-100 ${activeIndex === idx ? 'focus-active' : ''}`}
                                    className="input-custom input-custom-100"
                                    placeholder=""
                                    autoComplete="off"
                                    // autoFocus={idx === 0} // eslint-disable-line jsx-a11y/no-autofocus
                                    minLength={0}
                                    maxLength={1}
                                    onPaste={e => e.preventDefault()}
                                    onChange={e => handleQrCodeEntered(e, idx)}
                                    onKeyPress={validateOTPInput}
                                    onKeyDown={e => handleKeyDown(e, idx)}
                                    onWheel={disableScrollOnWheel}
                                    // ref={el => (inputRefs.current[idx] = el)}
                                    pattern="[0-9]*"
                                    min={0}
                                    max={9}
                                    // onFocus={e => onInputFocus(e, idx)}
                                />
                            ))}
                        </div>
                        <p className="error-text">{errorMessage}</p>
                        <AnalyticsButtonComponent
                            // className={`button button-link ${qrCode.join('')?.length === QR_ID_LENGTH ? 'active' : ''} ${activeIndex === 'confirm-qr-code' ? 'focus-active' : ''}`}
                            className={`button button-link ${qrCode.join('')?.length === QR_ID_LENGTH ? 'active' : ''}`}
                            id="confirm-qr-code"
                            onClick={confirmQrCode}
                            onKeyDown={confirmQrCode}
                            // focusLink={activeIndex === 'confirm-qr-code'}
                            meta={{
                                source_name: EVENT_SOURCE_NAME.TV_CODE_SCREEN,
                                source_page: sourcePage,
                                action: ANALYTICS_ACTIONS.VERIFY,
                                asset_name: ANALYTICS_ASSETS.ENTER_TV_CODE,
                                qr_code: qrCode?.join(''),
                            }}
                        >
                            <span>
                                {CONFIRM}
                            </span>
                        </AnalyticsButtonComponent>
                    </div>

                </div>
            )}
        </div>
    );
};

QrCodeConfirmation.defaultProps = {
    isAuthenticated: false,
    qrId: '',
    error: {},
    otp: '',
    token: '',
    tvLoginStatus: {},
    footerRight: {},
    queryParams: {},
};

QrCodeConfirmation.propTypes = {
    qrId: PropTypes.string,
    otp: PropTypes.string,
    token: PropTypes.string,
    isAuthenticated: PropTypes.bool,
    error: PropTypes.object,
    footerRight: PropTypes.object,
    tvLoginStatus: PropTypes.object,
    queryParams: PropTypes.object,
    msisdn: PropTypes.string.isRequired,
    triggerLoginDispatch: PropTypes.func.isRequired,
    isUserOnlineDispatch: PropTypes.func.isRequired,
    updateTvLoginStatusDispatch: PropTypes.func.isRequired,
    validateTvQrCodeDispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
    const {
        authConfig: {
            isAuthenticated,
            error,
            token = '',
            tvLoginStatus = {},
        } = {},
        userConfig: {
            userInfo: {
                msisdn = '',
            } = {},
        } = {},
        appConfig: {
            footer = {},
        } = {},
    } = state;
    const {
        footerStatic: {
            footerRight = {},
        } = {},
    } = footer;
    const queryParams = LocationUtil.getQueryParams(props);
    const { match: { params: { qrId = '' } = {} } = {} } = props;
    return {
        isAuthenticated,
        qrId,
        error,
        msisdn,
        token,
        tvLoginStatus,
        footerRight,
        queryParams,
    };
};

export default withRouter(connect(mapStateToProps, {
    triggerLoginDispatch: triggerLogin,
    isUserOnlineDispatch: isUserOnline,
    validateTvQrCodeDispatch: validateTvQrCode,
    updateTvLoginStatusDispatch: updateTvLoginStatus,
})(QrCodeConfirmation));
