import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import React from 'react';
import { getWindowLocation } from '@airtel-tv/utils/WindowUtil';

const LinkComponent = (props) => {
    const {
        children,
        staticContext,
        onClick,
        onMouseEnter,
        id,
        ...rest
    } = props;

    const linkClicked = (e) => {
        const {
            to,
        } = props;

        const location = getWindowLocation();

        if (onClick) {
            onClick(e);
        }

        if (location.pathname + location.search === to) {
            e.preventDefault();
        }
    };


    return (
        <Link
            id={id}
            onClick={linkClicked}
            onMouseEnter={onMouseEnter}
            draggable="false"
            {...rest}
        >
            {children}
        </Link>
    );
};

LinkComponent.defaultProps = {
    onClick: null,
    staticContext: null,
    children: null,
    onMouseEnter: null,
    id: null,
};

LinkComponent.propTypes = {
    children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    staticContext: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    id: PropTypes.string,
};

export default withRouter(LinkComponent);
