import React from 'react';
import PropTypes from 'prop-types';
import LinkComponent from '../link/LinkComponent';
import LanguageProvider from '../../../../providers/LanguageProvider';
import { DateTime } from '@airtel-tv/utils';
import ProgressBarComponent from '../ProgressBarComponent';

const CatchupEpisodeTileComponent = (props) => {
    const { episode } = props;
    const { tileCtaActionUrl, progressPercentage, showProgressBar } = episode;
    // const formattedDate = moment(episode.airDate);
    const formattedDate = new DateTime(episode.airDate).format('dddd');
    const LANGUAGE = LanguageProvider();

    return (
        <LinkComponent to={tileCtaActionUrl}>
            <div className={`listContent w-100 d-block position-relative ${episode.selected ? 'now-playing' : ''} contain-layout`}>
                {/* {!episode.selected ? null : (
                    <span className="now-playing">{LANGUAGE.LABEL_NOW_PLAYING}</span>
                )} */}
                <div className="insideText">
                    {formattedDate}
                </div>
                <div className="overlay">
                    <div className="card-center-play-icon">
                        <i className="icon40 icon-player-play" />
                    </div>
                </div>
                <ProgressBarComponent
                    showProgressBar={showProgressBar}
                    progressPercentage={progressPercentage}
                />
            </div>

            <div className="listFooter pt-2 pb-2 w-100 d-block">
                <h2>{`${LANGUAGE.EPISODE} ${episode.episodeNumber}`}</h2>
            </div>
        </LinkComponent>
    );
};

CatchupEpisodeTileComponent.defaultProps = {
    episode: {},
};

CatchupEpisodeTileComponent.propTypes = {
    episode: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
};

export default React.memo(CatchupEpisodeTileComponent);
