import React from 'react';
import { BUTTON_IMAGE_TITLE } from '@airtel-tv/constants/LayoutConstants';
import LanguageProvider from '../../../providers/LanguageProvider';
import { IMAGE_PATHS } from '../../../constants/AppConst';

const WatchListToggleButtonComponent = (props) => {
    const {
        inWatchList, watchListToggleButtonClick, contentDetails, addToWatchlistAnalyticsMeta,
    } = props;
    const LANGUAGE = LanguageProvider();
    const toggleButton = !inWatchList
        ? (
            <button
                type="button"
                className="watchlist-icon"
                id="add-watchlist"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    watchListToggleButtonClick(contentDetails, addToWatchlistAnalyticsMeta);
                }}
            >
                <img
                    src={IMAGE_PATHS.WATCHLIST_ICONS_ADD}
                    title={BUTTON_IMAGE_TITLE.ADD_TO_WATCHLIST}
                    alt={BUTTON_IMAGE_TITLE.ADD_TO_WATCHLIST}
                    loading="lazy"
                />
                <span>{`${LANGUAGE.ADD_TO_WATCH_LIST}`}</span>
            </button>
        )
        : (
            <button
                type="button"
                className="watchlist-icon"
                id="remove-watchlist"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    watchListToggleButtonClick(contentDetails, addToWatchlistAnalyticsMeta);
                }}
            >
                <img
                    src={IMAGE_PATHS.WATCHLIST_ICONS_REMOVE}
                    title={BUTTON_IMAGE_TITLE.REMOVE_FROM_WATCHLIST}
                    alt={BUTTON_IMAGE_TITLE.REMOVE_FROM_WATCHLIST}
                    loading="lazy"
                />
                <span>{`${LANGUAGE.REMOVE_FROM_WATCH_LIST}`}</span>
            </button>
        );
    return toggleButton;
};

export default WatchListToggleButtonComponent;
