import React from 'react';

const SubscriptionLoaderComponent = () => (
    <div
        className="loader-shimmer subscriction"
        style={{
            display: 'flex',
            justifyContent: 'center',
            background: 'transparent',
        }}
    >
        <div
            className="shimmer-skeleton"
            style={{
                width: '640px',
                padding: '0px 20px 20px',
                background: '#0c0f12',
                height: '100vh',
            }}
        ><div style={{color: '#0c0f12',fontSize: '20px'
    }}>Loading</div>
            <div
                className=" smallbox shimmer"
                style={{
                    width: '100%',
                    margin: '24px 0',
                    height: '40px',
                }}
            />
            <div
                className="bigbox shimmer"
                style={{
                    height: 'auto',
                    minHeight: '600px',
                }}
            />

        </div>
    </div>
);


export default SubscriptionLoaderComponent;
