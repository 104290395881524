import React from 'react';
import PropTypes from 'prop-types';

function PulsingCircle({
    delay, color, viewBoxLength, radiusFrom, radiusTo, radiusInit,
    opacityFrom, opacityTo, iteration, opacityInit, duration,
}) {
    return (
        <circle
            opacity={opacityInit}
            cx={viewBoxLength / 2}
            cy={viewBoxLength / 2}
            r={radiusInit}
            fill={color}
        >
            {radiusFrom !== radiusTo && (
                <animate
                    attributeName="r"
                    from={radiusFrom}
                    to={radiusTo}
                    dur={`${duration}s`}
                    repeatCount={iteration}
                    begin={`${delay}s`}
                />
            )}
            {opacityFrom !== opacityTo && (
                <animate
                    attributeName="opacity"
                    from={opacityFrom}
                    to={opacityTo}
                    dur={`${duration}s`}
                    repeatCount={iteration}
                    begin={`${delay}s`}
                />
            )}
        </circle>
    );
}

PulsingCircle.defaultProps = {
    delay: 0,
    color: '#ED2024',
    radiusFrom: 0,
    radiusTo: 0,
    radiusInit: 0,
    opacityFrom: 1,
    opacityTo: 1,
    opacityInit: 1,
    iteration: 1,
    duration: 1,
};

PulsingCircle.propTypes = {
    delay: PropTypes.number,
    color: PropTypes.string,
    viewBoxLength: PropTypes.number.isRequired,
    radiusFrom: PropTypes.number,
    radiusTo: PropTypes.number,
    radiusInit: PropTypes.number,
    opacityFrom: PropTypes.number,
    opacityTo: PropTypes.number,
    opacityInit: PropTypes.number,
    iteration: PropTypes.number,
    duration: PropTypes.number,
};

export default PulsingCircle;
