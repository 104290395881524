import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { railVisibleEvent, railScrollEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { railVisibleEvent, railScrollEvent, contentVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import IntersectionObservableHOC from './IntersectionObservableHOC';
import { getCurrentWindowSourceName } from '../../../utilities/WindowUtil';
import UserInfoUtil from '../../../utilities/UserInfoUtil';
import { INTERSECTION_OBSERVER_THRESHOLD } from '../../../constants/AppConst';

function RailEventHOC(WrappedComponent) {
    class RailEventWrapper extends Component {
        constructor(props) {
            super(props);
            this.state = {
                railVisiblEventSent: false,
                railScrollEventSent: false,
            };


            this.ref = React.createRef();

            const {
                railId, railIndex, railTitle, railType, packageId, analyticsPageId, currentSource = '', prevSource = '',
            } = props;

            this.railInteractionMeta = {
                page_id: analyticsPageId,
                rail_id: railId,
                rail_position: railIndex,
                rail_title: railTitle,
                source_name: currentSource || getCurrentWindowSourceName(),
                source_page: prevSource,
                rail_type: railType,
                package_id: packageId,
                user_language: UserInfoUtil.getUserLanguage(),
            };
        }


        onRailScroll = () => {
            const { railScrollEventSent, railVisiblEventSent } = this.state;
            const { railVisibleCallback } = this.props;
            if (!railScrollEventSent) {
                if (!railVisiblEventSent) {
                    if (railVisibleCallback) {
                        railVisibleCallback();
                    }
                    else {
                        railVisibleEvent(this.railInteractionMeta);
                        contentVisibleEvent(this.railInteractionMeta);
                    }
                    this.setState({
                        railVisiblEventSent: true,
                        railScrollEventSent: true,
                    });
                }
                else {
                    this.setState({
                        railScrollEventSent: true,
                    });
                }
                railScrollEvent(this.railInteractionMeta);
            }
        };

        visibilityChange = (ratio) => {
            const { railVisiblEventSent } = this.state;
            const { railVisibleCallback } = this.props;
            if (!railVisiblEventSent && ratio === 1) {
                if (railVisibleCallback) {
                    railVisibleCallback();
                }
                else {
                    railVisibleEvent(this.railInteractionMeta);
                    contentVisibleEvent(this.railInteractionMeta);
                }
                this.setState({
                    railVisiblEventSent: true,
                });
            }
        };

        visibleWithClickEvent = () => {
            const { railVisiblEventSent } = this.state;
            const { railVisibleCallback } = this.props;
            if (!railVisiblEventSent) {
                if (railVisibleCallback) {
                    railVisibleCallback();
                }
                else {
                    railVisibleEvent(this.railInteractionMeta);
                    contentVisibleEvent(this.railInteractionMeta);
                }
            }
        };


        render() {
            const { railId, railTitle } = this.props;

            const Wrapper = (
                <WrappedComponent
                    onRailScroll={this.onRailScroll}
                    {...this.props}
                    key={`${railId} - ${railTitle}`}
                    ref={this.ref}
                    visibleWithClickEvent={this.visibleWithClickEvent}
                />
            );
            const railIntersectionObserver = (
                <IntersectionObservableHOC
                    hasMore
                    visibilityChange={this.visibilityChange}
                    loaderElem={Wrapper}
                    startPageNumber={1}
                    threshold={INTERSECTION_OBSERVER_THRESHOLD.ON_ENTRY_AND_EXIT}
                />
            );
            return railIntersectionObserver;
        }
    }
    RailEventWrapper.defaultProps = {
        packageId: '',
        railVisibleCallback: null,
        analyticsPageId: '',
        currentSource: '',
        prevSource: '',
    };
    RailEventWrapper.propTypes = {
        railId: PropTypes.string.isRequired,
        railIndex: PropTypes.number.isRequired,
        packageId: PropTypes.string,
        railTitle: PropTypes.string.isRequired,
        railType: PropTypes.string.isRequired,
        railVisibleCallback: PropTypes.func,
        analyticsPageId: PropTypes.string,
        currentSource: PropTypes.string,
        prevSource: PropTypes.string,
    };
    return RailEventWrapper;
}


export default RailEventHOC;
