import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { getCurrentProgram } from '../../../../utilities/CommonUtil';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
// import { tileClickGaEvent } from '../../../../utilities/GaEvents';
import { checkWindowExist } from '../../../../utilities/WindowUtil';

const ChannelCardTileGridComponent = (props) => {
    const {
        imageUrl, tileCtaActionUrl, id, title, programGuide, railPosition, tileType, railId, tilePosition, cpId, packageId,
    } = props;
    const tileAnalyticsMeta = {
        cp_name: !cpId ? '' : cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        package_id: packageId,
    };
    const program = programGuide && programGuide[id] ? getCurrentProgram(programGuide[id]) : false;
    return (
        <div
            className="cards cards-channel-landscape cards-channel-landscape--grid contain-layout"
            key={id}
        >
            <AnalyticsLinkComponent
                to={tileCtaActionUrl}
                // onClick={() => tileClickGaEvent(id)}
                title={title}
                meta={tileAnalyticsMeta}
            >
                <div className="cards-img-wrap">
                    <ThumborImage
                        className="card-img"
                        src={imageUrl}
                        alt={title}
                    />
                    <div className="overlay">
                        <div className="card-center-play-icon">
                            <i className="icon40 icon-player-play" />
                        </div>
                    </div>
                </div>
                {
                    !checkWindowExist()
                        ? null
                        : <h6 className="card-title">{(program && program.title) ? program.title : title}</h6>
                }
            </AnalyticsLinkComponent>
        </div>
    );
};
ChannelCardTileGridComponent.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    programGuide: {},
    cpId: '',
    tileType: '',
    railPosition: 0,
    tilePosition: 0,
    railId: '',
    packageId: '',
};
ChannelCardTileGridComponent.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string.isRequired,
    programGuide: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    cpId: PropTypes.string,
    railPosition: PropTypes.number,
    tileType: PropTypes.string,
    tilePosition: PropTypes.number,
    railId: PropTypes.string,
    packageId: PropTypes.string,
};
const mapStateToProps = (state) => {
    const { epg } = state;
    return {
        programGuide: epg.programGuide,
    };
};

export default connect(mapStateToProps, {})(React.memo(ChannelCardTileGridComponent));
