import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { LocationUtil, withDeviceUtil } from '@airtel-tv/utils';
import { contentDetailsEvent, searchEvents } from '@airtel-tv/analytics/FunctionalEvents';
import EventType from '@airtel-tv/analytics/EventType';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import {
    DEEPLINK_INGRESS_KEYS, ELEMENT_ID, IMAGE_PATHS, MODAL_POPUP,
} from '@airtel-tv/constants/GlobalConst';
import { setAnalyticsMeta, setStitchId, getSourceName as getAnalyticsSourceName } from '@airtel-tv/utils/GlobalUtil';
import { ANALYTICS_ASSETS, TITLE_TYPE } from '@airtel-tv/constants';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import { packageReset } from '@airtel-feature/layout/actions/LayoutActions';
import AddedToWatchlistIcon from '@airtel-tv/svg/AddedToWatchlistIcon';
import AddToWatchlistIcon from '@airtel-tv/svg/AddToWatchlistIcon';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { convertRailTitle, getSourceName } from '../../../../utilities/CommonUtil';
import { CDP_EVENT_CONST, PROGRAM_TYPES } from '../../../../constants/AppConst';
import LanguageProvider from '../../../../providers/LanguageProvider';
import withWatchlistDataHOC from '../../hoc/withWatchlistDataHOC';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
// import TagDisplayer from '../../../../../../libraries/ui-lib/atoms/TagDisplayer/TagDisplayer';
import TileTag from '../../../../../../libraries/ui-lib/atoms/TileTag/TileTag';
import { hideModalComponentAction, showModalComponentAction } from '../../../modal-popup/ModalPopupActions';

const cpLogoImgeSettings = { 300: 30 };

const RelatedContentTileComponent = (props) => {
    const {
        id,
        tileCtaActionUrl,
        contentDetails,
        contentDetails: { shouldAddInWL = true, position },
        title,
        watchList,
        watchListToggleButtonClick,
        railId,
        logoUrl,
        pageId,
        webPageId,
        cpId,
        railPosition,
        tileType,
        tilePosition,
        shouldLazyLoad,
        railTitle,
        tileTagIdExcluded,
        tagDetails,
        carouselElementRef,
        railRef,
        showPosition,
        style = {},
        imageUrl = '',
        hidePlayIcon = false,
        nativeRedirect = false,
        clearCurrentCdp = false,
        preferredArtwork = '',
        tileClass = '',
        imageSettings,
        wrapperId = '',
        sosAnalyticsMeta,
        packageId,
        titleType,
        maxTileTitleLines,
        hoveredTile,
        setHoveredTile,
        isSearchEvent,
        tileLinkStyle,
        customClassTileLevel = '',
        hideImageAltTag = false,
        hideTileBottomeDetails = false,
        hideAddtoWatchListCta = false,
        zionTileId = '',
        maxAllowedPlaybackTime,
        userContentProperties,
        showModalComponentActionDispatch,
        hideModalComponentActionDispatch,
        isXppUser,
        deepLinkConfig,
        location,
    } = props;
    const currentTileId = `${railId}-${contentDetails.id}-${tilePosition + 1}`;
    const sourceName = SOSUtil.getRouteSourceNameMappedFromCMS(getSourceName(props?.location?.pathname));
    const addToWatchlistAnalyticsMeta = {
        cp_name: cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.ADD_TO_WATCHLIST_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        asset_name: ANALYTICS_ASSETS.WATCHLIST,
        source_name: sourceName,
        package_id: packageId,
    };

    const tileRef = useRef();
    const LANGUAGE = LanguageProvider();
    const clearTimeOutIds = {};
    const getImage = ({ contentDetails, imageUrl }) => {
        const images = {
            ...contentDetails.images || {},
            ...contentDetails.tileImages || {},
        };
        if (contentDetails && contentDetails.images) {
            return preferredArtwork ? images[preferredArtwork] : imageUrl;// contentDetails.images.PORTRAIT;
        }

        return null;
    };

    const tileClicked = () => {
        const {
            location, railId, tilePosition, packageId, navigate, contentDetails, params, pageId, packageResetDispatch, webPageId,
        } = props;
        if (Object.keys(contentDetails).length > 0) {
            const {
                title, programType, tvShowName, id, cpId,
            } = contentDetails;
            const { userSessionId } = setAnalyticsMeta(id);
            const { stitchId } = setStitchId();
            const meta = {
                action: CDP_EVENT_CONST.TILE_CLICK,
                source_name: sourceName,
                rail_position: railPosition,
                program_type: programType,
                // rail_name: railType,
                rail_id: railId,
                package_id: packageId,
                content_name: programType === PROGRAM_TYPES.EPISODE ? tvShowName : title,
                content_id: id,
                cp_name: cpId,
                asset_position: tilePosition,
                user_session_id: userSessionId,
                tile_tag: tileTagIdExcluded || 'none',
                tile_type: tileType,
                tileId: zionTileId || 'none',
                ...(stitchId && { playback_stitch_key: stitchId }),
                ...sosAnalyticsMeta,
            };
            if (isSearchEvent) {
                searchEvents(meta, EventType.TILE_CLICK);
            }
            else {
                contentDetailsEvent(meta, EventType.TILE_CLICK);
            }
        }
        if (clearCurrentCdp) {
            packageResetDispatch({ pageId: webPageId.CONTENT_DETAIL });
            // packageResetDispatch({ pageId: webPageId.MORE_LIKE_THIS });
            // packageResetDispatch({ pageId: webPageId.CAST_N_MORE });
        }
        if (!nativeRedirect) {
            const searchParams = LocationUtil.getQueryParams(props);
            const ctaUrlSplit = tileCtaActionUrl.split('?');
            const ctaUrlPathname = ctaUrlSplit?.[0] || '';
            const ctaUrlQueryParams = ctaUrlSplit?.[1] || '';

            navigate({
                pathname: location.pathname,
                search: LocationUtil.objectToQueryParams({
                    // pageId,
                    ...searchParams,
                }),
            }, { replace: true });
            navigate({
                pathname: ctaUrlPathname,
                search: ctaUrlQueryParams,
                state: {
                    prevPageTitle: params ? params.pageTitle : '',
                },
            });
        }
    };

    const addHoverClass = (contentDetails) => {
        const { deviceUtil: { isMobile } } = props;
        if (isMobile()) {
            return;
        }
        const id = setTimeout(() => {
            if (typeof setHoveredTile === 'function') {
                setHoveredTile(currentTileId);
                return;
            }
            tileRef?.current && tileRef.current.classList.add('scale-tile');
            tileRef?.current && tileRef.current.classList.remove('scale-down-tile');
            if (railId) {
                const railTarget = document.getElementById(railId);
                railTarget?.classList?.add('padding-booster');
                const targetParent = document.getElementById(wrapperId || `${ELEMENT_ID.POTRAIT_RAIL}-${railId}-${railPosition}`);
                targetParent?.classList?.add('shrink-space');
            }
            else {
                carouselElementRef?.current?.classList?.add('padding-booster');
                railRef?.current?.classList?.add('shrink-space');
            }
        }, 500);
        clearTimeOutIds[`${railId}${contentDetails.id}`] = id;
    };

    const removeHoverClass = (contentDetails) => {
        const id = clearTimeOutIds[`${railId}${contentDetails.id}`];
        if (id) {
            clearTimeout(id);
            delete clearTimeOutIds[`${railId}${contentDetails.id}`];
        }
        if (typeof setHoveredTile === 'function') {
            setHoveredTile('');
            return;
        }
        tileRef?.current && tileRef.current.classList.remove('scale-tile');
        tileRef?.current && tileRef.current.classList.add('scale-down-tile');
        if (railId) {
            const railTarget = document.getElementById(railId);
            railTarget?.classList.remove('padding-booster');
            const targetParent = document.getElementById(wrapperId || `${ELEMENT_ID.POTRAIT_RAIL}-${railId}-${railPosition}`);
            targetParent?.classList.remove('shrink-space');
        }
        else {
            carouselElementRef?.current?.classList?.remove('padding-booster');
            railRef?.current?.classList?.remove('shrink-space');
        }
    };

    const isAddedToWatchList = contentId => (watchList[contentId] && Object.keys(watchList[contentId]).length > 0);
    const addedToWatchList = isAddedToWatchList(contentDetails.id);

    const tagConfig = tagDetails && tileTagIdExcluded ? tagDetails[tileTagIdExcluded] : null;
    const showTileBottomTitle = titleType === TITLE_TYPE.TITLE && maxTileTitleLines > 0;

    return (
        <li
            className={`custom-portrait-relative-holder  ${tileClass} ${customClassTileLevel} ${showPosition ? 'margin-for-gradient-text' : ''} ${position > 9 ? 'gradient-text-less' : ''} ${showTileBottomTitle ? 'tile-title' : null}`}
            style={style || {}}
        >
            { showPosition && position > 0 && <span className={position > 9 ? 'number-text-gradient two-digit' : 'number-text-gradient'}><span>{ position }</span></span> }
            <section
                id={`atm_potrait-tile-${tilePosition + 1}`}
                key={id}
                className={`custom-card-absolute pos-relative ${hoveredTile === currentTileId ? 'scale-tile' : 'scale-down-tile'}`}
                onMouseEnter={() => addHoverClass(contentDetails)}
                onMouseLeave={() => removeHoverClass(contentDetails)}
                ref={tileRef}
                atm-name={title}
            >
                <AnalyticsLinkComponent
                    title={title}
                    to={tileCtaActionUrl}
                    onClick={(e) => {
                        if (([
                            PROGRAM_TYPES.VIDEO,
                            PROGRAM_TYPES.LIVETVCHANNEL,
                        ].includes(contentDetails.programType))
                            && !ScopedWebviewUtil.canPlayPlayback(maxAllowedPlaybackTime, userContentProperties, contentDetails)) {
                            e.preventDefault();
                            e.stopPropagation();
                            showModalComponentActionDispatch({
                                showModalValue: true,
                                componentNameValue: MODAL_POPUP.DOWNLOAD_XSTREAM_APP_POPUP,
                                overrideCrossButtonValue: true,
                                payload: {
                                    contentDetails: contentDetails || {},
                                    isSubscribed: isXppUser,
                                    pageId,
                                    meta: {
                                        popup_id: 'download_hard_popup',
                                        source_name: getAnalyticsSourceName(location.pathname),
                                        content_category: 'Content',
                                    },
                                    notNowBtnHandler: () => {
                                        hideModalComponentActionDispatch({
                                            showModalValue: false,
                                            componentNameValue: null,
                                            overrideCrossButtonValue: false,
                                        });
                                    },
                                    downloadNowBtnHandler: () => {
                                        ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, DEEPLINK_INGRESS_KEYS.HARD_POPUP);
                                    },
                                },
                            });
                            return;
                        }
                        tileClicked(props);
                        if (!nativeRedirect) {
                            e.preventDefault();
                        }
                    }}
                    style={tileLinkStyle || {}}
                    className={`${tagConfig && Object.keys(tagConfig).length ? 'no-left-border-radius' : ''}`}
                >
                    <ThumborImage
                        alt={!hideImageAltTag ? contentDetails.title : ''}
                        className={`custom-tile-image ${contentDetails.programType === PROGRAM_TYPES.LIVETVCHANNEL ? 'card-image-center' : ''}`}
                        src={getImage({
                            contentDetails,
                            imageUrl,
                        })}
                        shouldLazyLoad={shouldLazyLoad}
                        imageSettings={imageSettings}
                    />
                    {tagConfig && <TileTag tagConfig={tagConfig} />}

                    {!hidePlayIcon ? (
                        <div className="custom-tile-play">
                            <ThumborImage
                                alt="Play Button"
                                src={IMAGE_PATHS.TILE_PLAY_ICON}
                                id={`atm_play-btn-potrait-${tilePosition + 1}`}
                                imageSettings={cpLogoImgeSettings}
                            />
                        </div>
                    ) : null}
                    {!hideTileBottomeDetails ? (
                        <div className="custom-potrait-card-shade">
                            <div className="custom-detail-wrapper">
                                { title && <p className="title text-bolder-10">{ title }</p> }
                                <div className="tags-area">
                                    {contentDetails && contentDetails.ageRating && (
                                        <div
                                            className="age-rating"
                                            id={`atm_age-rating-potrait-${tilePosition + 1}`}
                                        >
                                            { contentDetails.ageRating }
                                        </div>
                                    ) }
                                    {contentDetails && contentDetails?.genres?.length > 0 && (
                                        <p>
                                            <span id={`atm_genre-potrait-${tilePosition + 1}`}>{ contentDetails.genres[0] }</span>
                                        </p>
                                    )}
                                </div>
                                {shouldAddInWL && !hideAddtoWatchListCta && (
                                    <AnalyticsButtonComponent
                                        className="button-for-portrait-tile"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            watchListToggleButtonClick(contentDetails, addToWatchlistAnalyticsMeta);
                                        }}
                                        id={`atm_add-to-watchlist-${convertRailTitle(railTitle)}-${tilePosition + 1}`}
                                    >
                                        { addedToWatchList
                                            ? <AddedToWatchlistIcon />
                                            : <AddToWatchlistIcon />
                                        }
                                        { addedToWatchList ? LANGUAGE.ADDED_TO_WATCH_LIST_CAPITALIZE : LANGUAGE.ADD_TO_WATCH_LIST_CAPITALIZE }

                                    </AnalyticsButtonComponent>
                                )}
                            </div>

                        </div>
                    ) : null}

                    {pageId !== webPageId.MORE_LIKE_THIS && logoUrl && (
                        <div className="custom-logo-img-banner">
                            <ThumborImage
                                src={logoUrl}
                                alt=""
                                imageSettings={cpLogoImgeSettings}
                            />
                        </div>
                    )}
                </AnalyticsLinkComponent>
                {titleType === TITLE_TYPE.TITLE && maxTileTitleLines > 0 ? <span className="show-title text-14">{title}</span> : null}
            </section>
        </li>
    );
};

RelatedContentTileComponent.defaultProps = {
    sosAnalyticsMeta: {},
    packageId: '',
    titleType: '',
    maxTileTitleLines: 0,
};

RelatedContentTileComponent.propTypes = {
    packageResetDispatch: PropTypes.func.isRequired,
    contentDetails: PropTypes.object.isRequired,
    webPageId: PropTypes.object.isRequired,
    tileCtaActionUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    watchList: PropTypes.string.isRequired,
    watchListToggleButtonClick: PropTypes.func.isRequired,
    railId: PropTypes.string.isRequired,
    logoUrl: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
    cpId: PropTypes.string.isRequired,
    railPosition: PropTypes.string.isRequired,
    tileType: PropTypes.string.isRequired,
    tilePosition: PropTypes.string.isRequired,
    sosAnalyticsMeta: PropTypes.object,
    packageId: PropTypes.string,
    titleType: PropTypes.string,
    maxTileTitleLines: PropTypes.number,
    hoveredTile: PropTypes.string.isRequired,
    setHoveredTile: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { appConfig, userConfig: { userContentProperties = {}, userContentProperties: { oSeg = '' } = {} } } = state;
    const { maxAllowedPlaybackTime, deepLinkConfig } = appConfig;
    const isXppUser = !!(oSeg || '').split(',').filter(item => item === 'xstreampremium_paid' || item === 'xstreampremium_telco_paid').length;

    return {
        webPageId: appConfig.web_pageId,
        tagDetails: appConfig.tagDetails,
        maxAllowedPlaybackTime,
        userContentProperties,
        isXppUser,
        deepLinkConfig,
    };
}

export default withDeviceUtil(withRouter(withWatchlistDataHOC(connect(mapStateToProps, {
    packageResetDispatch: packageReset,
    showModalComponentActionDispatch: showModalComponentAction,
    hideModalComponentActionDispatch: hideModalComponentAction,

})(RelatedContentTileComponent))));
