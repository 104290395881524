import { PropTypes } from 'prop-types';
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { getRandomInt, percentCalculation } from '../../../../utilities/CommonUtil';
import styles from './PlaybackSecurityLabelComponent.scss';

const DOCKS = {
    TOP: 1,
    BOTTOM: 2,
    RIGHT: 3,
    LEFT: 4,
};

class PlaybackSecurityLabelComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };

        this.timeoutRef = null;
    }

    componentDidMount() {
        this.show();
    }

    componentDidUpdate(prevProps) {
        const {
            changeRequestNumber,
        } = this.props;

        const {
            visible,
        } = this.state;

        if (changeRequestNumber && prevProps.changeRequestNumber !== changeRequestNumber && !visible) {
            this.show();
        }
    }

    componentWillUnmount() {
        if (this.timeoutRef) {
            clearTimeout(this.timeoutRef);
        }
    }

    show = () => {
        let {
            maxTop,
            maxLeft,
            minTop,
            minLeft,
        } = this.props;

        const {
            lifeSpanInSeconds,
        } = this.props;

        const randomDock = getRandomInt(1, 4);


        maxLeft -= 120; // minus the length of the label

        switch (randomDock) {
            case DOCKS.TOP:
                maxTop -= percentCalculation(maxTop, 75);
                maxLeft -= percentCalculation(maxLeft, 75);
                minLeft += percentCalculation(maxLeft, 75);
                break;
            case DOCKS.BOTTOM:
                minTop += percentCalculation(maxTop, 75);
                maxLeft -= percentCalculation(maxLeft, 75);
                minLeft += percentCalculation(maxLeft, 75);
                break;
            case DOCKS.LEFT:
                maxLeft -= percentCalculation(maxLeft, 75);
                minTop += percentCalculation(maxTop, 75);
                maxTop -= percentCalculation(maxTop, 75);
                break;
            case DOCKS.RIGHT:
                minLeft += percentCalculation(maxLeft, 75);
                minTop += percentCalculation(maxTop, 75);
                maxTop -= percentCalculation(maxTop, 75);
                break;
            default:
                break;
        }

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
            visible: true,
            top: getRandomInt(minTop, maxTop),
            left: getRandomInt(minLeft, maxLeft),
        });

        if (this.timeoutRef) {
            clearTimeout(this.timeoutRef);
        }

        this.timeoutRef = setTimeout(() => {
            this.setState({
                visible: false,
            });
        }, lifeSpanInSeconds * 1000);
    }

    render() {
        const { uid } = this.props;
        const { top, left, visible } = this.state;

        if (!visible) {
            return null;
        }

        return (
            <div
                ref={this.labelNode}
                className="PlaybackSecurityLabelComponent"
                style={{
                    top: `${top}px`,
                    left: `${left}px`,
                }}
            >
                <span>{uid}</span>
            </div>
        );
    }
}

PlaybackSecurityLabelComponent.propTypes = {
    uid: PropTypes.string.isRequired,
    lifeSpanInSeconds: PropTypes.number.isRequired,
    changeRequestNumber: PropTypes.number.isRequired,
    maxTop: PropTypes.number.isRequired,
    maxLeft: PropTypes.number.isRequired,
    minTop: PropTypes.number.isRequired,
    minLeft: PropTypes.number.isRequired,
};

export default withStyles(styles)(PlaybackSecurityLabelComponent);
