// eslint-disable-next-line no-unused-vars
import React from 'react';

const TileLoader = React.forwardRef((props, ref) => (
    <div className="rail-loader">
        <div
            className="inside-rail-loader"
            id="loader"
            {...props}
            ref={ref}
        />
    </div>
    // <div className='horizontal-tile-shimmer'>
    //     {console.log('props loader',props)}
    //     <div className='shimmer-tile-single' {...props} width={props?.style?.width || '120px'} height={props?.style?.height || '170px'}></div>
    //     {/* if want to show lanscape shimmer classname should be like this className="shimmer-tile-single landscape" */}
    //     {/* also give dynamic width and height inline because tile have inline styles  */}
    // </div>
    // <div class="lds-ellipsis" id="loader" {...props} ref={ref}><div></div><div></div><div></div><div></div></div>
));

export default TileLoader;
