import React, { Component } from 'react';
import AppContext from '../../../contexts/AppContext';

function withDeviceUtil(WrappedComponent) {
    class DeviceUtilWrapper extends Component {
        constructor(props, context) {
            super(props);
            this.deviceUtil = context.deviceUtil;
        }

        render() {
            return (
                <WrappedComponent
                    deviceUtil={this.deviceUtil}
                    {...this.props}
                />
            );
        }
    }

    DeviceUtilWrapper.contextType = AppContext;

    return DeviceUtilWrapper;
}

export default withDeviceUtil;
