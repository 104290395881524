import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import lodashGet from 'lodash/get';
import { getSourceName, validateOTPInput } from '@airtel-tv/utils/GlobalUtil';
import { KEY_CODES, OTP_TIMER } from '../../../constants/AppConst';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { ROUTE_PATHS_SETTINGS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { popUpVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import PhoneCallSVGComponent from '@airtel-tv/ui-lib/svg/PhoneCallSVGComponent';
import SMSSVGComponent from '@airtel-tv/ui-lib/svg/SMSSVGComponent';
import LanguageProvider from '../../../providers/LanguageProvider';
import { getEvent, getWindowLocation } from '../../../utilities/WindowUtil';
import { handlePasteNumericData, disableScrollOnWheel } from '../../../utilities/CommonUtil';
import themefication from '../../../providers/themeProvider';
import LinkComponent from '../../shared/components/link/LinkComponent';

const OtpConfirmation = (props) => {
    const LANGUAGE = LanguageProvider();
    const THEME_COLORS = themefication();
    const { BUTTON_RESEND_OTP_SMS = 'Resend via SMS', BUTTON_RESEND_OTP_CALL = 'Resend via Call ', BUTTON_RESEND_OTP_TIMER = 'Resend OTP in' } = LANGUAGE;

    const {
        backToMsisdnEntry,
        errorMessage,
        handleOtpEntered,
        msisdn,
        otp,
        regenerateOtp,
        submitOtpRef,
        validateOtp,
        otpTimer,
        message: apiErrorMessage,
        otpSuccess,
        sourceOfLoginTrigger = 'Login-Verify',
    } = props;

    const [
        secondsLeft,
        setSeconds,
    ] = useState(otpTimer || OTP_TIMER);

    const resendOtp = () => {
        setSeconds(otpTimer);
        regenerateOtp({ viaCall: false });
        setSeconds(otpTimer || OTP_TIMER);
    };

    const resendOtpCall = () => {
        setSeconds(otpTimer);
        regenerateOtp({ viaCall: true });
        setSeconds(otpTimer || OTP_TIMER);
    };


    const handleKeyDown = (e, index) => {
        // restrict decimal point in otp
        if (e.keyCode === KEY_CODES.DECIMAL_POINT || e.keyCode === KEY_CODES.UNIDENTIFIED) {
            e.preventDefault();
            return;
        }
        if (e.keyCode === KEY_CODES.UP_ARROW || e.keyCode === KEY_CODES.DOWN_ARROW) {
            e.preventDefault();
            return;
        }

        if (e.keyCode === KEY_CODES.TAB) {
            return;
        }

        if (e.keyCode === KEY_CODES.RIGHT_ARROW) {
            // prevent non nums
            const { nextSibling } = e.target;
            if (nextSibling) {
                nextSibling.focus();
            }

            e.preventDefault();
            return;
        }

        if (e.keyCode === KEY_CODES.LEFT_ARROW) {
            const { previousSibling } = e.target;
            if (previousSibling) {
                previousSibling.focus();
            }

            e.preventDefault();
            return;
        }

        if (!((e.keyCode >= KEY_CODES.NUMPAD_0 && e.keyCode <= KEY_CODES.NUMPAD_9) || (e.keyCode >= KEY_CODES.NUM0 && e.keyCode <= KEY_CODES.NUM9) || e.keyCode === KEY_CODES.BACKSPACE
            || e.keyCode === KEY_CODES.DELETE || e.keyCode === KEY_CODES.V || e.keyCode === KEY_CODES.C || e.keyCode === KEY_CODES.A
            || e.keyCode === KEY_CODES.X || (e.keyCode >= KEY_CODES.LEFT_ARROW && e.keyCode <= KEY_CODES.DOWN_ARROW))) {
            // prevent non nums
            e.preventDefault();
            return;
        }

        if (e.keyCode === KEY_CODES.BACKSPACE || e.keyCode === KEY_CODES.DELETE) {
            if (e.target.value) {
                e.target.value = '';
                handleOtpEntered(index, e);
            }
            else if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }

        if (e.target.value.length > 0) {
            // clear previous data as opt can has only 1 num in 1 text box
            e.target.value = e.key;
        }
    };

    useEffect(() => {
        let interval;
        if (secondsLeft > 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        }
        const { pathname } = getWindowLocation();
        const sourceName = getSourceName(pathname);
        const analyticsLoginPopup = {
            source_name: sourceName,
            asset_name: sourceOfLoginTrigger,
        };
        popUpVisibleEvent(analyticsLoginPopup);
        return () => clearInterval(interval);
    }, []);

    const resendTimer = () => {
        const min = Math.floor(secondsLeft / 60);
        const second = secondsLeft % 60;
        if (second < 10) {
            return `(${min}:0${second})`;
        }
        return `(${min}:${second})`;
    };

    return (
        <div className="modal-content--option-2 modal-content--login-otp">
            <div className="modal-header d-flex justify-content-start">
                <AnalyticsButtonComponent
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={backToMsisdnEntry}
                    meta={{ asset_name: 'Login-Back' }}
                >
                    <i className="icon24 icon-back" />
                </AnalyticsButtonComponent>
            </div>
            <div className="modal-body">
                <div
                    id="otp-upper"
                    className="modal-small__title mt-3"
                >
                    <h4 className='text-14 text-small-normal'>{LANGUAGE.LABEL_VERIFY_NUMBER}</h4>
                    <span
                        id={`${errorMessage ? 'otp-error' : ''}`}
                        className={`text-16 ${errorMessage ? 'message-error' : 'info-text'}`}
                    >
                        {errorMessage || `${LANGUAGE.LABEL_ENTER_OTP} ${msisdn}`}
                    </span>
                </div>
                <div
                    id={`${errorMessage ? 'otp-error' : ''}`}
                    className="modal-small__form"
                >
                    <div className="form-group form-group--otp">
                        <div className="d-flex justify-content-center align-items-center">
                            <input
                                id="otp-first-letter"
                                value={otp[0] || ''}
                                type="number"
                                className="input-custom input-custom-100"
                                placeholder=""
                                autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                                maxLength={1}
                                onPaste={handlePasteNumericData}
                                onChange={val => handleOtpEntered(0, val)}
                                onKeyPress={validateOTPInput}
                                onKeyDown={e => handleKeyDown(e, 0)}
                                onWheel={disableScrollOnWheel}
                                pattern="[0-9]*"
                                min={0}
                                max={9}
                            />

                            <input
                                id="otp-second-letter"
                                value={otp[1] || ''}
                                type="number"
                                className="input-custom input-custom-100"
                                placeholder=""
                                maxLength={1}
                                onPaste={handlePasteNumericData}
                                onChange={val => handleOtpEntered(1, val)}
                                onKeyPress={validateOTPInput}
                                onKeyDown={e => handleKeyDown(e, 1)}
                                onWheel={disableScrollOnWheel}
                                pattern="[0-9]*"
                                min={0}
                                max={9}
                            />

                            <input
                                id="otp-third-letter"
                                value={otp[2] || ''}
                                type="number"
                                className="input-custom input-custom-100"
                                placeholder=""
                                maxLength={1}
                                onPaste={handlePasteNumericData}
                                onChange={val => handleOtpEntered(2, val)}
                                onKeyPress={validateOTPInput}
                                onKeyDown={e => handleKeyDown(e, 2)}
                                onWheel={disableScrollOnWheel}
                                pattern="[0-9]*"
                                min={0}
                                max={9}
                            />

                            <input
                                id="otp-fourth-letter"
                                value={otp[3] || ''}
                                type="number"
                                className="input-custom input-custom-100"
                                placeholder=""
                                maxLength={1}
                                onPaste={handlePasteNumericData}
                                onChange={val => handleOtpEntered(3, val)}
                                onKeyPress={validateOTPInput}
                                onKeyDown={e => handleKeyDown(e, 3)}
                                onWheel={disableScrollOnWheel}
                                pattern="[0-9]*"
                                min={0}
                                max={9}
                            />

                        </div>
                    </div>
                    {apiErrorMessage && apiErrorMessage && !otpSuccess && <div className="d-flex justify-content-center"><p className="message-text">{ apiErrorMessage }</p></div> }
                    { otpSuccess && (
                        secondsLeft <= 0
                            ? (
                                <div className="form-link d-flex resend-otp-cta">
                                    <AnalyticsButtonComponent
                                        className="button button-link"
                                        id="resend-otp-call"
                                        onClick={resendOtpCall}
                                        meta={{
                                            source_name: 'enter_otp',
                                            action: 'action_resend_call',
                                        }}
                                    >
                                        <PhoneCallSVGComponent />
                                        {BUTTON_RESEND_OTP_CALL}
                                    </AnalyticsButtonComponent>
                                    <AnalyticsButtonComponent
                                        className="button button-link"
                                        id="resend-otp"
                                        onClick={resendOtp}
                                        meta={{
                                            source_name: 'enter_otp',
                                            action: 'action_resend',
                                        }}
                                    >
                                        <SMSSVGComponent />
                                        {BUTTON_RESEND_OTP_SMS}
                                    </AnalyticsButtonComponent>
                                </div>
                            )
                            : <div className="resend-timer text-normal-12 form-link button-link ">{`${BUTTON_RESEND_OTP_TIMER} ${resendTimer()}`}</div>
                    )}

                    <div className="form-button">
                        <AnalyticsButtonComponent
                            id="otp-verify-btn"
                            className={`button button-theme button-ripple primary-cta common-white-btn ${otp.filter(Boolean).length < 4 ? 'button-theme-disabled button-ripple' : ''}`}
                            onClick={validateOtp}
                            disabled={otp.filter(Boolean).length < 4}
                            style={{
                                color: lodashGet(THEME_COLORS, 'login.buttonText', THEME_COLORS.application.secondaryLabel),
                                backgroundColor: otp.filter(Boolean).length < 4 ? lodashGet(THEME_COLORS, 'login.disableButtonBackground', THEME_COLORS.application.primaryLabel) : lodashGet(THEME_COLORS, 'login.activeButtonBackground', THEME_COLORS.application.tint),
                            }}
                            ref={submitOtpRef}
                            autoFocus={!(otp.filter(Boolean).length < 4)}
                            meta={{
                                asset_name: sourceOfLoginTrigger,
                                action: 'verify',
                                action_mode: 'manual',
                                otp_entry: 'manual',
                            }}
                        >
                            {LANGUAGE.BUTTON_VERIFY}
                        </AnalyticsButtonComponent>
                        <div className="text-info">
                            <p>
                                {/* TODO: CHANGE LATER */}
                                {LANGUAGE.I_AGREE}
                                {' '}
                                <LinkComponent
                                    id="login-terms-of-uses"
                                    to={ROUTE_PATHS_SETTINGS.SETTINGS_HELP_TERMS_SECTION}
                                    title={LANGUAGE.TERMS_OF_USE}
                                    className="info-text"
                                >
                                    {LANGUAGE.TERMS_OF_USE}
                                </LinkComponent>
                                {' '}
                                {LANGUAGE.AND}
                                {' '}
                                <LinkComponent
                                    id="login-privacy-policy"
                                    to={ROUTE_PATHS_SETTINGS.SETTINGS_HELP_PRIVACY_SECTION}
                                    title={LANGUAGE.PRIVACY_POLICY}
                                    className="info-text"
                                >
                                    {LANGUAGE.PRIVACY_POLICY}
                                </LinkComponent>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

OtpConfirmation.propTypes = {
    backToMsisdnEntry: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    msisdn: PropTypes.string.isRequired,
    handleOtpEntered: PropTypes.func.isRequired,
    regenerateOtp: PropTypes.func.isRequired,
    otp: PropTypes.instanceOf(Array),
    validateOtp: PropTypes.func.isRequired,
    submitOtpRef: PropTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    otpTimer: PropTypes.number,
    sourceOfLoginTrigger: PropTypes.string,
    message: PropTypes.string,
    otpSuccess: PropTypes.bool,
};

OtpConfirmation.defaultProps = {
    errorMessage: '',
    otp: [],
    sourceOfLoginTrigger: '',
    message: '',
    otpSuccess: false,
};

export default OtpConfirmation;
