// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"mobileWidth": "767px",
	"mediaLandscapeHeight": "500px",
	"mediaLandscapeWidth": "990px",
	"tabletLargeWidth": "1200px"
};
module.exports = exports;
