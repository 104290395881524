/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { LoaderComponent } from '@airtel-tv/ui-lib/molecules/loader/LoaderComponent';
// import BigBoxShimmer from '@airtel-tv/ui-lib/molecules/loader/BigBoxShimmer';

function layoutLoaderComponent(props) {
    const {
        deviceUtil: { isMobile },
        pageId,
    } = props;

    if (pageId === 'OneHubLayout') {
        return <LoaderComponent />;
    }

    return (
        <div
            className="loader-shimmer pt-4 pt-md-4"
            style={{
                paddingTop: 0,
            }}
        >
            <div className="shimmer-skeleton">
                {isMobile() ? (
                    <div
                        id="shimmer-sm"
                        className="bigbox-shimmer shimmer"
                        style={{
                            height: '31vh',
                        }}
                    >

                        <img
                            style={{
                                height: '31vh',
                            }}
                            src="/static/bigbox-shimmer-mob.png"
                        />
                    </div>
                )
                    : (
                        <div
                            id="shimmer-xl"
                            className="bigbox-shimmer shimmer"
                            style={{
                                height: '25vw',
                            }}
                        >
                            {/* <img
                                style={{
                                    height: '31vh',
                                }}
                                src="/static/bigbox-shimmer.png"
                            /> */}
                            {/* <BigBoxShimmer
                                customStyle={{
                                    height: '31vh',
                                }}
                            /> */}
                        </div>
                    )}
                <div className="smallbox shimmer" />
                <div className=" d-flex medium-container">
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                </div>
                <div className=" smallbox shimmer" />
                <div className=" d-flex medium-container">
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                    <div className=" mediumbox shimmer" />
                </div>
            </div>
        </div>
    );
}


export default withDeviceUtil(layoutLoaderComponent);
