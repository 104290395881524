import lodashGet from 'lodash/get';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import lodashIsEqual from 'lodash/isEqual';
import { matchRoutes } from 'react-router';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { ROUTE_PATHS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import { RoutingUtil } from '@airtel-tv/utils';
import {
    loginInit, otpRegenerateInit, otpValidateInit, resetMsisdn, triggerLogin, resetLoginSource, isUserOnline,
} from './AuthAction';
import { isNumber, isObjEmpty } from '../../utilities/CommonUtil';
import OtpConfirmation from './components/OtpConfirmation';
import ErrorHandler from '../error-handler/ErrorHandler';
import { MAX_PHONE_LENGTH } from '../../constants/AppConst';
import getAllRouters from '../../routes/routes';
import MsisdnConfirmation from './components/MsisdnConfirmation';
// import { generateOtpGaEvent, validateOtpGaEvent, authPopupDisplayGaEvent } from '../../utilities/GaEvents';

const routes = getAllRouters();

class AuthContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            otp: [],
            phoneNumber: '',
        };
    }

    componentDidMount() {
        this.autoOtpGenerate();
        window.addEventListener('online', () => this.checkUserOnline());
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            isOtpSent, msisdn, error, loginTriggered,
        } = nextProps;

        let changes = {};

        // check if new content
        if (isOtpSent !== prevState.isOtpSent || msisdn !== prevState.msisdn || !lodashIsEqual(error, prevState.error) || loginTriggered !== prevState.loginTriggered) {
            changes = {
                ...prevState,
                isOtpSent,
                msisdn,
                error,
                loginTriggered,
                phoneNumber: msisdn || prevState.phoneNumber,
            };
        }

        return Object.keys(changes).length > 0 ? changes : null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState !== this.state;
    }

    componentDidUpdate(prevState) {
        const { loginTriggered } = this.props;
        // const { programTypeTitle } = this.getProgramTypeTitle();
        if (!prevState.loginTriggered && loginTriggered) {
            // authPopupDisplayGaEvent({ programTypeTitle });
        }

        this.autoOtpGenerate();
    }

    componentWillUnmount() {
        window.removeEventListener('online', () => this.checkUserOnline());
    }

    getProgramTypeTitle = () => {
        const {
            location: { pathname },
        } = this.props;

        const matchedRoutes = matchRoutes(routes, pathname);

        if (matchedRoutes.length > 0) {
            return lodashGet(matchedRoutes, '[1].match.params.category', '');
        }

        return '';
    };

    autoOtpGenerate = () => {
        const {
            msisdn, loginDispatch, isOtpSent, loginTriggered, error,
        } = this.props;

        if (msisdn && !isOtpSent && loginTriggered && !error) {
            console.debug('Msisdn already present, sending a generate otp call');
            loginDispatch(msisdn);
        }
    };

    generateOtp = (e) => {
        if (e) {
            e.preventDefault();
        }
        const { phoneNumber } = this.state;
        if (!phoneNumber) {
            return;
        }
        const { loginDispatch } = this.props;
        // const { programTypeTitle } = this.getProgramTypeTitle();

        // generateOtpGaEvent({ programTypeTitle });

        loginDispatch(phoneNumber);
    };

    regenerateOtp = ({ viaCall = false }) => {
        const { otpRegenerateDispatch, msisdn } = this.props;

        this.setState({
            otp: [],
        });

        otpRegenerateDispatch({
            msisdn,
            viaCall,
        });
    };

    validateOtp = () => {
        const {
            otpValidateDispatch,
            redirectUrl,
        } = this.props;
        const { otp } = this.state;

        const otpR = otp[0] + otp[1] + otp[2] + otp[3];
        otpValidateDispatch({
            otp: otpR,
            redirectUrl,
        });
        this.setState({ otp: [] }); // reset the OTP

        // const { programTypeTitle } = this.getProgramTypeTitle();

        // validateOtpGaEvent({ programTypeTitle });
    };

    closeLoginModal = () => {
        const {
            triggerLoginDispatch, resetMsisdnDispatch, location, history, navigateToHomeOnLoginClose,
        } = this.props;
        triggerLoginDispatch({
            loginTriggered: false,
            redirectUrl: null,
        });

        resetMsisdnDispatch();
        const isTvLogin = location?.pathname?.toLowerCase()?.includes('tvlogin');
        if (location.pathname === ROUTE_PATHS.PLANS_AND_OFFERS || isTvLogin || navigateToHomeOnLoginClose) {
            history.replace({
                pathname: RoutingUtil.getHomePage(),
                search: isTvLogin ? '' : lodashGet(history, 'location.search', ''),
            });
        }
    };

    handleOtpEntered = (index, e) => {
        const { otp } = this.state;
        const { value, nextSibling } = e.target;

        if ((isNumber(value) && !Number.isNaN(isNumber(value))) || value === '') {
            if (!isObjEmpty(value.trim())) {
                const valueTrimed = value.toString()[0];
                otp[index] = valueTrimed;
                if (nextSibling && valueTrimed) {
                    nextSibling.focus();
                }
            }
            else {
                delete otp[index];
            }

            this.setState({
                otp,
            });
        }
        else {
            e.target.value = '';
        }
    };

    setPhoneNumber = (value) => {
        this.setState({
            phoneNumber: value.slice(0, MAX_PHONE_LENGTH),
        });
    };

    backToMsisdnEntry = () => {
        const { isUserOnlineDispatch } = this.props;
        this.setState({
            otp: [],
        });
        isUserOnlineDispatch();
        const { resetMsisdnDispatch } = this.props;
        resetMsisdnDispatch();
    };

    resetSourceOfLogin = () => {
        const { resetLoginSourceDispatch } = this.props;
        resetLoginSourceDispatch();
    };

    checkUserOnline() {
        const { error, isUserOnlineDispatch } = this.props;
        if (error === ERROR_CODES.LOCAL1007) {
            isUserOnlineDispatch();
        }
    }

    render() {
        const {
            isOtpSent, error, msisdn, loginTriggered, sourceOfLoginTrigger, otpTimer, message, otpSuccess,
            hideCrossButton,
        } = this.props;

        const { otp, phoneNumber } = this.state;

        // if login is not triggered then return null
        if (!loginTriggered) {
            return null;
        }

        let Error = null;
        if (!error) {
            Error = { message: '' };
        }
        else {
            Error = ErrorHandler({
                error,
                code: error.errorcode,
                doNotHandle: true,
            }).errorData;
        }

        let element = null;

        // goto otp
        if (isOtpSent || msisdn) {
            element = (
                <OtpConfirmation
                    backToMsisdnEntry={this.backToMsisdnEntry}
                    errorMessage={Error.message}
                    msisdn={msisdn}
                    otpTimer={otpTimer}
                    handleOtpEntered={this.handleOtpEntered}
                    handleKeyDown={this.handleKeyDown}
                    regenerateOtp={({ viaCall }) => this.regenerateOtp({ viaCall })}
                    otp={otp}
                    validateOtp={this.validateOtp}
                    submitOtpRef={(val) => {
                        this.submitOtpRef = val;
                    }}
                    message={message}
                    otpSuccess={otpSuccess}
                />
            );
        }
        // goto take msisdn
        else {
            element = (
                <MsisdnConfirmation
                    errorMessage={Error.message}
                    closeLoginModal={this.closeLoginModal}
                    phoneNumber={phoneNumber || msisdn}
                    setPhoneNumber={this.setPhoneNumber}
                    generateOtp={this.generateOtp}
                    sourceOfLoginTrigger={sourceOfLoginTrigger}
                    resetLoginSource={this.resetSourceOfLogin}
                    hideCrossButton={hideCrossButton}
                />
            );
        }

        return (
            <>
                <div
                    className="modal fade show login-window login-modal"
                    id="loginModal"
                    style={{ display: 'block' }}
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="loginModalLabel"
                    aria-hidden="true"
                >
                    <div
                        className="modal-dialog modal-custom modal-custom--small modal-custom-m-100vwh"
                        role="document"
                    >
                        <div className="modal-content modal-small">{element}</div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" />
            </>
        );
    }
}

function mapStateToProps(state) {
    const { authConfig, appConfig } = state;
    const { hideCrossButton = false } = authConfig;
    return {
        otpRequestInit: authConfig.otpRequestInit,
        isOtpSent: authConfig.isOtpSent,
        msisdn: authConfig.msisdn,
        error: authConfig.error,
        loginTriggered: authConfig.loginTriggered,
        sourceOfLoginTrigger: authConfig.sourceOfLoginTrigger,
        navigateToHomeOnLoginClose: authConfig.navigateToHomeOnLoginClose,
        redirectUrl: authConfig.redirectUrl,
        dthLogin: authConfig.dthLogin,
        dthParam: authConfig.dthParam,
        otpTimer: appConfig.web_otp_timer,
        message: authConfig.message,
        otpSuccess: authConfig.success,
        hideCrossButton,
    };
}

AuthContainer.defaultProps = {
    msisdn: '',
    error: null,
    redirectUrl: null,
    sourceOfLoginTrigger: null,
    otpTimer: null,
};

AuthContainer.propTypes = {
    loginDispatch: PropTypes.func.isRequired,
    otpValidateDispatch: PropTypes.func.isRequired,
    otpRegenerateDispatch: PropTypes.func.isRequired,
    resetMsisdnDispatch: PropTypes.func.isRequired,
    triggerLoginDispatch: PropTypes.func.isRequired,
    isOtpSent: PropTypes.bool.isRequired,
    msisdn: PropTypes.string,
    loginTriggered: PropTypes.bool.isRequired,
    error: PropTypes.string,
    location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    redirectUrl: PropTypes.string,
    sourceOfLoginTrigger: PropTypes.string,
    resetLoginSourceDispatch: PropTypes.func.isRequired,
    isUserOnlineDispatch: PropTypes.func.isRequired,
    otpTimer: PropTypes.string,
};

export default connect(
    mapStateToProps,
    {
        loginDispatch: loginInit,
        otpValidateDispatch: otpValidateInit,
        otpRegenerateDispatch: otpRegenerateInit,
        resetMsisdnDispatch: resetMsisdn,
        triggerLoginDispatch: triggerLogin,
        resetLoginSourceDispatch: resetLoginSource,
        isUserOnlineDispatch: isUserOnline,
    },
)(withRouter(AuthContainer));
