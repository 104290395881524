import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import {
    IMAGE_PATHS, KEY_CODES, MAX_PHONE_LENGTH,
} from '@airtel-tv/constants/GlobalConst';

import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { screenVisibleEvent, clickEvent, popUpVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { ROUTE_PATHS, ROUTE_PATHS_SETTINGS } from '@airtel-tv/utils/constantWrappers/RouteConstWrapper';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
import LanguageProvider from '../../../providers/LanguageProvider';
import { isNumber, handlePasteNumericData, disableScrollOnWheel } from '../../../utilities/CommonUtil';
import LinkComponent from '../../shared/components/link/LinkComponent';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '../../../constants/AnalyticsConst';
import { EVENT_SOURCE_NAME } from '../../../constants/EventsConst';
import { getCurrentWindowSourceName } from '../../../utilities/WindowUtil';

class MsisdnConfirmation extends Component {
    constructor(props) {
        super(props);

        this.LANGUAGE = LanguageProvider();
        this.sourceName = getCurrentWindowSourceName();

    }
    componentDidMount() {
        const { sourceOfLoginTrigger, resetLoginSource } = this.props;
        // removed extra screen_visible for cdp
        // screenVisibleEvent(EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE);
        const analyticsLoginPopup = {
            source_name: SOSUtil.getRouteSourceNameMappedFromCMS(this.sourceName),
            action: 'login_popup_visible',
            asset_name: sourceOfLoginTrigger,
        };
        popUpVisibleEvent(analyticsLoginPopup);
        resetLoginSource();
    }

    handleNumberEntered = (e) => {
        const { value } = e.target;
        e.target.value = e.target.value.slice(0, MAX_PHONE_LENGTH);

        const { setPhoneNumber } = this.props;
        if (isNumber(value) || value === '') {
            setPhoneNumber(value);
        }
    };

    handleKeyDown = (e) => {
        const { generateOtp } = this.props;

        if (!e.target.value) { // handle undefined input
            e.target.value = null;
        }

        if ((e.keyCode >= KEY_CODES.NUMPAD_0 && e.keyCode <= KEY_CODES.NUMPAD_9) || (e.keyCode >= KEY_CODES.NUM0 && e.keyCode <= KEY_CODES.NUM9) || e.keyCode === KEY_CODES.BACKSPACE
            || e.keyCode === KEY_CODES.DELETE || e.keyCode === KEY_CODES.V || e.keyCode === KEY_CODES.C || e.keyCode === KEY_CODES.A
            || e.keyCode === KEY_CODES.X || e.keyCode === KEY_CODES.LEFT_ARROW || e.keyCode === KEY_CODES.RIGHT_ARROW) {
            // TO be Ignored
        }
        else if (e.keyCode === KEY_CODES.ENTER) {
            if (e.target.value.length >= MAX_PHONE_LENGTH) {
                clickEvent({
                    source_name: ANALYTICS_ASSETS.MOBILE_INPUT_SOURCE_NAME,
                    action: ANALYTICS_ACTIONS.SUBMIT_MOB,
                });
                generateOtp();
            }
        }
        else {
            e.preventDefault();
        }
    };


    render() {
        const {
            closeLoginModal,
            generateOtp,
            phoneNumber,
            hideCrossButton: hideCrossButtonFromProps = false,
        } = this.props;

        const { errorMessage } = this.props;
        const hideCrossButton = this.sourceName?.includes('hoichoi/campaign') || this.sourceName.includes(ROUTE_PATHS.DEEPLINK_LANDING_PAGE) || hideCrossButtonFromProps;
        return (
            <div className="modal-content--option-1 modal-content--login-number">
                <div className="modal-header d-flex justify-content-between">
                    <div className="modal-small__logo">
                        <ThumborImage
                            src={IMAGE_PATHS.SITE_DARK_LOGO}
                            alt=""
                        />
                    </div>

                    {!hideCrossButton ? (
                        <AnalyticsButtonComponent
                            id="close-login-popup"
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={closeLoginModal}
                            meta={{ asset_name: 'Login-Close' }}
                        >
                            <i className="icon24 icon-close" />
                        </AnalyticsButtonComponent>
                    ) : null}
                </div>
                <div className="modal-body">
                    <div className="modal-small__title">
                        <h2 id="login-heading" className='text-20 text-small-normal'>{this.LANGUAGE.LOGIN_H1_HEADING}</h2>
                        <h4 id="login-sub-heading" className='text-14 text-small-normal'>{this.LANGUAGE.LOGIN_H2_HEADING}</h4>
                        {/* <h4>{this.LANGUAGE.LABEL_LOGIN}</h4> */}
                    </div>
                    <div className="modal-small__form">

                        <div className="form-group form-group--l-align">
                            <div className="d-flex justify-content-start">
                                <div className="input-phone-wrap-l">
                                    <input
                                        type="text"
                                        className="input-custom input-custom-100 padding-0"
                                        readOnly="readonly"
                                        onFocus={e => e.target.blur()}
                                        tabIndex="-1"
                                        value="+91"
                                    />
                                </div>
                                <div className="input-phone-wrap-r">
                                    <input
                                        id="mobile-number"
                                        type="number"
                                        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                                        className="input-custom input-custom-100"
                                        pattern="[0-9]*"
                                        placeholder={this.LANGUAGE.ENTER_PHONE_NUMBER}
                                        onChange={this.handleNumberEntered}
                                        onKeyDown={this.handleKeyDown}
                                        defaultValue={phoneNumber}
                                        onPaste={handlePasteNumericData}
                                        onWheel={disableScrollOnWheel}
                                    />
                                </div>
                            </div>
                            <span className="input-error mt-2 text-14 text-small-normal">{errorMessage}</span>
                        </div>

                        <div className="form-button">
                            <AnalyticsButtonComponent
                                id="mobile-number-verify-btn"
                                disabled={phoneNumber.length !== MAX_PHONE_LENGTH}
                                className={`button button-theme button-ripple primary-cta common-white-btn ${phoneNumber.length < 10 ? 'button-theme-disabled button-ripple' : ''}`}
                                onClick={generateOtp}
                                meta={{
                                    // source_name: ANALYTICS_ASSETS.MOBILE_INPUT_SOURCE_NAME,
                                    action: ANALYTICS_ACTIONS.SUBMIT_MOB,
                                    asset_name: 'Login-Continue',
                                }}
                            >
                                {this.LANGUAGE.BUTTON_CONTINUE}
                            </AnalyticsButtonComponent>
                            <div className="text-info">
                                <p className='text-small-normal text-12'>
                                    {/* TODO: CHANGE LATER */}
                                    {this.LANGUAGE.I_AGREE}
                                    {' '}
                                    <LinkComponent
                                        id="login-terms-of-uses"
                                        to={ROUTE_PATHS_SETTINGS.SETTINGS_HELP_TERMS_SECTION}
                                        title={this.LANGUAGE.TERMS_OF_USE}
                                        className="info-text"
                                    >
                                        {this.LANGUAGE.TERMS_OF_USE}
                                    </LinkComponent>
                                    {' '}
                                    {this.LANGUAGE.AND}
                                    {' '}
                                    <LinkComponent
                                        id="login-privacy-policy"
                                        to={ROUTE_PATHS_SETTINGS.SETTINGS_HELP_PRIVACY_SECTION}
                                        title={this.LANGUAGE.PRIVACY_POLICY}
                                        className="info-text"
                                    >
                                        {this.LANGUAGE.PRIVACY_POLICY}
                                    </LinkComponent>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MsisdnConfirmation.defaultProps = {
    errorMessage: '',
    sourceOfLoginTrigger: null,
};

MsisdnConfirmation.propTypes = {
    closeLoginModal: PropTypes.func.isRequired,
    setPhoneNumber: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    generateOtp: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    sourceOfLoginTrigger: PropTypes.string,
    resetLoginSource: PropTypes.func.isRequired,
};

export default MsisdnConfirmation;
