
import React from 'react';
import PropTypes from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import LinkComponent from '../link/LinkComponent';
import { getDayMonth, getWeek } from '../../../../utilities/CommonUtil';

const EpisodesCardsTileComponent = (props) => {
    const {
        imageUrl, tileCtaActionUrl, episodeNumber, airDate, name,
    } = props;

    return (
        <div className="item contain-layout">
            <div className="cards-episode-session">
                <LinkComponent
                    to={tileCtaActionUrl}
                    title={name}
                >
                    <div className="cards-episode-session__thumbnail cards-episode-session__thumbnail--sm">
                        <ThumborImage
                            src={imageUrl}
                            alt={name}
                        />
                    </div>
                    <div className="cards-episode-session__details">
                        <p className="episode-date">
                            {getWeek(airDate)}
                            &nbsp;
                            <span>{getDayMonth(airDate)}</span>
                        </p>
                        <p className="episode-no">
                            Episode&nbsp;
                            {episodeNumber}
                        </p>
                    </div>
                </LinkComponent>
            </div>
        </div>
    );
};

EpisodesCardsTileComponent.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
};

EpisodesCardsTileComponent.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    airDate: PropTypes.number.isRequired,
    episodeNumber: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};

export default React.memo(EpisodesCardsTileComponent);
