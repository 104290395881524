import React from 'react';
import PropTypes from 'prop-types';
import PulsingCircle from './PulsingCircle';

function BlinkerComponent(props) {
    const {
        viewBoxLength, delay, color, iteration, opacityInit,
        duration, radiusFrom, radiusTo, opacityFrom, opacityTo,
    } = props;
    return (
        <svg
            className="blinker"
            width={viewBoxLength}
            height={viewBoxLength}
            viewBox={`0 0 ${viewBoxLength} ${viewBoxLength}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <PulsingCircle
                delay={0}
                color={color}
                viewBoxLength={viewBoxLength}
                radiusFrom={radiusFrom}
                radiusTo={radiusTo}
                opacityFrom={opacityFrom}
                opacityTo={opacityTo}
                iteration={iteration}
                opacityInit={opacityInit}
                duration={duration}
            />
            <PulsingCircle
                delay={delay}
                color={color}
                viewBoxLength={viewBoxLength}
                radiusFrom={radiusFrom}
                radiusTo={radiusTo}
                opacityFrom={opacityFrom}
                opacityTo={opacityTo}
                iteration={iteration}
                opacityInit={opacityInit}
                duration={duration}
            />
        </svg>
    );
}

BlinkerComponent.defaultProps = {
    color: '#ED2024',
    iteration: 1,
    opacityInit: 1,
    duration: 1,
    radiusFrom: 0,
    radiusTo: 0,
    opacityFrom: 1,
    opacityTo: 1,
};

BlinkerComponent.propTypes = {
    viewBoxLength: PropTypes.number.isRequired,
    delay: PropTypes.number.isRequired,
    color: PropTypes.string,
    iteration: PropTypes.number,
    opacityInit: PropTypes.number,
    duration: PropTypes.number,
    radiusFrom: PropTypes.number,
    radiusTo: PropTypes.number,
    opacityFrom: PropTypes.number,
    opacityTo: PropTypes.number,
};
export default BlinkerComponent;
