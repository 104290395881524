
import React from 'react';

export default function LockSVGComponent() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="lock-img"
        >
            <circle
                cx="16"
                cy="16"
                r="15.5"
                fill="black"
                fillOpacity="0.5"
                stroke="white"
            />
            <path
                d="M20 13.3333H19.3333V12C19.3333 11.1159 18.9821 10.2681 18.357 9.64294C17.7319 9.01782 16.8841 8.66663 16 8.66663C15.1159 8.66663 14.2681 9.01782 13.643 9.64294C13.0179 10.2681 12.6667 11.1159 12.6667 12V13.3333H12C11.6467 13.3343 11.3082 13.4752 11.0584
                13.725C10.8085 13.9748 10.6677 14.3133 10.6667 14.6666V21.3333C10.6677 21.6866 10.8085 22.0251 11.0584 22.2749C11.3082 22.5248 11.6467 22.6656 12 22.6666H20C20.3533 22.6656 20.6918 22.5248 20.9416 22.2749C21.1915 22.0251 21.3323 21.6866 21.3333 21.3333V14.6666C21.3323
                14.3133 21.1915 13.9748 20.9416 13.725C20.6918 13.4752 20.3533 13.3343 20 13.3333ZM14 12C14 11.4695 14.2107 10.9608 14.5858 10.5857C14.9609 10.2107 15.4696 9.99996 16 9.99996C16.5304 9.99996 17.0391 10.2107 17.4142 10.5857C17.7893 10.9608 18 11.4695 18 12V13.3333H14V12ZM20
                21.3333H12V14.6666H20V21.3333ZM16 19.3333C16.2637 19.3333 16.5215 19.2551 16.7408 19.1086C16.96 18.9621 17.1309 18.7538 17.2318 18.5102C17.3328 18.2666 17.3592 17.9985 17.3077 17.7398C17.2563 17.4812 17.1293 17.2436 16.9428 17.0572C16.7563 16.8707 16.5188 16.7437 16.2601
                16.6922C16.0015 16.6408 15.7334 16.6672 15.4898 16.7681C15.2461 16.869 15.0379 17.0399 14.8914 17.2592C14.7449 17.4785 14.6667 17.7363 14.6667 18C14.6677 18.3533 14.8085 18.6918 15.0584 18.9416C15.3082 19.1914 15.6467 19.3322 16 19.3333Z"
                fill="#EEEEEE"
            />
        </svg>
    );
}
