import React from 'react';
import PropTypes from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import withWatchlistDataHOC from '../../hoc/withWatchlistDataHOC';
import { IMAGE_PATHS } from '../../../../constants/AppConst';
// import { tileClickGaEvent } from '../../../../utilities/GaEvents';

const ChannelLogoTileComponent = (props) => {
    const {
        imageUrl,
        title,
        cpId,
        tileType,
        id,
        railPosition,
        tilePosition,
        railId,
        tileCtaActionUrl,
        packageId,
    } = props;

    const tileAnalyticsMeta = {
        cp_name: !cpId ? '' : cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        package_id: packageId,
    };

    /* eslint-disable */
    return (
        <AnalyticsLinkComponent
            to={tileCtaActionUrl}
            meta={tileAnalyticsMeta}
            title={title}
        >
            <div className="channel-card">
                <div className="logo" style={{ '--aspect-ratio': 1}}>
                    <ThumborImage
                        src={imageUrl}
                        alt={title}
                    />
                </div>
                <div className="subscribed-icon">
                    <ThumborImage
                        src={IMAGE_PATHS.SUBSCRIBED_TICK}
                    />
                </div>
                <h3 className="title">{title}</h3>
            </div>
        </AnalyticsLinkComponent>
    );
    /* eslint-enable */
};

ChannelLogoTileComponent.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    cpId: '',
    id: '',
    tileType: '',
    railPosition: 0,
    tilePosition: 0,
    railId: '',
    packageId: '',
};

ChannelLogoTileComponent.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    cpId: PropTypes.string,
    id: PropTypes.string,
    railPosition: PropTypes.number,
    tileType: PropTypes.string,
    tilePosition: PropTypes.number,
    railId: PropTypes.string,
    packageId: PropTypes.string,
};

export default React.memo(withWatchlistDataHOC(ChannelLogoTileComponent));
