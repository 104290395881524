import React from 'react';
import PropTypes from 'prop-types';
import LanguageProvider from '../../../../providers/LanguageProvider';
import { IMAGE_PATHS } from '../../../../constants/AppConst';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import LockSVGComponent from '../svg/LockSVGComponent';

const LockOverlayComponent = (props) => {
    const {
        onBtnClick, contentDetails,
    } = props;
    const LANGUAGE = LanguageProvider();

    return (
        <div className="locked-content-overlay">
            <LockSVGComponent />
            <div className="overlay-text">
                <button
                    className="transparent-btn sub-btn hover-underline"
                    onClick={(e) => {
                        onBtnClick(e, contentDetails);
                    }}
                    type="button"
                >
                    <span>{LANGUAGE.SUBSCRIBE_TO_WATCH}</span>
                    <ThumborImage
                        className="vertical-center subscribe-arrow"
                        src={IMAGE_PATHS.TILE_LINK_ARROW}
                    />
                </button>
            </div>
        </div>
    );
};

LockOverlayComponent.defaultProps = {
    onBtnClick: () => {},
    contentDetails: {},
};

LockOverlayComponent.propTypes = {
    onBtnClick: PropTypes.func,
    contentDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default LockOverlayComponent;
