import React from 'react';
import PropTypes from 'prop-types';

const SmallLoaderComponent = React.forwardRef((props, ref) => {
    const { showRailSkeleton = false, style = {}, key = '' } = props;
    if (showRailSkeleton) {
        return (
            <div>
                <div className=" smallbox shimmer" />
                <div
                    className="d-flex medium-container"
                    id="loader"
                    {...props}
                    ref={ref}
                >
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                    <div className="mediumbox shimmer" />
                </div>
            </div>
        );
    }
    return (
        <div
            className="loader"
            id="loader"
            style={style}
            key={key}
            {...props}
            ref={ref}
        />

    );
    // <div class="lds-ellipsis" id="loader" {...props} ref={ref}><div></div><div></div><div></div><div></div></div>
});
SmallLoaderComponent.propTypes = {
    isHomePage: PropTypes.bool.isRequired,
    showRailSkeleton: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.string),
    key: PropTypes.string,
};

SmallLoaderComponent.defaultProps = {
    style: {},
    key: '',
    showRailSkeleton: false,
};


export default SmallLoaderComponent;
