import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ANALYTICS_ASSETS, EVENT_SOURCE_NAME } from '@airtel-tv/constants';
import withWatchlistDataHOC from '../../hoc/withWatchlistDataHOC';
import WatchListToggleButtonComponent from '../WatchListToggleButtonComponent';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import isContentLockedHOC from '../../hoc/isContentLockedHOC';
import LockOverlayComponent from '../overlay/LockOverlayComponent';
import LanguageProvider from '../../../../providers/LanguageProvider';
import ProgressBarComponent from '../ProgressBarComponent';
import { getElementById } from '../../../../utilities/WindowUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import TileTag from '../../../../../../../packages/libraries/ui-lib/atoms/TileTag/TileTag';
import { withDeviceUtil } from '@airtel-tv/utils';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';

const TvShowDefault169TileComponent = (props) => {
    const {
        imageUrl,
        tileCtaActionUrl,
        title,
        channelLogoUrl,
        logoUrl,
        channelName,
        watchList,
        watchListToggleButtonClick,
        inWatchList,
        progressPercentage,
        showProgressBar = false,
        contentDetails,
        contentDetails : { shouldAddInWL = true },
        imageSettings,
        tilePosition,
        tileType,
        railId,
        railPosition,
        cpId,
        isLocked,
        onSubscribeButtonClick,
        tileTagIdExcluded,
        deviceUtil: { isMobile },
        packageId,
    } = props;
    const tagDetails = useSelector(store => store.appConfig?.tagDetails);
    const LANGUAGE = LanguageProvider();
    const tileRef = useRef();
    const clearTimeOutIds = {};
    const {
        ageRating, genres,
    } = contentDetails;
    const sourceName = SOSUtil.getRouteSourceNameMappedFromCMS('');

    const isAddedToWatchList = contentId => (watchList[contentId] && Object.keys(watchList[contentId]).length > 0);

    const addToWatchlistAnalyticsMeta = {
        cp_name: cpId,
        content_id: contentDetails.id || null,
        action: isAddedToWatchList(contentDetails.id || null) ? ANALYTICS_ACTIONS.REMOVE_FROM_WATCHLIST_CLICK : ANALYTICS_ACTIONS.ADD_TO_WATCHLIST_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        asset_name: ANALYTICS_ASSETS.WATCHLIST,
        source_name: sourceName,
        package_id: packageId,
    };

    const hoverLayer = !isLocked ? (
        <div className="overlay">
            {/* <div className="watchlist">
                <WatchListToggleButtonComponent
                    contentDetails={contentDetails}
                    inWatchList={inWatchList}
                    watchListToggleButtonClick={watchListToggleButtonClick}
                    addToWatchlistAnalyticsMeta={addToWatchlistAnalyticsMeta}
                />
            </div> */}
            {/* <div className="card-center-play-icon">
                <i className="icon40 icon-player-play" />
            </div> */}
        </div>
    ) : (
        <LockOverlayComponent
            contentDetails={contentDetails}
            onBtnClick={onSubscribeButtonClick}
        />
    );


    const addHoverClass = () => {
        if (isMobile()) {
            return;
        }
        const id = setTimeout(() => {
            if (tileRef?.current) {
                tileRef.current.classList.add('zoom-43');
                tileRef.current.classList.remove('zoomout-43');
            }
        }, 500);
        clearTimeOutIds[contentDetails.id] = id;
    };

    const removeHoverClass = () => {
        const id = clearTimeOutIds[contentDetails.id];
        if (id) {
            clearTimeout(id);
            delete clearTimeOutIds[contentDetails.id];
        }
        if (tileRef?.current) {
            tileRef.current.classList.remove('zoom-43');
            tileRef.current.classList.add('zoomout-43');
        }
    };

    const tagConfig = tagDetails && tileTagIdExcluded ? tagDetails[tileTagIdExcluded]: null;

    return (
        <section className="card-relative-holder">


            <section
                id={`default-43-rail-wrapping-ele-${tilePosition + 1}`}
                className="episodeWrapper contain-layout tvshow43wrapper"
                onMouseEnter={addHoverClass}
                onMouseLeave={removeHoverClass}
                ref={tileRef}
                atm-name={title}
            >
                <Link to={tileCtaActionUrl}>
                    <div className="episodeCards">
                        <div className="pos-relative">
                            <ThumborImage
                                src={imageUrl}
                                alt={title}
                                className="imgCard w-100 h-100"
                                imageSettings={imageSettings}
                            />
                            <div className="gradient-for-img" />
                            {tagConfig && <TileTag tagConfig={tagConfig} />}
                        </div>

                        <div className="title">
                            <ThumborImage
                                src={channelLogoUrl}
                                alt={channelName}
                                className="logo position-absolute"
                            />
                        </div>
                        {/* {hoverLayer} */}

                        <div className="default169logo-holder ">
                            <div className="d-flex justify-content-between child-holder">
                                <h6 className="text-truncate w-50">
                                    {title}

                                </h6>
                                <ThumborImage
                                    src={logoUrl}
                                    alt={channelName}
                                    className="default169logo"
                                />
                            </div>


                            <div className="tags-wrapper">
                                { ageRating && (
                                    <div
                                        className="age-rating line"
                                        id={`atm_age-rating-default-169-${tilePosition + 1}`}
                                    >
                                        { ageRating }
                                    </div>
                                ) }
                                {genres && genres.length > 0 && (
                                    genres.slice(0, 3).map((gen, i) => (
                                        <div
                                            className={`tag ${i < genres.length - 1 ? 'dot' : ''}`}
                                            id={`atm_genre-default-169-${tilePosition + 1}`}
                                        >
                                            { gen }
                                        </div>
                                    ))
                                )}
                            </div>

                            {shouldAddInWL && <AnalyticsButtonComponent
                                className="portrait-tile-watchlist"
                                id={`atm_default-169-rail-watchlist-btn-${tilePosition + 1}`}
                                onClick={(e) => {
                                    watchListToggleButtonClick(contentDetails, addToWatchlistAnalyticsMeta);
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                aria-label={isAddedToWatchList(contentDetails.id) ? 'Added to watchlist' : 'Add to Watchlist'}
                            >
                                { isAddedToWatchList(contentDetails.id)
                                    ? (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M16.125 9.75L10.625 15L7.875 12.375"
                                                stroke="#E8EAED"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="#E8EAED"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>

                                    ) : (
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                stroke="#E8EAED"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M8.25 12H15.75"
                                                stroke="#E8EAED"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M12 8.25V15.75"
                                                stroke="#E8EAED"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    ) }
                                { isAddedToWatchList(contentDetails.id) ? LANGUAGE.ADDED_TO_WATCH_LIST_CAPITALIZE : LANGUAGE.ADD_TO_WATCH_LIST_CAPITALIZE }
                            </AnalyticsButtonComponent>}
                        </div>
                        <ProgressBarComponent
                            progressPercentage={progressPercentage}
                            showProgressBar={showProgressBar}
                        />


                    </div>

                </Link>
            </section>
        </section>
    );
};
TvShowDefault169TileComponent.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    channelLogoUrl: '',
    logoUrl: '',
    channelName: '',
    cpId: '',
    railPosition: 0,
    tileType: '',
    railId: '',
    tilePosition: 0,
    isLocked: false,
    showProgressBar: false,
    packageId: '',
};
TvShowDefault169TileComponent.propTypes = {
    cpId: PropTypes.string,
    railPosition: PropTypes.number,
    railId: PropTypes.string,
    tileType: PropTypes.string,
    tilePosition: PropTypes.number,
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    logoUrl: PropTypes.string,
    channelLogoUrl: PropTypes.string,
    channelName: PropTypes.string,
    watchListToggleButtonClick: PropTypes.func.isRequired,
    inWatchList: PropTypes.bool.isRequired,
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isLocked: PropTypes.bool,
    onSubscribeButtonClick: PropTypes.func.isRequired,
    watchList: PropTypes.object.isRequired,
    progressPercentage: PropTypes.number.isRequired,
    showProgressBar: PropTypes.bool,
    packageId: PropTypes.string,
};

export default React.memo(withDeviceUtil(isContentLockedHOC(withWatchlistDataHOC(TvShowDefault169TileComponent))));
