import React from 'react';

const hoichoiLoaderComponent = () => (
    <div
        className="loader-shimmer  pt-md-4"
        style={{
            margin: '0 auto',
            'max-width': '100%',
        }}
    >
        <div className="shimmer-skeleton">
            <div
                className="bigbox shimmer"
                style={{
                    height: '750px'
                }}
            />
        </div>
    </div>
);


export default hoichoiLoaderComponent;
