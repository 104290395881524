import React from 'react';
import PropTypes from 'prop-types';
import PlanTile from '@airtel-feature/onehub/components/PlanTile/PlanTile';

const SubscriptionTile = props => (
    <PlanTile
        {...props}
    />
);

SubscriptionTile.propTypes = {};

export default SubscriptionTile;
