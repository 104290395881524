import React from 'react';
import PropTypes from 'prop-types';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
// import { tileClickGaEvent } from '../../../../utilities/GaEvents';


const onDragStartEvent = e => e.preventDefault();

const LandscapeCardTileGridComponentSmall = (props) => {
    const {
        imageUrl, tileCtaActionUrl, genre, title, cpId, id, railPosition, tileType, tilePosition, railId,
    } = props;


    function onPortraitTileClick(e) {
        // tileClickGaEvent(id);

        if (e) {
            const { dragging } = props;
            if (dragging !== '') {
                e.stopPropagation();
                e.preventDefault();
            }
        }
    }


    const tileAnalyticsMeta = {
        cp_name: cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
    };

    return (
        <div
            className="cards cards-landscape cards-landscape-grid cards-landscape-grid--small contain-layout"
            style={{ '--aspect-ratio': 0.56 }}
        >
            <AnalyticsLinkComponent
                to={tileCtaActionUrl}
                meta={tileAnalyticsMeta}
                onClick={onPortraitTileClick}
                onDragStart={onDragStartEvent}
                title={title}
            >
                <ThumborImage
                    className="card-img"
                    src={imageUrl}
                    alt={title}
                />
                <div className="card-title d-none">
                    <h5 className="title">{title}</h5>
                </div>
                <div className="overlay">
                    <div className="card-center-play-icon">
                        <i className="icon40 icon-player-play" />
                    </div>
                    <div className="card-bottom-left-details">
                        <h5 className="title">{title}</h5>
                        <div className="title-tagline">
                            <span>{genre}</span>
                        </div>
                    </div>
                </div>
            </AnalyticsLinkComponent>
        </div>
    );
};

LandscapeCardTileGridComponentSmall.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    cpId: '',
    genre: '',
    id: '',
    railPosition: 0,
    tileType: '',
    railId: '',
    tilePosition: 0,
    dragging: '',
};

LandscapeCardTileGridComponentSmall.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    cpId: PropTypes.string,
    genre: PropTypes.string,
    id: PropTypes.string,
    railPosition: PropTypes.number,
    railId: PropTypes.string,
    tileType: PropTypes.string,
    tilePosition: PropTypes.number,
    dragging: PropTypes.string,
};

export default React.memo(LandscapeCardTileGridComponentSmall);
