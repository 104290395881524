import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from '../../scss/components/_error-page.scss';
import { PropTypes } from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import { packageIdForListingPage } from '../../../../utilities/CommonUtil';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { getRelatedContent } from '../../../rail/actions/RelatedContentActions';
import { packageFetchAllDataAction } from '../../../layout/actions/PackageActions';
// import { buildRail } from '../../../layout/builders/RailBuilder';
// import getRail from '../../../rail/factories/RailFactory';
import { updatePageBreadCrumbs } from '../../../breadcrumbs/actions/BreadCrumbsActions';
import { changeMetaData } from '../../../notify/NotifyActions';
import SomeThingWentWrongPopup from './../../../modal-popup/SomeThingWentWrongPopup';

class ErrorPageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.deviceUtil = props.deviceUtil;
        this.state = {
            railElement: null,
        };
    }

    componentDidMount() {
        const {
            packageFetchAllDataActionDispatch, packageId, packageDetails, updatePageBreadCrumbs,changeMetaDataDispatch,
        } = this.props;
        const offset = lodashGet(packageDetails, 'content.length', 0);
        //set no idexing meta tag for 404 pages
        changeMetaDataDispatch({headMetaData:{noIndexing: true}});

        if (packageId) {
            packageFetchAllDataActionDispatch({
                packageId,
                offset,
            });
        }
        updatePageBreadCrumbs({ breadCrumbsList: [] });
    }
    componentDidUpdate(prevProps){
        const { packageContents, changeMetaDataDispatch } = this.props;
        changeMetaDataDispatch({headMetaData:{noIndexing: true}});
        if (prevProps.packageContents !== packageContents) {
            this.getRail();
        }
    }

    getRail = () => {
        const {
            packageContents, cpDetailsById, themeConfig, subscriptionDataById, channels, listingPagePkgId,
        } = this.props;
        if (!packageContents || Object.keys(packageContents).length < 1) {
            return null;
        }

        const slicedContent = lodashGet(packageContents[listingPagePkgId], 'content', []).slice(0, 30);
        const railIndex = 0;
        const numberOfRails = 1;
        let railProps = null;
        let railElement = null;
        import("@airtel-feature/layout/builders/RailBuilder").then(({buildRail}) => {
            railProps = buildRail({
                // layoutDetails: layout,
                items: slicedContent,
                railIndex,
                totalContentCount: packageContents.totalContentCount,
                channels,
                themeConfig,
                numberOfRails,
                cpDetailsById,
                subscriptionDataById,
            });
            import("../../../rail/factories/RailFactory").then(({ getRail }) => {
                railElement = getRail({
                    railProps,
                });
                this.setState({railElement});
            });
        });

    }

    goToHome = () => {
        const { history, navigate, buttonText, action } = this.props;
        if (buttonText && action) {
            return action();
        }
        navigate('/');
    }

    render() {
        const {
            location, imageUrl = '', message, buttonText, errorPageProps, errorPageRailTitle, subText = '',
            showOtherRail = true, deviceUtil
        } = this.props;
        const { railElement } = this.state;
        const pathname = lodashGet(location, 'pathname', '');
        const errorPageBtnText = lodashGet(errorPageProps, 'errorPageBtnText', '');
        const errorMsg = lodashGet(errorPageProps, 'errorMsg', '');
        const errorTitle = lodashGet(errorPageProps, 'errorTitle', '');
        const isScopedWebview = deviceUtil.isScopedWebview();

        return isScopedWebview ? <SomeThingWentWrongPopup /> : (
            <div className={`${pathname === '/404' ? 'error-page-wrapper' : 'error-page-wrapper error-page-new-wrapper'}`}>
                <div className="error-page">
                    <div className="top-area">
                        <img
                            className="astro-image"
                            src={imageUrl ? imageUrl : "/static/astronaut.svg"}
                            alt=""
                            loading="lazy"
                        />
                        {pathname === '/404' && (
                            <>
                                <p className="error-oops">
                                    {errorTitle}
                                </p>
                            </>
                        )}
                        <p className="suggestion">{pathname === '/404' ? errorMsg : message}</p>
                        {subText && <p className="error-subtext">{subText}</p>}
                        <button
                            className="back-home-error common-white-btn"
                            onClick={() => this.goToHome()}
                            type="button"
                        >
                            {buttonText || errorPageBtnText}
                        </button>
                    </div>
                </div>
                {showOtherRail ? <h4 className="errorPage-rail-title">{errorPageRailTitle}</h4> : null}
                {showOtherRail ? railElement : null}
            </div>
        );
    }
}

ErrorPageComponent.defaultProps = {
    buttonText: '',
    action: () => null,
    showOtherRail: true,
};

ErrorPageComponent.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    errorPageRailPackageId: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    action: PropTypes.func,
    deviceUtil: PropTypes.object.isRequired,
    showOtherRail: PropTypes.bool,
};


const mapStateToProps = (state, props) => {
    const {
        userConfig, appConfig, packageContents, themeConfig, channel,
    } = state;
    const errorPageProps = lodashGet(appConfig, 'errorPageProps', '');
    const packageId = lodashGet(errorPageProps, 'errorPageRailPackageId', '');
    const errorPageRailTitle = lodashGet(errorPageProps, 'errorPageRailTitle', '');
    const listingPagePkgId = packageIdForListingPage(packageId);
    const { cpDetailsById } = appConfig;
    const subscriptionDataById = lodashGet(userConfig, 'subscriptionDataById', {});
    const packageDetails = listingPagePkgId ? (packageContents[listingPagePkgId] || null) : null;


    // return prop;
    return {
        packageId,
        packageDetails,
        packageContents,
        errorPageProps,
        themeConfig,
        cpDetailsById,
        subscriptionDataById,
        errorPageRailTitle,
        listingPagePkgId,
        channels: channel.channels,
    };
};
export default withDeviceUtil(connect(mapStateToProps, {
    getRelatedContentDispatch: getRelatedContent,
    packageFetchAllDataActionDispatch: packageFetchAllDataAction,
    updatePageBreadCrumbs,
    changeMetaDataDispatch: changeMetaData,
})(withRouter(withStyles(styles)(withDeviceUtil(ErrorPageComponent)))));
