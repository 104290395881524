import { OTP_VALIDATION } from '../../constants/LoginConst';
import ModalPopupAction from '../modal-popup/ModalPopupActions';

const AuthAction = {
    INIT_AUTH_FROM_STORAGE: 'INIT_AUTH_FROM_STORAGE',
    LOGIN_OTP_GENERATE_INIT: 'LOGIN_OTP_GENERATE_INIT',
    LOGIN_OTP_VALIDATION_INIT: 'LOGIN_OTP_VALIDATION_INIT',
    LOGIN_OTP_VALIDATING: 'LOGIN_OTP_VALIDATING',
    LOGIN_OTP_CONFIRMED: 'LOGIN_OTP_CONFIRMED',
    LOGIN_OTP_FAILED: 'LOGIN_OTP_FAILED',
    LOGIN_REQUEST_INIT: 'LOGIN_REQUEST_INIT',
    LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
    TEMP_LOGIN_REQUEST_SUCCESS: 'TEMP_LOGIN_REQUEST_SUCCESS',
    LOGIN_OTP_GENERATE_SUCCESS: 'LOGIN_OTP_GENERATE_SUCCESS',
    LOGIN_OTP_VALIDATION_SUCCESS: 'LOGIN_OTP_VALIDATION_SUCCESS',
    LOGOUT_REQUEST_INIT: 'LOGOUT_REQUEST_INIT',
    LOGOUT_REQUEST_INIT_SILENT: 'LOGOUT_REQUEST_INIT_SILENT',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_REQUEST_SUCCESS: 'LOGOUT_REQUEST_SUCCESS',
    RESET_MSISDN_NUMBER: 'RESET_MSISDN_NUMBER',
    ON_LOGIN_ERROR: 'ON_LOGIN_ERROR',
    TRIGGER_LOGIN: 'TRIGGER_LOGIN',
    UPDATE_MSISDN: 'UPDATE_MSISDN',
    RESET_MSISDN_OTP: 'RESET_MSISDN_OTP',
    REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
    CLEAR_ACCESS_TOKEN: 'CLEAR_ACCESS_TOKEN',
    DTH_SUBSCRIPTION_INIT: 'DTH_LOGIN_OTP_VALIDATION_INIT',
    DTH_SUBSCRIPTION_SUCCESS: 'DTH_LOGIN_OTP_VALIDATION_INIT_SUCCESS',
    RESET_LOGIN_SOURCE: 'RESET_LOGIN_SOURCE',
    HAPPY_CODE_GENERATE_INIT: 'HAPPY_CODE_GENERATE_INIT',
    HAPPY_CODE_GENERATE_SUCCESS: 'HAPPY_CODE_GENERATE_SUCCESS',
    REFRESH_AUTH_CONFIG: 'REFRESH_AUTH_CONFIG',
    IS_USER_ONLINE: 'IS_USER_ONLINE',
    ENCODED_BRANCH_KEY: 'ENCODED_BRANCH_KEY',
    DEVICE_SESSION_OUT: 'DEVICE_SESSION_OUT',
    GENERATE_QR_LOGIN_CODE: 'GENERATE_QR_LOGIN_CODE',
    GENERATE_QR_LOGIN_SUCCESS: 'GENERATE_QR_LOGIN_SUCCESS',
    CHECK_USER_LOGGED_IN: 'CHECK_USER_LOGGED_IN',
    RESET_LOGIN_INFO: 'RESET_LOGIN_INFO',
};

export default AuthAction;

export const authInitFromStorage = () => ({
    type: AuthAction.INIT_AUTH_FROM_STORAGE,
});

export const clearAccessToken = () => ({
    type: AuthAction.CLEAR_ACCESS_TOKEN,
});

export const refreshTokenSuccess = ({ token, accessToken }) => ({
    type: AuthAction.REFRESH_TOKEN_SUCCESS,
    token,
    accessToken,
});

export const otpRegenerateInit = (msisdn, viaCall) => ({
    type: AuthAction.LOGIN_OTP_GENERATE_INIT,
    msisdn,
    viaCall,
});

export const logoutInit = ({ redirectUrl } = { redirectUrl: null }) => ({
    type: AuthAction.LOGOUT_REQUEST_INIT,
    redirectUrl,
});

export const logoutInitSilent = () => ({
    type: AuthAction.LOGOUT_REQUEST_INIT_SILENT,
});

export const otpValidateInit = ({ otp, redirectUrl }) => ({
    type: AuthAction.LOGIN_OTP_VALIDATION_INIT,
    otp,
    redirectUrl,
});

export const showValidationLoader = () => ({
    type: AuthAction.LOGIN_OTP_VALIDATING,
    val: OTP_VALIDATION.IN_PROGRESS,
});

export const confirmValidationLoader = () => ({
    type: AuthAction.LOGIN_OTP_CONFIRMED,
    val: OTP_VALIDATION.SUCCEED,
});

export const errorValidationLoader = () => ({
    type: AuthAction.LOGIN_OTP_FAILED,
    val: OTP_VALIDATION.FAILED,
});

export const loginInit = msisdn => ({
    type: AuthAction.LOGIN_REQUEST_INIT,
    msisdn,
});

export const loginRequestSuccess = payload => ({
    type: AuthAction.LOGIN_REQUEST_SUCCESS,
    ...payload,
});

export const encodingBranchKeySuccess = payload => ({
    type: AuthAction.ENCODED_BRANCH_KEY,
    payload,
});

export const tempLoginRequestSuccess = payload => ({
    type: AuthAction.TEMP_LOGIN_REQUEST_SUCCESS,
    ...payload,
});

export const resetMsisdn = () => ({
    type: AuthAction.RESET_MSISDN_NUMBER,
});

export const updateMsisdn = msisdn => ({
    type: AuthAction.UPDATE_MSISDN,
    msisdn,
});

export const triggerLogin = payload => ({
    type: AuthAction.TRIGGER_LOGIN,
    ...payload,
});

export const resetOtp = () => ({
    type: AuthAction.RESET_MSISDN_OTP,
});

export const logoutRequestSuccess = () => ({
    type: AuthAction.LOGOUT_REQUEST_SUCCESS,
});

export const dthSubscriptionPlanInit = key => ({
    type: AuthAction.DTH_SUBSCRIPTION_INIT,
    key,
});

export const dthSubscriptionPlanSuccess = payload => ({
    type: AuthAction.DTH_SUBSCRIPTION_SUCCESS,
    payload,
});

export const resetLoginSource = payload => ({
    type: AuthAction.RESET_LOGIN_SOURCE,
    payload,
});

export const generateHappyCode = payload => ({
    type: AuthAction.HAPPY_CODE_GENERATE_INIT,
    ...payload,
});

export const generateHappyCodeSuccess = payload => ({
    type: AuthAction.HAPPY_CODE_GENERATE_SUCCESS,
    ...payload,
});

export const resetAuthConfig = () => ({
    type: AuthAction.REFRESH_AUTH_CONFIG,
});

export const isUserOnline = () => ({
    type: AuthAction.ON_LOGIN_ERROR,
    error: null,
});

export const showModalComponentAction = payload => ({
    type: ModalPopupAction.SHOW_MODAL_POPUP,
    ...payload,
});

export const generateQrLoginCode = () => ({
    type: AuthAction.GENERATE_QR_LOGIN_CODE,
});

export const generateQrLoginSuccess = payload => ({
    type: AuthAction.GENERATE_QR_LOGIN_SUCCESS,
    ...payload,
});

export const checkUserLoggedIn = payload => ({
    type: AuthAction.CHECK_USER_LOGGED_IN,
    ...payload,
});

export const resetLoginInfo = () => ({
    type: AuthAction.RESET_LOGIN_INFO,
});
