import { AuthenticationUtil } from '@airtel-tv/utils/AuthenticationUtil';
import AuthAction from './AuthAction';


const initialState = JSON.parse(`{
    "userName": "",
    "msisdn": "",
    "accessToken": "",
    "uid": "",
    "token": "",
    "isAuthenticated": false,
    "msisdnDetected": false,
    "isOtpSent": false,
    "otpError": null,
    "error": null,
    "errorCode": "",
    "loginTriggered": false,
    "logoutTriggered": false,
    "redirectUrl": null,
    "activationId": null,
    "success": false,
    "deviceResponse": {},
    "tvLoginStatus": {},
    "navigateToHomeOnLoginClose": false
}`);

const AuthReducer = (state = initialState, action = {}) => {
    let changes = {};

    switch (action.type) {
        case AuthAction.INIT_AUTH_FROM_STORAGE:
            changes = {
                uid: AuthenticationUtil.getUid(),
                token: AuthenticationUtil.getAuthToken(),
                isAuthenticated: AuthenticationUtil.isAuthenticated(),
                msisdnDetected: AuthenticationUtil.getMsisdnDetected(),
                accessToken: AuthenticationUtil.getAccessToken(),
            };
            break;

        case AuthAction.LOGOUT_REQUEST:
            changes = {
                errorCode: '',
                token: '',
                uid: '',
                msisdn: '',
                isOtpSent: false,
                loginTriggered: false,
                logoutTriggered: true,
            };
            break;

        case AuthAction.LOGOUT_REQUEST_SUCCESS:
            changes = {
                isAuthenticated: false,
                logoutTriggered: false,
            };
            break;

        case AuthAction.TEMP_LOGIN_REQUEST_SUCCESS:
            AuthenticationUtil.saveAuthToken(action.token);
            AuthenticationUtil.saveUid(action.uid);

            changes = {
                token: action.token,
                accessToken: action.authToken,
                uid: action.uid,
                errorCode: '',
                isAuthenticated: false,
                loginTriggered: false,
            };
            break;

        case AuthAction.LOGIN_REQUEST_SUCCESS:
            // store locale
            AuthenticationUtil.saveAuthToken(action.token);
            AuthenticationUtil.saveUid(action.uid);

            changes = {
                token: action.token,
                accessToken: action.authToken,
                uid: action.uid,
                errorCode: '',
                isAuthenticated: AuthenticationUtil.isAuthenticated(),
            };
            break;
        case AuthAction.TOKEN_CHANGE:
            AuthenticationUtil.saveAuthToken(action.token);
            AuthenticationUtil.saveUid(action.uid);
            changes = {
                token: action.token,
                uid: action.uid,
                isAuthenticated: AuthenticationUtil.isAuthenticated(),
            };
            // console.log("chhhhh",changes);
            break;
        case AuthAction.VALIDATE_DELETE_OTP_SUCCESS:
            // store locale
            AuthenticationUtil.saveAuthToken(action.token);
            AuthenticationUtil.saveUid(action.uid);

            changes = {
                sessionToken: action.sessionToken,
                activeUid: action.activeUid,
                message: action.message,
                token: action.token,
                isApiSuccess: true,
                otpError: null,
                // accessToken: action.authToken,
                // uid: action.uid,
                // errorCode: '',
                isAuthenticated: AuthenticationUtil.isAuthenticated(),
            };
            break;

        case AuthAction.LOGIN_OTP_GENERATE_SUCCESS:
            changes = {
                isOtpSent: true,
                msisdn: action.msisdn,
                errorCode: '',
                message: action.message,
                success: action.success,
                otpResponse: { response: true },
            };
            break;

        case AuthAction.LOGIN_OTP_VALIDATION_SUCCESS:

            AuthenticationUtil.saveAuthToken(action.token);
            AuthenticationUtil.saveAccessToken(action.authToken);
            AuthenticationUtil.saveUid(action.uid);
            AuthenticationUtil.saveMsisdnDetected(action.msisdnDetected);

            changes = {
                msisdnDetected: action.msisdnDetected,
                token: action.token,
                accessToken: action.authToken,
                uid: action.uid,
                isAuthenticated: AuthenticationUtil.isAuthenticated(),
                errorCode: '',
                loginTriggered: false,
                activationId: null,
                sessionId: null,
                success: action.success,
                deviceResponse: action.deviceResponse,
                hideCrossButton: false,
            };
            break;

        case AuthAction.RESET_MSISDN_NUMBER:
            changes = {
                msisdn: '',
                isOtpSent: false,
                errorCode: '',
            };
            break;

        case AuthAction.ON_LOGIN_ERROR:
            changes = {
                error: action.error,
            };
            break;

        case AuthAction.TRIGGER_LOGIN:
            changes = {
                loginTriggered: action.loginTriggered,
                redirectUrl: action.redirectUrl || null,
                sourceOfLoginTrigger: action.sourceOfLoginTrigger,
                navigateToHomeOnLoginClose: !!action?.navigateToHomeOnLoginClose,
                errorCode: '',
                activationId: action.activationId || null,
                sessionId: action.sessionId || null,
                hideCrossButton: action.hideCrossButton || false,
            };
            break;

        case AuthAction.UPDATE_MSISDN:
            changes = {
                msisdn: action.msisdn,
                errorCode: '',
            };
            break;

        case AuthAction.REFRESH_TOKEN_SUCCESS:
            AuthenticationUtil.saveAccessToken(action.accessToken);

            changes = {
                accessToken: action.accessToken,
                isAuthenticated: AuthenticationUtil.isAuthenticated(),
                errorCode: '',
            };
            break;

        case AuthAction.CLEAR_ACCESS_TOKEN:
            AuthenticationUtil.clearAccessToken();

            changes = {
                isAuthenticated: false,
                accessToken: '',
            };
            break;

        case AuthAction.DTH_SUBSCRIPTION_SUCCESS:
            changes = {
                rechargeUrl: action.rechargeUrl,
            };
            break;

        case AuthAction.RESET_LOGIN_SOURCE:
            changes = {
                sourceOfLoginTrigger: null,
            };
            break;

        case AuthAction.HAPPY_CODE_GENERATE_SUCCESS:
            changes = {
                happyCode: action.code,
            };
            break;

        case AuthAction.REFRESH_AUTH_CONFIG:
            changes = initialState;
            break;

        case AuthAction.IS_USER_ONLINE:
            changes = {
                errorCode: '',
            };
            break;

        case AuthAction.ENCODED_BRANCH_KEY:
            changes = {
                encryptedBranchKey: action.payload,
            };
            break;
        case AuthAction.VALIDATE_DELETE_OTP_ERROR:
            changes = {
                otpError: action.otpError,
            };
            break;
        case AuthAction.UPDATE_TV_LOGIN_STATUS:
            changes = {
                tvLoginStatus: { ...action.payload },
            };
            break;
        case AuthAction.SET_AUTO_LOGIN: {
            changes = {
                autoLoginInProgress: action.autoLoginInProgress,
            };
            break;
        }
        default:
            return state;
    }

    return {
        ...state,
        ...changes,
    };
};

export default AuthReducer;
