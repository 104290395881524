import React from 'react';
import PropTypes from 'prop-types';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import withWatchlistDataHOC from '../../hoc/withWatchlistDataHOC';
import { connect } from 'react-redux';
import TileTag from '@airtel-tv/ui-lib/atoms/TileTag/TileTag';
// import WatchListToggleButtonComponent from '../WatchListToggleButtonComponent';
// import { tileClickGaEvent } from '../../../../utilities/GaEvents';

const TrendingCardTileComponent = (props) => {
    const {
        imageUrl,
        tileCtaActionUrl,
        // genre,
        title,
        cpId,
        id,
        railPosition,
        tileType,
        tilePosition,
        railId,
        logoUrl,
        // segment,
        imageSettings,
        channelLogoUrl,
        // watchListToggleButtonClick,
        // inWatchList,
        // contentDetails,
        packageId,
        tileTagId,
        tileTagIdExcluded,
        tagDetails,
        zionTileId = '',
    } = props;
   
    const tileTagIdToShow = tileTagId || tileTagIdExcluded;
    const tagConfig = tagDetails && tileTagIdToShow ? tagDetails[tileTagIdToShow] : null;

    const tileAnalyticsMeta = {
        cp_name: cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        package_id: packageId,
        tile_tag: tileTagIdToShow || 'none',
        tileId: zionTileId || 'none',
    };

    return (
        <div
            className="trending-search-cards contain-layout"
            style={{ '--aspect-ratio': 0.56 }}
            id={`atm_trending-tile-${tilePosition + 1}`}
        >
            <AnalyticsLinkComponent
                to={tileCtaActionUrl}
                meta={tileAnalyticsMeta}
                title={title}
                // onClick={() => tileClickGaEvent(id)}
            >
                <ThumborImage
                    className="card-img"
                    src={imageUrl}
                    alt={title}
                    imageSettings={imageSettings}
                />
                {tagConfig && <TileTag tagConfig={tagConfig} />}
                <div className="card-title d-flex justify-content-between align-items-bottom">
                    <div className="title">
                        {(logoUrl || channelLogoUrl) ? (
                            <ThumborImage
                                title=""
                                className="logo"
                                alt={cpId}
                                src={logoUrl || channelLogoUrl}
                                imageSettings={imageSettings}
                            />
                        ) : null}
                    </div>
                </div>

                {/* {
                    segment && segment === TAGS.PREMIUM_TAG
                        ? (
                            <div className="premium-label">
                                <span className="premium-label__text">Premium</span>
                                <i className="icon16 icon-premium" />
                            </div>
                        ) : null
                } */}
                <div className="card-title d-none">
                    <h5 className="title">{title}</h5>
                </div>
                {/* <div className="overlay">
                    <div className="watchlist">
                        <WatchListToggleButtonComponent
                            contentDetails={contentDetails}
                            inWatchList={inWatchList}
                            watchListToggleButtonClick={watchListToggleButtonClick}
                        />
                    </div>
                    <div className="card-center-play-icon">
                        <i className="icon40 icon-player-play" />
                    </div>
                    <div className="card-bottom-left-details">
                        <h5 className="title">{title}</h5>
                        <div className="title-tagline">
                            <span>{genre}</span>
                        </div>
                    </div>
                </div> */}
            </AnalyticsLinkComponent>
        </div>
    );
};

TrendingCardTileComponent.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    cpId: '',
    // genre: '',
    id: '',
    railPosition: 0,
    tileType: '',
    railId: '',
    tilePosition: 0,
    logoUrl: '',
    // segment: '',
    channelLogoUrl: '',
    packageId: '',
};

TrendingCardTileComponent.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    cpId: PropTypes.string,
    // genre: PropTypes.string,
    id: PropTypes.string,
    railPosition: PropTypes.number,
    railId: PropTypes.string,
    tileType: PropTypes.string,
    tilePosition: PropTypes.number,
    logoUrl: PropTypes.string,
    // segment: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    channelLogoUrl: PropTypes.string,
    // watchListToggleButtonClick: PropTypes.func.isRequired,
    // inWatchList: PropTypes.bool.isRequired,
    // contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    packageId: PropTypes.string,
};

const mapStateToProps = ({
    appConfig: {
        tagDetails = {}
    } = {}
}) => {
    return {
        tagDetails
    }
}

export default React.memo(withWatchlistDataHOC(connect(mapStateToProps)(TrendingCardTileComponent)));
