// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cont-progress{width:100%;position:absolute;bottom:12px;z-index:9;padding:0px 15px}.cont-progress .cont-progress-bar{height:4px;background-color:rgba(238,238,238,.5);display:block;-webkit-border-radius:8px;-moz-border-radius:8px;-ms-border-radius:8px;border-radius:8px}.cont-progress .cont-progress-bar .progress-completed{width:0%;display:block;-webkit-border-radius:8px;-moz-border-radius:8px;-ms-border-radius:8px;border-radius:8px;background-color:var(--default-brand);height:4px;background-color:var(--theme-brand, var(--default-brand))}", ""]);
// Exports
exports.locals = {
	"mobileWidth": "767px",
	"mediaLandscapeHeight": "500px",
	"mediaLandscapeWidth": "990px",
	"tabletLargeWidth": "1200px"
};
module.exports = exports;
