import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import withWatchlistDataHOC from '../../hoc/withWatchlistDataHOC';
import WatchListToggleButtonComponent from '../WatchListToggleButtonComponent';
import isContentLockedHOC from '../../hoc/isContentLockedHOC';
import LockOverlayComponent from '../overlay/LockOverlayComponent';
import LanguageProvider from '../../../../providers/LanguageProvider';
import { getElementById } from '../../../../utilities/WindowUtil';
import {withDeviceUtil} from '@airtel-tv/utils/withDeviceUtil';
import { PROGRAM_TYPES } from '../../../../constants/AppConst';
import { convertRailTitle } from '../../../../utilities/CommonUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import TileTag from '../../../../../../../packages/libraries/ui-lib/atoms/TileTag/TileTag';
import { ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
// import { tileClickGaEvent } from '../../../../utilities/GaEvents';

// 4 by 3
const TvShowBig43TileComponent = (props) => {
    const {
        imageUrl,
        tileCtaActionUrl,
        genre,
        title,
        cpId,
        id,
        railPosition,
        tileType,
        tilePosition,
        railId,
        imageSettings,
        // segment,
        logoUrl,
        channelLogoUrl,
        watchListToggleButtonClick,
        inWatchList,
        contentDetails,
        contentDetails : { shouldAddInWL = true, programType },
        isLocked,
        onSubscribeButtonClick,
        watchList,
        railTitle,
        deviceUtil: { isMobile },
        tileTagIdExcluded,
        carouselElementRef,
        railRef,
        hoveredTile,
        setHoveredTile,
        packageId,
        zionTileId = '',
    } = props;

    const tagDetails = useSelector(store => store.appConfig?.tagDetails);
    const isFocused = useRef();
    const tileRef = useRef();
    const {
        ageRating, genres,
    } = contentDetails;

    const LANGUAGE = LanguageProvider();
    const clearTimeOutIds = {};
    const { ADD_TO_WATCH_LIST_CAPITALIZE = 'Add To Watchlist', ADDED_TO_WATCH_LIST_CAPITALIZE = 'Added To Watchlist' } = LANGUAGE;
    const sourceName = SOSUtil.getRouteSourceNameMappedFromCMS('');
    const tileAnalyticsMeta = {
        cp_name: cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        source_name: sourceName,
        package_id: packageId,
        tile_tag: tileTagIdExcluded || 'none',
        tileId: zionTileId || 'none',
    };

    const addToWatchlistAnalyticsMeta = {
        cp_name: cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.ADD_TO_WATCHLIST_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        asset_name: ANALYTICS_ASSETS.WATCHLIST,
        source_name: sourceName,
        package_id: packageId,
    };

    const hoverLayer = !isLocked ? (
        <div className="overlay">
            <div className="watchlist">
                <WatchListToggleButtonComponent
                    contentDetails={contentDetails}
                    inWatchList={inWatchList}
                    watchListToggleButtonClick={watchListToggleButtonClick}
                    addToWatchlistAnalyticsMeta={addToWatchlistAnalyticsMeta}
                />
            </div>
            <div className="card-center-play-icon">
                <i className="icon40 icon-player-play" />
            </div>
            <div className="card-bottom-left-details">
                <h5 className="title">{title}</h5>
                <div className="title-tagline">
                    <span>{genre}</span>
                </div>
            </div>
        </div>
    ) : (
        <LockOverlayComponent
            contentDetails={contentDetails}
            onBtnClick={onSubscribeButtonClick}
        />
    );

    const isAddedToWatchList = contentId => (watchList[contentId] && Object.keys(watchList[contentId]).length > 0);

    const addHoverClass = () => {
        const tId = setTimeout(() => {
            if (isMobile()) {
                return;
            }
            if (!isFocused.current) {
                clearTimeout(tId);
                return;
            }
            setHoveredTile(tilePosition);
            if (railId) {
                const railTarget = document.getElementById(railId);
                railTarget?.classList?.add("padding-booster");
                const targetParent = document.getElementById(`${ELEMENT_ID.LANDSCAPE_RAIL}-${railId}-${railPosition}`);
                targetParent?.classList?.add('shrink-space');
            } else {
                carouselElementRef?.current?.classList?.add("padding-booster");
                railRef?.current?.classList?.add('shrink-space');
            }

        }, 500);
        isFocused.current = true;
    };

    const removeHoverClass = () => {
        const tId = clearTimeOutIds[contentDetails.id];
        if (tId) {
            clearTimeout(tId);
            delete clearTimeOutIds[contentDetails.id];
        }
        if (tileRef?.current) {
            tileRef.current.classList.remove('scale-tile');
            tileRef.current.classList.add('scale-down-tile');
        }
        if (railId) {
            const railTarget = document.getElementById(railId);
            railTarget?.classList.remove("padding-booster");
            const targetParent = document.getElementById(`${ELEMENT_ID.LANDSCAPE_RAIL}-${railId}-${railPosition}`);
            targetParent?.classList.remove('shrink-space');
        } else {
            carouselElementRef?.current?.classList?.remove("padding-booster");
            railRef?.current?.classList?.remove('shrink-space');
        }
        setHoveredTile('');
        isFocused.current = false;
    };

    const tagConfig = tagDetails && tileTagIdExcluded ? tagDetails[tileTagIdExcluded]: null;

    return (
        <li className="custom-portrait-relative-holder custom-landscape-size">
            <section
                id={`big-43-rail-wrapping-ele-${tilePosition + 1}`}
                className={`custom-card-absolute ${hoveredTile === tilePosition ? 'zoom-43 scale-tile': 'zoomout-43 scale-down-tile'}`}
                onMouseEnter={addHoverClass}
                onMouseLeave={removeHoverClass}
                ref={tileRef}
                atm-name={title}
            >
                
                    <AnalyticsLinkComponent
                        to={tileCtaActionUrl}
                        meta={tileAnalyticsMeta}
                        className={tagConfig && Object.keys(tagConfig).length ? 'no-left-border-radius' : ''}
                        // title={title}
                        // onClick={() => tileClickGaEvent(id)}
                    >

                        <div className='position-relative w-100 h-100'>
                        <ThumborImage
                                className="custom-tile-image"
                                src={imageUrl}
                                alt={title}
                                imageSettings={imageSettings}
                            />
                            <div
                                className="custom-tile-play"
                                id={`atm_play-btn-big-43-${tilePosition + 1}`}
                            >
                                <ThumborImage
                                    src="/static/play_ic.svg"
                                />
                            </div>
                        </div>
                            
                            <div className="custom-potrait-card-shade" >
                            {tagConfig && <TileTag tagConfig={tagConfig}/>}
                            <div className="custom-detail-wrapper">

                             
                                        <h6 className="title text-bolder-10">{title}</h6>

                                    

                                <div className="tags-area">
                                    { ageRating && (
                                        <div
                                            className="age-rating"
                                            id={`atm_age-rating-big-43-${tilePosition + 1}`}
                                        >
                                            { ageRating }
                                        </div>
                                    ) }
                                    {/* <div className="tag yellow-text line">IMDb 6.6</div> */}
                                    {genres && genres.length > 0 && (
                                        genres.slice(0, 3).map((gen, i) => (
                                            <p
                                                className={`tag text-normal-12 ${i < genres.length - 1 ? 'dot' : ''}`}
                                                id={`atm_genre-big-43-${tilePosition + 1}`}
                                            >
                                                <span>{ gen }</span>
                                            </p>
                                        ))
                                    )}
                                </div>
                                {shouldAddInWL && programType !== PROGRAM_TYPES.TRAILER && <AnalyticsButtonComponent
                                    className="button-for-portrait-tile"
                                    id={`atm_big-43-add-to-watchlist-${convertRailTitle(railTitle)}-${tilePosition + 1}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        watchListToggleButtonClick(contentDetails, addToWatchlistAnalyticsMeta);
                                    }}
                                    aria-label={isAddedToWatchList(contentDetails.id) ? 'Added to watchlist' : 'Add to Watchlist'}
                                >
                                    { isAddedToWatchList(contentDetails.id)
                                        ? (
                                            <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M16.125 9.75L10.625 15L7.875 12.375"
                                                    stroke="#E8EAED"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                    stroke="#E8EAED"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>

                                        ) : (
                                            <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                                    stroke="#E8EAED"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M8.25 12H15.75"
                                                    stroke="#E8EAED"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M12 8.25V15.75"
                                                    stroke="#E8EAED"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        ) }
                                    { isAddedToWatchList(contentDetails.id) ? ADDED_TO_WATCH_LIST_CAPITALIZE : ADD_TO_WATCH_LIST_CAPITALIZE }
                                </AnalyticsButtonComponent>}

                            </div>
                                </div>
                            
                       


                        {/* {
                    segment && segment === TAGS.PREMIUM_TAG
                        ? (
                            <div className="premium-label">
                                <span className="premium-label__text">Premium</span>
                                <i className="icon16 icon-premium" />
                            </div>
                        ) : null
                } */}
                        <div className="card-title d-none">
                            <h5 className="title">{title}</h5>
                        </div>

                        <div>
                            {
                                (logoUrl || channelLogoUrl)
                                    ? (
                                        <ThumborImage
                                            title=""
                                            className="logo d-none"
                                            alt=""
                                            src={logoUrl || channelLogoUrl}
                                        />
                                    )
                                    : null}

                            
                        </div>

                        {/* {hoverLayer} */}
                    </AnalyticsLinkComponent>
               
            </section>
        </li>
    );
};

TvShowBig43TileComponent.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    cpId: '',
    genre: '',
    id: '',
    railPosition: 0,
    tileType: '',
    railId: '',
    tilePosition: 0,
    logoUrl: '',
    // segment: '',
    channelLogoUrl: '',
    isLocked: false,
    // episodeRefs: [],
    packageId: '',
};

TvShowBig43TileComponent.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    cpId: PropTypes.string,
    genre: PropTypes.string,
    id: PropTypes.string,
    railPosition: PropTypes.number,
    railId: PropTypes.string,
    tileType: PropTypes.string,
    // segment: PropTypes.string,
    tilePosition: PropTypes.number,
    logoUrl: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    channelLogoUrl: PropTypes.string,
    watchListToggleButtonClick: PropTypes.func.isRequired,
    inWatchList: PropTypes.bool.isRequired,
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isLocked: PropTypes.bool,
    onSubscribeButtonClick: PropTypes.func.isRequired,
    deviceUtil: PropTypes.object.isRequired,
    watchList: PropTypes.object.isRequired,
    // episodeRefs: PropTypes.array, // eslint-disable-line react/forbid-prop-types
    packageId: PropTypes.string,
};

export default React.memo(withDeviceUtil(isContentLockedHOC(withWatchlistDataHOC(TvShowBig43TileComponent))));
