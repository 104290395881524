import React from 'react';
import PropTypes from 'prop-types';

const HeaderTileComponent = (props) => {
    const {
        railTitle,
    } = props;
    return (

        <h5 className="section-title contain-layout">
            {/* <span>
            <i class="icon30 icon-library"></i>
        </span> */}
            {railTitle}
        </h5>
    );
};

HeaderTileComponent.defaultProps = {
};

HeaderTileComponent.propTypes = {
    railTitle: PropTypes.string.isRequired,
};

export default React.memo(HeaderTileComponent);
