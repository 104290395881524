import React from 'react';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { RoutingUtil, LocationUtil } from '@airtel-tv/utils';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import EventType from '@airtel-tv/analytics/EventType';
import { contentDetailsEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { IMAGE_PATHS } from '@airtel-tv/constants';
import { getSourceName } from '../../../../utilities/CommonUtil';
import { CDP_EVENT_CONST } from '../../../../constants/AppConst';

const PeopleTileComponent = (props) => {
    const {
        contentDetails: {
            displayTitle = '', characterName = ' ', roleType = '',
            images = {},
        } = { },
        packageId,
    } = props;
    const IMAGESETTINGS = {
        500: 150,
        300: 100,
    };

    const imageUrl = images?.PORTRAIT;
    const tileClicked = (credit) => {
        const {
            history, location, pageId, navigate,
        } = props;

        const meta = {
            action: CDP_EVENT_CONST.STARNAME_CLICK,
            source_name: getSourceName(location.pathname),
            content_name: credit.characterName,
            package_id: packageId,
        };
        contentDetailsEvent(meta, EventType.STARNAME_CLICK);
        history.replace({
            pathname: location.pathname,
            search: LocationUtil.objectToQueryParams({ pageId }),
        });
        navigate(`${RoutingUtil.getArtistUrlWeb({
            name: credit.displayTitle,
            id: credit.id,
        })}`);
    };

    const capitalizeRoleType = () => (roleType.charAt(0).toUpperCase() + roleType.slice(1).toLowerCase());

    return (
        <section
            className="staring-tile"
            onClick={() => tileClicked(props)}
            tabIndex={0}
        >
            <a>
                {imageUrl ? (
                    <ThumborImage
                        alt={displayTitle}
                        src={imageUrl}
                        imageSettings={IMAGESETTINGS}
                    />
                ) : (
                    <ThumborImage
                        alt={displayTitle}
                        src={IMAGE_PATHS.ARTIST_DEFAULT_ICON}
                    />
                )}
                <p className="cast-name">
                    {roleType && <div className="role-type">{ capitalizeRoleType() }</div> }
                    <p>
                        { characterName }
                    </p>
                </p>
            </a>
        </section>
    );
};

PeopleTileComponent.defaultProps = {
    contentDetails: {},
    packageId: '',
};

PeopleTileComponent.propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    contentDetails: PropTypes.objectOf(PropTypes.object),
    pageId: PropTypes.string.isRequired,
    packageId: PropTypes.string,
};

export default withRouter(React.memo(PeopleTileComponent));
