import React, {
    useState, useRef, useEffect, useMemo,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { ACTIVE_TILE_AFTER_TIMEOUT, ELEMENT_ID, LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { BUTTON_IMAGE_TITLE, TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import lodashGet from 'lodash/get';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import TrailerContainer from '@airtel-feature/content-details/content-detail-page/container/TrailerContainer';
import { playbackWithoutLoginDetailsFetchAction } from '@airtel-feature/playback/actions/PlaybackActions';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import SubscriptionUtil from '@airtel-tv/utils/SubscriptionUtil';
import { AnalyticsButtonComponent } from '@airtel-tv/analytics';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import useStyles from 'isomorphic-style-loader/useStyles';
import { checkWindowExist } from '@airtel-tv/utils';
import styles from '../../scss/components/ControlBarComponent.scss';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import { addTrailerData } from '../../../notify/NotifyActions';
import LanguageProvider from '../../../../providers/LanguageProvider';
import TileTrailerControlBarComponent from '../../../playbackComponents/components/control-bars/TileTrailerControlBarComponent';
import ProgressBarComponent from '../ProgressBarComponent';
import { PROGRAM_TYPES } from '../../../../constants/AppConst';
import SeekBarComponent from '../../../playbackComponents/components/player-controls/SeekBarComponent';

function useOnScreen(ref) {
    const [
        isIntersecting,
        setIntersecting,
    ] = useState(false);

    const observer = useMemo(() => new IntersectionObserver(
        ([
            entry,
        ]) => setIntersecting(entry.isIntersecting), {
            root: null,
            threshold: 0.87,
        },
    ), [
        ref,
    ]);

    useEffect(() => {
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    return isIntersecting;
}

const TrailerTileComponent = (props) => {
    const {
        imageUrl,
        tileType,
        contentDetails,
        contentDetails: { playAsTrailer = false, id },
        playbackDetails,
        title,
        railId,
        tilePosition,
        tileCtaActionUrl,
        history,
        trailerRefId,
        isAuthenticated,
        deviceUtil: { isMobile, hasTouchScreen, isIOS },
        railPosition,
        subscriptionDataById,
        cpDetailsById,
        cpId,
        trailerTileVideoResolutionLimiter,
        trailersRailRef,
        packageId,
    } = props;
    // const [ clearTimeOutIds, setClearId ] = useState();
    useStyles(styles);
    const trailerTileRef = useRef();
    const trailerContRef = useRef();
    const isHovered = useRef(false);
    const [
        tileHovered,
        setTileHovered,
    ] = useState(false);
    const [
        isTileVisible,
        setIsTileVisible,
    ] = useState(false);
    const LANGUAGE = LanguageProvider();
    const {
        WATCH_SERIES = 'Watch Series', PLAY_CAPITALISE = 'Play', Trailer_TAG = 'Trailer', BANNER_WATCH_NOW = 'Watch Now',
    } = LANGUAGE;
    const tileAnalyticsMeta = {
        content_id: contentDetails.id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        package_id: packageId,
    };
    const clearTimeOutId = {};

    const ua = checkWindowExist() ? navigator.userAgent : '';
    const isMobileDevice = isMobile() && hasTouchScreen(ua);

    const [
        trailerLoaded,
        setTrailerLoaded,
    ] = useState(false);
    const [
        playTrailer,
        setPlayTrailer,
    ] = useState(false);
    const [
        isMute,
        setMute,
    ] = useState(true);
    const [
        playerControls,
        setPlayerControls,
    ] = useState({});
    const [
        showControlBar,
        setControlBarVisibility,
    ] = useState(false);
    const [
        currentTime,
        setCurrentTime,
    ] = useState(0);
    const [
        meta,
        setMeta,
    ] = useState({});

    const tileClicked = () => {
    };

    const isVisible = checkWindowExist() && useOnScreen(trailerTileRef);
    if (isVisible && !isTileVisible) {
        const tId = setTimeout(() => {
            setIsTileVisible(true);
        }, 200);
        clearTimeOutId[`${railId}${contentDetails.id}`] = tId;
    }
    else if (!isVisible && isTileVisible) {
        clearTimeOutId && clearTimeout(clearTimeOutId[`${railId}${contentDetails.id}`]);
        setIsTileVisible(false);
    }

    const addHoverClass = () => {
        const {
            playbackWithoutLoginDetailsFetchActionDispatch, deviceUtil: { isMobile }, trailerplaybackDetails, contentDetails: { id = '', playAsTrailer = false },
        } = props;
        if (isMobile()) {
            return;
        }
        isHovered.current = true;

        const tId = setTimeout(() => {
            if (!isHovered.current) {
                return;
            }
            setTileHovered(true);
            if ((trailerRefId || playAsTrailer) && !trailerplaybackDetails) {
                playbackWithoutLoginDetailsFetchActionDispatch({
                    isTrailer: true,
                    contentId: playAsTrailer ? id : trailerRefId,
                    isTrailerTile: true,
                });
            }
            const railTarget = document.getElementById(railId);
            railTarget && railTarget?.classList.add('padding-booster');
            trailersRailRef && trailersRailRef?.current.classList.add('shrink-space');
            setPlayTrailer(true);
        }, ACTIVE_TILE_AFTER_TIMEOUT);
        clearTimeOutId[`${railId}${contentDetails.id}`] = tId;
    };

    useEffect(() => {
        const { playbackWithoutLoginDetailsFetchActionDispatch, trailerplaybackDetails } = props;

        if (isMobile() && isTileVisible) {
            if (trailerRefId) {
                setPlayTrailer(true);
                // if (!trailerplaybackDetails) {
                    playbackWithoutLoginDetailsFetchActionDispatch({
                        isTrailer: true,
                        contentId: trailerRefId,
                    });
                // }
            }
        }
        else {
            setPlayTrailer(false);
        }
    }, [
        isTileVisible,
    ]);

    useEffect(() => {
        const { syncTrailerDataDispatch } = props;
        if (currentTime > 0) {
            const payload = {
                [trailerRefId]: {
                    lastWatchedPosition: currentTime,
                },
            };
            syncTrailerDataDispatch({ payload });
        }
    }, [
        currentTime,
    ]);

    const removeHoverClass = () => {
        isHovered.current = false;
        const tId = clearTimeOutId && clearTimeOutId[`${railId}${contentDetails.id}`];
        setTileHovered(false);
        setPlayTrailer(false);
        clearTimeout(tId);
        delete clearTimeOutId[`${railId}${contentDetails.id}`];
        const railTarget = document.getElementById(railId);
        railTarget?.classList?.remove('padding-booster');
        trailersRailRef && trailersRailRef?.current.classList.remove('shrink-space');
    };

    const playTrailerPromise = (playerFunc) => {
        setPlayerControls({ ...playerFunc });
        setControlBarVisibility(true);
        if (!playerFunc) {
            return;
        }
        const userMutePref = browserStore.get(LOCAL_STORE_KEYS.TRAILER_AUTOPLAY_MUTE) === 'true';
        userMutePref ? playerFunc?.volumeChange(0, false, false) : playerFunc?.volumeChange(100, false, false);
        setMute(userMutePref);
    };

    const getCTAText = () => {
        const { programType } = contentDetails;
        if (programType === PROGRAM_TYPES.MOVIE) {
            return PLAY_CAPITALISE;
        }
        return WATCH_SERIES;
    };

    const handleVolume = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!playerControls || Object.keys(playerControls) <= 0) {
            return;
        }
        if (isMute) {
            playerControls?.volumeChange(100, false, false);
            setMute(false);
            browserStore.set(LOCAL_STORE_KEYS.TRAILER_AUTOPLAY_MUTE, 'false');
        }
        else {
            playerControls?.volumeChange(0, false, false);
            setMute(true);
            browserStore.set(LOCAL_STORE_KEYS.TRAILER_AUTOPLAY_MUTE, 'true');
        }
    };

    const toggleTrailerPoster = (loaded) => {
        setTrailerLoaded(loaded);
    };

    const chId = SubscriptionUtil.getChannelId({
        cpDetailsById,
        cpId,
    });
    const channelDetails = lodashGet(cpDetailsById, `[${chId}]`, null);
    const subscriptionDetails = lodashGet(subscriptionDataById, `[${chId}]`, null);
    const targetHierarchy = lodashGet(channelDetails, 'targetHierarchy', 0);
    const isSubscribed = SubscriptionUtil.isSubscribed(subscriptionDetails, targetHierarchy);
    const watchNowCtaActionUrl = (isAuthenticated && isSubscribed && tileCtaActionUrl) ? `${tileCtaActionUrl}?showPlayback=true&fromHome=true` : tileCtaActionUrl;

    const renderTrailer = (!isMobile() ? tileHovered : isVisible);
    const showTrailerComponent = trailerLoaded && renderTrailer;

    return (
        <li
            className={`episodeWrapper contain-layout continue-tile-wrapper cwWrapper align-trailer-card ${tileHovered ? 'zoom-cw' : 'zoom-out-cw'} ${isMobileDevice ? 'trailer-tile-mobile' : ''}`}
            onMouseEnter={addHoverClass}
            onMouseLeave={removeHoverClass}
            onBlur={removeHoverClass}
            ref={trailerTileRef}
        >
            <AnalyticsLinkComponent
                to={tileCtaActionUrl}
                meta={tileAnalyticsMeta}
            >
                <section

                    className={`episodeCards  ${!showTrailerComponent ? 'show' : 'hide-player-gredient'}`}
                >
                    { !isMobile() ? (
                        <ThumborImage
                            src={imageUrl}
                            alt={title}
                            className={`imgCard w-100  h-100 full-height opaque-cont ${!showTrailerComponent ? 'show' : 'hide'}`}
                            imageSettings={{
                                1000: 300,
                                500: 300,
                            }}
                            imageChanged
                        />
                    )
                        : (
                            <img
                                src={imageUrl}
                                alt={title}
                                className={`imgCard w-100 h-100 full-height opaque-cont ${!showTrailerComponent ? 'show' : 'hide'}`}
                            />
                        )}
                    <div
                        className={`gradient-for-img  ${!showTrailerComponent ? 'show' : ' '}`}
                    >
                        <p className="title-trailer-gredient">{ title }</p>
                        <>
                            <p className="tag-trailer-gredient">Trailer</p>
                            <AnalyticsButtonComponent
                                aria-label="Trailer Tile Volume Button"
                                className="trailer-card-volume-btn"
                                onClick={e => handleVolume(e)}
                            >
                                { isMute ? (
                                    <svg
                                        className="volume-img"
                                        width="25"
                                        height="24"
                                        viewBox="0 0 25 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.46826 8.25V15.75"
                                            stroke="#E8EAED"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M6.34375 3.7489L21.9688 20.2489"
                                            stroke="#E8EAED"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M16.5 14.4739V21L9.46875 15.75H4.78125C4.57405 15.75 4.37534 15.671 4.22882 15.5303C4.08231 15.3897 4 15.1989 4 15V9C4 8.80109 4.08231 8.61032 4.22882 8.46967C4.37534 8.32902 4.57405 8.25 4.78125 8.25H9.46875L10.135 7.7525"
                                            stroke="#E8EAED"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M12.6089 5.90522L16.4999 3V10.0141"
                                            stroke="#E8EAED"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )
                                    : (
                                        <svg
                                            className="volume-img"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M8.72778 15.9709H3.81742C3.60037 15.9709 3.3922 15.8873 3.23873 15.7383C3.08525 15.5894 2.99902 15.3874 2.99902 15.1768V8.82324C2.99902 8.61261 3.08525 8.41061 3.23873 8.26167C3.3922 8.11273 3.60037 8.02905 3.81742 8.02905H8.72778L16.0933 2.46973V21.5303L8.72778 15.9709Z"
                                                stroke="#E8EAED"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M8.72754 8.02905V15.9709"
                                                stroke="#E8EAED"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M20.041 9.75394C20.345 10.0489 20.5861 10.3991 20.7506 10.7846C20.9151 11.17 20.9998 11.5831 20.9998 12.0002C20.9998 12.4174 20.9151 12.8305 20.7506 13.2159C20.5861 13.6014 20.345 13.9516 20.041 14.2466"
                                                stroke="#E8EAED"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    ) }
                            </AnalyticsButtonComponent>
                        </>
                    </div>
                    { !(isMobileDevice && isIOS()) && playbackDetails
                    && (
                        <div className={`opaque-cont fit-video-container ${showTrailerComponent ? 'show' : 'hide'}`}>
                            {renderTrailer ? (
                                <TrailerContainer
                                    controlBar={TileTrailerControlBarComponent}
                                    playbackData={playbackDetails}
                                    playableId={playAsTrailer ? id : trailerRefId}
                                    playTrailer={playTrailer}
                                    canPlayTrailer={playTrailerPromise}
                                    contentDetails={contentDetails}
                                    videoResolutionLimiter={trailerTileVideoResolutionLimiter}
                                    isTrailerTile
                                    controlBarProps={{
                                        trailerLoaded: toggleTrailerPoster,
                                        setCurrentTime,
                                        setMeta,
                                        trailerRefId,
                                        title,
                                        subtitle: Trailer_TAG,
                                    }}
                                    trailerRefId={trailerRefId}
                                />
                            ) : null}
                        </div>
                    )
                    }
                    <div className="continue-tile-time-wrap">
                        <div className="cw-tile-progress-holder">
                            <p className="time hideOnhover">{ title }</p>
                            {trailerLoaded ? (
                                <>
                                    <SeekBarComponent
                                        max={meta.duration}
                                        currentTime={currentTime}
                                        progress={0}
                                        onChange={() => {}}
                                        pause={() => {}}
                                        play={() => {}}
                                        isPaused={false}
                                        // previewImageInfo={previewImageInfo}
                                        getProgressBarRef={() => null}
                                        setSeekPauseStatus={() => {}}
                                    />
                                    {' '}
                                    <AnalyticsButtonComponent
                                        className="trailer-card-volume-btn btn-for-mweb"
                                        onClick={e => handleVolume(e)}
                                    >
                                        { isMute ? (
                                            <svg
                                                className="volume-img"
                                                width="25"
                                                height="24"
                                                viewBox="0 0 25 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.46826 8.25V15.75"
                                                    stroke="#E8EAED"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M6.34375 3.7489L21.9688 20.2489"
                                                    stroke="#E8EAED"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M16.5 14.4739V21L9.46875 15.75H4.78125C4.57405 15.75 4.37534 15.671 4.22882 15.5303C4.08231 15.3897 4 15.1989 4 15V9C4 8.80109 4.08231 8.61032 4.22882 8.46967C4.37534 8.32902 4.57405 8.25 4.78125 8.25H9.46875L10.135 7.7525"
                                                    stroke="#E8EAED"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M12.6089 5.90522L16.4999 3V10.0141"
                                                    stroke="#E8EAED"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        )
                                            : (
                                                <svg
                                                    className="volume-img"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8.72778 15.9709H3.81742C3.60037 15.9709 3.3922 15.8873 3.23873 15.7383C3.08525 15.5894 2.99902 15.3874 2.99902 15.1768V8.82324C2.99902 8.61261 3.08525 8.41061 3.23873 8.26167C3.3922 8.11273 3.60037 8.02905 3.81742 8.02905H8.72778L16.0933 2.46973V21.5303L8.72778 15.9709Z"
                                                        stroke="#E8EAED"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M8.72754 8.02905V15.9709"
                                                        stroke="#E8EAED"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M20.041 9.75394C20.345 10.0489 20.5861 10.3991 20.7506 10.7846C20.9151 11.17 20.9998 11.5831 20.9998 12.0002C20.9998 12.4174 20.9151 12.8305 20.7506 13.2159C20.5861 13.6014 20.345 13.9516 20.041 14.2466"
                                                        stroke="#E8EAED"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            ) }
                                    </AnalyticsButtonComponent>
                                    {' '}

                                </>
                            ) : null
                            }
                        </div>
                        <div className={`dummy-seekbar ${showTrailerComponent ? 'd-none' : 'hide'}`} />
                        <AnalyticsLinkComponent
                            className="view-info-for-cw"
                            to={watchNowCtaActionUrl}
                        >
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                    stroke="#E8EAED"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M14.6379 11.6061L10.5472 9.06778C10.4783 9.025 10.3994 9.00163 10.3186 9.00008C10.2379 8.99853 10.1582 9.01885 10.0877 9.05895C10.0173 9.09906 9.95863 9.15749 9.91781 9.22825C9.87699 9.29901 9.85547 9.37953 9.85547 9.46154V14.5386C9.85553 14.6206 9.87709 14.7011 9.91793 14.7718C9.95877 14.8425 10.0174 14.9009 10.0878 14.941C10.1583 14.9811 10.2379 15.0014 10.3187 14.9999C10.3994 14.9984 10.4783 14.9751 10.5472 14.9324L14.6379 12.3941C14.7044 12.3528 14.7593 12.2949 14.7974 12.2259C14.8355 12.1569 14.8555 12.0792 14.8555 12.0001C14.8555 11.921 14.8355 11.8432 14.7974 11.7742C14.7593 11.7053 14.7044 11.6474 14.6379 11.6061Z"
                                    fill="#E8EAED"
                                />
                            </svg>
                            { BANNER_WATCH_NOW }
                        </AnalyticsLinkComponent>
                    </div>
                </section>

                { isMobileDevice && (
                    <section className="dur-title-mweb">
                        <p className="time">{title }</p>
                        <AnalyticsLinkComponent
                            className="mweb-icon-btn"
                            to={watchNowCtaActionUrl}
                        >
                            { BANNER_WATCH_NOW }
                            <ThumborImage
                                src="/static/Play-icon-mweb.svg"
                            />
                        </AnalyticsLinkComponent>
                    </section>
                ) }
            </AnalyticsLinkComponent>
        </li>
    );
};

TrailerTileComponent.defaultProps = {
    packageId: '',
};

TrailerTileComponent.propTypes = {
    packageId: PropTypes.string,
};

function mapStateToProps(state, props) {
    const {
        playbackDetails = {},
        authConfig,
        appConfig: {
            cpDetailsById, trailerTileVideoResolutionLimiter = {
                MAX_WIDTH: 800,
                MAX_HEIGHT: 800,
            },
        },
        userConfig: { subscriptionDataById },
    } = state;
    const { contentDetails } = props;
    const trailerRefId = contentDetails?.trailers?.[0]?.refId || (contentDetails.programType === PROGRAM_TYPES.TRAILER && contentDetails?.playableId);

    return {
        trailerRefId,
        playbackDetails,
        trailerplaybackDetails: playbackDetails[trailerRefId],
        isAuthenticated: authConfig.isAuthenticated,
        cpDetailsById,
        subscriptionDataById,
        trailerTileVideoResolutionLimiter,
    };
}

export default connect(
    mapStateToProps, {
        playbackWithoutLoginDetailsFetchActionDispatch: playbackWithoutLoginDetailsFetchAction,
        syncTrailerDataDispatch: addTrailerData,
    },
)(withDeviceUtil(withRouter(React.memo(TrailerTileComponent))));
