/* eslint-disable array-bracket-newline */
/* eslint-disable array-element-newline */
import React, {
    useState, useEffect, useRef,
} from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import withRouter from '@airtel-tv/lib/hoc/WithRouterHOC';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { playbackWithoutLoginDetailsFetchAction } from '@airtel-feature/playback/actions/PlaybackActions';
import TrailerContainer from '@airtel-feature/content-details/content-detail-page/container/TrailerContainer';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import useStyles from 'isomorphic-style-loader/useStyles';
import { IMAGE_SETTINGS } from '@airtel-tv/constants/ImagesConst';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { convertRailTitle, getSourceNameFromStorage, setAnalyticsMeta } from '@airtel-tv/utils/GlobalUtil';
import {
    ANALYTICS_ACTIONS, ANALYTICS_ASSETS, PLAYER_CLICK_EVENTS,
} from '@airtel-tv/constants/AnalyticsConst';
import { AUTO_VOLUME_AFTER_TIMEOUT, DEBOUNCED_TIMEOUT, LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { withWatchlistDataHOC } from '@airtel-tv/lib';
import { contentVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';
import TickIcon from '@airtel-tv/ui-lib/atoms/icons/components/TickIcon';
import PlusIcon from '@airtel-tv/ui-lib/atoms/icons/components/PlusIcon';
import PYWTileDetailContentComponent from '../pyw-detail/PYWTileDetailContentComponent';
import styles from './PYWTileComponent.scss';
import { IMAGE_PATHS } from '../../../../constants/AppConst';

const PYWTileComponent = (props) => {
    const {
        imageUrl,
        contentDetails,
        contentDetails: {
            playAsTrailer = false, id: contentId, shouldAddInWL = true, programType,
        },
        trailerRefId,
        trailerplaybackDetails,
        playbackWithoutLoginDetailsFetchActionDispatch,
        playbackDetails,
        railId,
        tileCtaActionUrl,
        deviceUtil: {
            isMobile, isTabletViewPort, hasTouchScreen, isIOS,
        },
        pywWatchListToggleButtonClick,
        watchList = {},
        railTitle,
        tilePosition,
        railPosition,
        tileType,
        packageId,
        cpId,
        activeTilePosition,
        analyticsPageId = '',
        match: {
            params: { pathname } = {},
        } = {},
        videoEndedCallback,
        currentSource,
        railType,
        setDisableTileNavigation,
    } = props;
    useStyles(styles);
    const isMobileDevice = isMobile() || isTabletViewPort();
    const { prevSource } = getSourceNameFromStorage();
    const timerRef = useRef(null);
    const tileAnalyticsMeta = {
        content_id: contentId,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_type: railType,
        rail_id: railId,
        asset_position: tilePosition,
        package_id: packageId,
        source_name: currentSource,
        source_page: prevSource,
        tile_click_cta: 'View Details',
        program_type: programType,
        asset_name: isMobileDevice ? ANALYTICS_ASSETS.CONTENT_NAME_CLICK : '',
        rail_title: railTitle,
        page_id: analyticsPageId,
    };

    const muteAnalyticsMeta = {
        source_name: currentSource,
        source_page: prevSource,
        action: 'mute',
        content_id: contentId,
        asset_position: tilePosition,
        rail_type: railType,
        rail_id: railId,
        package_id: packageId,
        rail_title: railTitle,
        page_id: analyticsPageId,
    };


    const isAddedToWatchList = contentid => (watchList && watchList[contentid] && Object.keys(watchList[contentid]).length > 0);
    const addedToWatchList = isAddedToWatchList(contentId);

    const addToWatchlistAnalyticsMeta = {
        cp_name: cpId,
        content_id: contentId,
        action: addedToWatchList ? ANALYTICS_ACTIONS.REMOVE_FROM_WATCHLIST : ANALYTICS_ACTIONS.ADD_TO_WATCHLIST_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        asset_name: ANALYTICS_ASSETS.WATCHLIST,
        source_name: currentSource,
        package_id: packageId,
        source_page: prevSource,
        watchlist_count: Object.values(watchList)?.length,
        rail_type: railType,
        page_id: analyticsPageId,
        rail_title: railTitle,
    };
    const {
        PYW_CTA: {
            VIEW_DETAILS = 'View Details',
            ADDED_TO_WATCHLIST = 'Added To Watchlist',
            ADD_TO_WATCHLIST = 'Add To Watchlist',
            WATCHLIST = 'Watchlist',
            ADDED = 'Added',
        } = {},
    } = LanguageProviderUtil?.getLanguage();
    const ADDED_TO_WATCHLIST_TEXT = isMobileDevice ? ADDED : ADDED_TO_WATCHLIST;
    const ADD_TO_WATCHLIST_TEXT = ADD_TO_WATCHLIST;

    const removeWatchlistIconPath = isMobileDevice ? IMAGE_PATHS.REMOVE_TO_WATCHLIST_GREEN : IMAGE_PATHS.REMOVE_TO_WATCHLIST_BLACK;

    const [showTrailer, setShowTrailer] = useState(false);
    const [trailerLoaded, setTrailerLoaded] = useState(false);
    const [playerControls, setPlayerControls] = useState({});
    const [currentTime, setCurrentTime] = useState(0);
    const meta = useRef({});
    const [isMute, setMute] = useState(true);
    const [trackTime, setTrackTime] = useState(0);
    const trailerTileRef = useRef();
    const trailerTimerRef = useRef(0);
    const playbackDetail = trailerplaybackDetails || playbackDetails[contentId];

    const contentVisibleMeta = {
        page_id: analyticsPageId,
        rail_id: railId,
        rail_position: railPosition,
        rail_title: railTitle,
        source_name: currentSource,
        source_page: prevSource,
        rail_type: railType,
        package_id: packageId,
        content_id: contentId,
        asset_position: tilePosition,
    };


    const showTrailerOnHover = () => {
        if (activeTilePosition === tilePosition && !showTrailer) {
            contentVisibleEvent(contentVisibleMeta);
            setAnalyticsMeta(contentId);
            if ((trailerRefId || playAsTrailer)) {
                playbackWithoutLoginDetailsFetchActionDispatch({
                    isTrailer: true,
                    contentId: playAsTrailer ? contentId : trailerRefId,
                    isTrailerTile: true,
                });
            }
        }
    };

    useEffect(() => {
        if ((trailerRefId || playAsTrailer) && playbackDetail?.success) {
            setShowTrailer(true);
        }
        else {
            setShowTrailer(false);
        }
    }, [
        playbackDetail,
    ]);

    const debouncedAddHover = (cancelTimer) => {
        if (isMobileDevice) {
            clearTimeout(trailerTimerRef.current);
        }
        if (!isMobileDevice && trailerTimerRef.current) {
            return;
        }
        if (!cancelTimer) {
            setDisableTileNavigation(true);
            trailerTimerRef.current = setTimeout(() => {
                showTrailerOnHover();
                clearTimeout(trailerTimerRef.current);
                trailerTimerRef.current = null;
                if (!isMobileDevice) {
                    setDisableTileNavigation(false);
                }
            }, DEBOUNCED_TIMEOUT);
        }
    };

    const removeHover = () => {
        debouncedAddHover(true);
        setShowTrailer(false);
        setTrailerLoaded(false);
        setTrackTime(0);
    };

    useEffect(() => {
        if (activeTilePosition === tilePosition) {
            debouncedAddHover();
        }
        else {
            removeHover();
        }
    }, [
        activeTilePosition,
        tilePosition,
    ]);

    const handleVolume = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!playerControls || Object.keys(playerControls) <= 0) {
            return;
        }

        if (isMute) {
            playerControls?.volumeChange(100, false, false);
            setMute(false);
            browserStore.set(LOCAL_STORE_KEYS?.PYW_TRAILER_AUTOPLAY_MUTE, 'false');
        }
        else {
            playerControls?.volumeChange(0, false, false);
            setMute(true);
            browserStore.set(LOCAL_STORE_KEYS?.PYW_TRAILER_AUTOPLAY_MUTE, 'true');
        }
        // clickEvent(analyticsMeta);
    };

    const playTrailerPromise = (playerFunc) => {
        setPlayerControls({ ...playerFunc });
        setTrailerLoaded(true);
        const muteState = browserStore.get(LOCAL_STORE_KEYS?.PYW_TRAILER_AUTOPLAY_MUTE);
        if (Object.keys(playerFunc)?.length && (muteState === 'true' || muteState === 'false')) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                const unmute = muteState === 'false';
                unmute ? playerFunc?.volumeChange(100, false, false) : playerFunc?.volumeChange(0, false, false);
                setMute(!unmute);
            }, AUTO_VOLUME_AFTER_TIMEOUT);
        }
        // if (!playerFunc) {
        //     return;
        // }
    };

    const playerCallbackHandler = (playerState = {}) => {
        const {
            isTrailerEnded, isTrailerPlaying, meta: videoMeta, playbackCurrentTime, isTrailerLoaded, isPlayerError,
        } = playerState;
        if (isTrailerEnded || (isPlayerError && !isTrailerPlaying)) {
            setShowTrailer(false);
            setTrailerLoaded(false);
            if (videoEndedCallback && typeof videoEndedCallback === 'function') {
                videoEndedCallback();
            }
        }
        if (videoMeta) {
            meta.current = videoMeta;
        }
        if (playbackCurrentTime) {
            setCurrentTime(playbackCurrentTime);
        }
        if (isTrailerLoaded) {
            setTrailerLoaded(true);
        }
    };

    useEffect(() => {
        if (meta?.current?.duration && typeof currentTime === 'number') {
            const progressRatio = currentTime / meta?.current?.duration;
            const progressBarWidth = progressRatio * 100;
            setTrackTime(progressBarWidth);
        }
    }, [currentTime, meta]);

    useEffect(() => () => {
        removeHover();
    }, []);

    let activeTileClass = activeTilePosition === tilePosition ? 'pyw_link_focus' : 'pyw_link_blur';
    activeTileClass += activeTilePosition === tilePosition && trailerLoaded ? ' show_trailer' : ' hide_trailer';
    const isActive = activeTilePosition === tilePosition;
    return (
        <li
            className={`pyw_link ${activeTileClass}`}
            ref={trailerTileRef}
        >
            <AnalyticsLinkComponent
                meta={tileAnalyticsMeta}
                to={tileCtaActionUrl}
                id={`pyw_${railPosition}_${tilePosition}`}
                className={`pyw-trailer-wrapper ${isActive ? 'active' : ''}`}
            >
                <ThumborImage
                    src={imageUrl}
                    className="imgCard w-100  h-100 full-height opaque-cont show"
                    imageSettings={IMAGE_SETTINGS.PYW_TILES}
                    imageChanged
                    defaultImageWidth={2000}
                />

                {/* Trailer */}
                {isActive && showTrailer ? (
                    <div
                        className="trailer-wrap-cont fit-video-container"
                        style={{ opacity: trailerLoaded ? 1 : 0 }}
                    >
                        <TrailerContainer
                            playbackData={playbackDetails}
                            playableId={playAsTrailer ? contentId : trailerRefId}
                            playTrailer
                            contentDetails={contentDetails}
                            canPlayTrailer={playTrailerPromise}
                            // videoResolutionLimiter={trailerTileVideoResolutionLimiter}
                            isTrailerTile
                            enableAnalyticsOnTile
                            trailerRefId={trailerRefId}
                            playerCallbackHandler={playerCallbackHandler}
                        />

                        {/* Control Bars */}
                        {Object.keys(playerControls)?.length ? (
                            <AnalyticsButtonComponent
                                className="mute-btn"
                                onClick={handleVolume}
                                meta={{
                                    ...muteAnalyticsMeta,
                                    action: isMute ? PLAYER_CLICK_EVENTS.UNMUTE : PLAYER_CLICK_EVENTS.MUTE,
                                }}
                            >
                                {isMute ? (
                                    <ThumborImage
                                        src={IMAGE_PATHS.MUTE_ICON}
                                        imageSettings={IMAGE_SETTINGS.SVG_ICONS}
                                    />
                                ) : (
                                    <ThumborImage
                                        src={IMAGE_PATHS.UNMUTE_ICON}
                                        imageSettings={IMAGE_SETTINGS.SVG_ICONS}
                                    />
                                )}
                            </AnalyticsButtonComponent>
                        ) : null}

                        {meta?.current?.duration ? (
                            <div className="pyw-seekbar">
                                <span
                                    className="progress"
                                    style={{ width: `${trackTime}%` }}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {/* Content Details */}
                {isActive || isMobileDevice ? (
                    <div className="content">
                        <div className="content-wrapper">
                            <PYWTileDetailContentComponent
                                {...contentDetails}
                                isMobileDevice
                                tileCtaActionUrl={tileCtaActionUrl}
                                tileAnalyticsMeta={tileAnalyticsMeta}
                            />
                            <div className="cta-container">
                                {shouldAddInWL ? (
                                    <AnalyticsButtonComponent
                                        className={`button-for-portrait-tile pyw-action-btn primary watchlist-icon text-16 text-bold mobile-text-12 mobile-text-normal ${addedToWatchList ? 'added-to-watch-list' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            pywWatchListToggleButtonClick(contentDetails, addToWatchlistAnalyticsMeta);
                                        }}
                                        id={`atm_add-to-watchlist-${convertRailTitle(railTitle)}-${tilePosition + 1}`}
                                    >
                                        {/* <ThumborImage
                                            src={addedToWatchList ? removeWatchlistIconPath : IMAGE_PATHS.ADD_TO_WATCHLIST_BLACK}
                                            className={`watchlist-image ${addedToWatchList && 'added-to-watchlist'}`}
                                            imageChanged
                                        /> */}
                                        <div className={`watchlist-image ${addedToWatchList && 'added-to-watchlist'}`}>
                                            {addedToWatchList ? <TickIcon iconProps={isMobileDevice ? { color: '#7CCE7A' } : {}} /> : <PlusIcon />}
                                        </div>

                                        <span className="add_to_watchlist_label">{ addedToWatchList ? ADDED_TO_WATCHLIST_TEXT : ADD_TO_WATCHLIST_TEXT }</span>
                                    </AnalyticsButtonComponent>
                                ) : null}

                                <AnalyticsLinkComponent
                                    to={tileCtaActionUrl}
                                    className="pyw-action-btn non-primary text-16 text-bold"
                                    meta={tileAnalyticsMeta}
                                >
                                    {VIEW_DETAILS}
                                </AnalyticsLinkComponent>
                            </div>
                        </div>
                        <div className="mobile-description">
                            {contentDetails?.description}
                        </div>
                    </div>
                ) : null}
            </AnalyticsLinkComponent>
        </li>
    );
};


PYWTileComponent.propTypes = {
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    contentDetails: PropTypes.object,
    // contentDetails: { playAsTrailer = false, id: contentId },
    trailerRefId: PropTypes.any,
    trailerplaybackDetails: PropTypes.object,
    playbackWithoutLoginDetailsFetchActionDispatch: PropTypes.func.isRequired,
    playbackDetails: PropTypes.object,
    railId: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    pywWatchListToggleButtonClick: PropTypes.func.isRequired,
    watchList: PropTypes.object,
    railTitle: PropTypes.string,
    tilePosition: PropTypes.number,
    railPosition: PropTypes.number,
    tileType: PropTypes.string,
    packageId: PropTypes.string,
    cpId: PropTypes.string,
    activeTilePosition: PropTypes.number,
    match: PropTypes.object,
};


PYWTileComponent.defaultProps = {
    title: '',
    imageUrl: '',
    contentDetails: {},
    // contentDetails: { playAsTrailer = false, id: contentId },
    trailerRefId: null,
    trailerplaybackDetails: null,
    playbackDetails: {},
    railId: '',
    tileCtaActionUrl: '',
    watchList: {},
    railTitle: '',
    tilePosition: 0,
    railPosition: 0,
    tileType: '',
    packageId: '',
    cpId: '',
    activeTilePosition: 0,
    match: {},
};

function mapStateToProps(state, props) {
    const {
        playbackDetails = {},
        syncContent: {
            watchList,
        },
        location: { navigationObj: { currentSource = '' } = {} } = {},
    } = state;
    const trailerRefId = props?.contentDetails?.trailers?.[0]?.refId;

    return {
        trailerRefId,
        trailerplaybackDetails: playbackDetails[trailerRefId],
        playbackDetails,
        watchList,
        currentSource,
    };
}

export default connect(mapStateToProps, {
    playbackWithoutLoginDetailsFetchActionDispatch: playbackWithoutLoginDetailsFetchAction,
})(withDeviceUtil(withRouter(withWatchlistDataHOC(PYWTileComponent))));
