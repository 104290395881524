import React from 'react';
import PropTypes from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
// import { tileClickGaEvent } from '../../../../utilities/GaEvents';

const ExploreTileComponent = (props) => {
    const {
        imageUrl,
        tileCtaActionUrl,
        title,
        tileType,
        id,
        railPosition,
        tilePosition,
        railId,
        imageSettings,
        showGrid,
        style,
        packageId,
    } = props;

    const tileAnalyticsMeta = {
        tile_name: title,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        package_id: packageId,
    };

    return (
        <section
            className={`explore-card${showGrid ? ' grid' : ''} contain-layout`}
            style={{
                '--aspect-ratio': 1,
                ...style,
            }}
        >
            <AnalyticsLinkComponent
                to={tileCtaActionUrl}
                meta={tileAnalyticsMeta}
                title={title}
            >
                <ThumborImage
                    src={imageUrl}
                    imageSettings={imageSettings}
                    alt={title}
                />
                {/* <div className="card-overlay-gradient card-overlay-gradient pos-bottom cover">
                    <h5 className="title pos-bottom cover">{title}</h5>
                </div> */}
            </AnalyticsLinkComponent>
        </section>
    );
};

ExploreTileComponent.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    id: '',
    tileType: '',
    railPosition: 0,
    tilePosition: 0,
    railId: '',
    showGrid: false,
    style: {},
    packageId: '',
};

ExploreTileComponent.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    railPosition: PropTypes.number,
    tileType: PropTypes.string,
    tilePosition: PropTypes.number,
    railId: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showGrid: PropTypes.bool,
    style: PropTypes.object,
    packageId: PropTypes.string,
};

export default React.memo(ExploreTileComponent);
