import {
    call, put, select, getContext,
    delay,
} from 'redux-saga/effects';
import lodashGet from 'lodash/get';
import AuthenticationUtil from '@airtel-tv/utils/AuthenticationUtil';
import CacheUtil from '@airtel-tv/utils/CacheUtil';
import { regOtpResponseEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { Analytics } from '@airtel-tv/analytics';
import { playbackClearAllAction } from '@airtel-feature/playback/actions/PlaybackActions';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { syncContentClear } from '@airtel-tv/sync/SyncActions';
import { syncContentSaga } from '@airtel-tv/sync/SyncSaga';
import { Crash } from '@airtel-tv/crash-capture';
import { isOnline } from '@airtel-tv/utils/WindowUtil';
import { purgeLayoutData } from '@airtel-feature/layout/actions/LayoutActions';
import { getAuthConfigFromReducer } from '@airtel-tv/redux/StoreListing';
import { clearAddToBillDetails } from '@airtel-feature/addToBill/actions';
import { deviceSessionOutSuccess, updateLoginTriggereed } from '@airtel-feature/auth/AuthAction';
import { takeFirst } from '../../utilities/ReduxSagaUtil';
import {
    generateOtpCall, getTempLoginToken, validateOtpCall, disposeAuthSession, dthRechargeRedirection, happyCodeGeneration,
    generateQrLogin,
    getUserLoggedIn,
} from '../../service/end-points/AuthApiService';
import AuthAction, {
    tempLoginRequestSuccess, logoutRequestSuccess, generateHappyCodeSuccess, showValidationLoader, confirmValidationLoader, errorValidationLoader, triggerLogin,
    isUserOnline,
    showModalComponentAction,
    resetMsisdn,
    generateQrLoginSuccess,
} from './AuthAction';
import UserAction, { userConfigSuccessAction } from '../user/UserAction';
import { CACHE_CONFIGS, MODAL_POPUP } from '../../constants/AppConst';
// import { regOtpResponseEvent } from '../analytics/FunctionalEvents';
// import { Analytics } from '../analytics';
import { getAppId } from '../../utilities/CommonUtil';
// import { successfulLoginGaEvent } from '../../utilities/GaEvents';
import UserStorageUtil from '../user/UserStorageUtil';
import UserInfoUtil from '../../utilities/UserInfoUtil';
import { getUserConfig } from '../../service/end-points/ConfigApiService';
import { PopupConfigFetchAction } from '../app/PopupActions';
import { hideLoadingBar, showLoadingBar, redirectTo } from '../notify/NotifyActions';
import { hideModalComponentAction } from '../modal-popup/ModalPopupActions';
import ErrorHandler from '../error-handler/ErrorHandler';


function* clearAuthDataFromLocal() {
    AuthenticationUtil.clearAuthToken();
    AuthenticationUtil.clearAccessToken();
    AuthenticationUtil.clearUid();
    AuthenticationUtil.clearMsisdnDetected();
    // clear all sunnxt keys on logout
    AuthenticationUtil.clearSunnxtKeys();
    // refresh user config in cache
    yield CacheUtil.clearByName(CACHE_CONFIGS.CRITICAL_PRIORITY_CACHE);
}

function* generateOtp(action) {
    try {
        yield put(showLoadingBar());

        const authConfig = yield select(getAuthConfigFromReducer);
        const deviceUtil = yield getContext('deviceUtil');
        const appId = getAppId();
        const xAtvDid = deviceUtil.getXAtvDid();

        yield call(generateOtpCall, action.msisdn, authConfig.token, authConfig.uid, appId, xAtvDid, action.viaCall);

        yield put({
            type: AuthAction.LOGIN_OTP_GENERATE_SUCCESS,
            msisdn: action.msisdn,
        });
    }
    catch (e) {
        yield put({
            type: AuthAction.ON_LOGIN_ERROR,
            error: e.data ? e.data : e,
            errorCode: e.data ? e.data.errorcode : e,
        });
    }
    finally {
        yield put(hideLoadingBar());
    }
}

function* loginSuccessCallback({ data, deviceUtil, redirectUrl }) {
    try {
        yield put({
            type: AuthAction.LOGIN_OTP_VALIDATION_SUCCESS,
            ...data,
        });

        if (!data.success && data?.deviceResponse?.devices?.length) {
            yield put(showModalComponentAction({
                showModalValue: true,
                componentNameValue: MODAL_POPUP.DEVICE_LIMIT_REACHED,
                overrideCrossButtonValue: true,
                payload: {
                    popUpcustomClass: 'second-pop-up',
                },
            }));
            return;
        }
        // reset layout first then update user config so that user config update will actually update the ui :: reseting layout wont call update as layout container is not listening to whole reducer

        yield put(purgeLayoutData());
        // set user config
        yield put({
            type: UserAction.USER_CONFIG_SUCCESS,
            userConfig: data.userConfig,
        });

        let userLang = lodashGet(data, 'userConfig.userInfo.lang', []);
        if (!userLang || userLang.length < 1) {
            userLang = lodashGet(data, 'userConfig.userConfigResponse.ln', '').split(',');
        }
        UserInfoUtil.setUserLanguage(userLang);


        const { uid, token } = { ...data };

        yield call(syncContentSaga, {
            forceSync: false,
            playbackForced: true,
            uid,
            token,
        });

        yield put(PopupConfigFetchAction({}));
        const userData = yield call(getUserConfig, (deviceUtil));
        const { experimentDetails } = userData;
        Analytics.initialize({
            deviceUtil,
            experimentDetails,
        });
        yield put(userConfigSuccessAction({
            userConfig: userData,
        }));
        yield put(updateLoginTriggereed({ loginTriggered: false }));
        if (redirectUrl) {
            if (redirectUrl.includes('directPlaybackFromHome') && userData.customerType === 'NON_AIRTEL') {
                return;
            }
            yield put(
                redirectTo({ redirectUrl }),
            );
        }

        Crash.initialize(deviceUtil);

        const loginCount = UserInfoUtil.getSuccessfulLoginCount() + 1;
        UserInfoUtil.setSuccessfulLoginCount({ loginCount });
        UserStorageUtil.clearTempLang();
    }
    catch (err) {
        throw Error(err);
    }
}
function* validateOtp(action) {
    const { otp, redirectUrl } = action;
    let data;
    try {
        yield delay(500);
        const deviceUtil = yield getContext('deviceUtil');
        const user = yield select(getAuthConfigFromReducer);
        yield put(showValidationLoader());
        data = yield call(validateOtpCall, user.msisdn, otp, user.token, user.uid, getAppId(), deviceUtil.getXAtvDid());

        if (data.success && !data?.deviceResponse?.devices?.length) {
            yield put(confirmValidationLoader());
        }
        yield delay(3000);
        yield loginSuccessCallback({
            data,
            deviceUtil,
            redirectUrl,
        });
        //     yield put({
        //         type: AuthAction.LOGIN_OTP_VALIDATION_SUCCESS,
        //         ...data,
        //     });

        //     if (!data.success && data?.deviceResponse?.devices?.length) {
        //         // save devices info
        //         // AuthenticationUtil.setLoginDeviceSuccess(data.success);
        //         // AuthenticationUtil.setLoginDeviceResponse(data.deviceResponse);
        //         // call modalpopup
        //         yield put(showModalComponentAction({
        //             showModalValue: true,
        //             componentNameValue: MODAL_POPUP.DEVICE_LIMIT_REACHED,
        //             overrideCrossButtonValue: true,
        //             payload: {
        //                 popUpcustomClass: 'second-pop-up',
        //             },
        //         }));
        //         return;
        //     }
        //     // reset layout first then update user config so that user config update will actually update the ui :: reseting layout wont call update as layout container is not listening to whole reducer

        //     yield put(purgeLayoutData());
        //     // set user config
        //     yield put({
        //         type: UserAction.USER_CONFIG_SUCCESS,
        //         userConfig: data.userConfig,
        //     });

        //     let userLang = lodashGet(data, 'userConfig.userInfo.lang', []);
        //     if (!userLang || userLang.length < 1) {
        //         userLang = lodashGet(data, 'userConfig.userConfigResponse.ln', '').split(',');
        //     }
        //     UserInfoUtil.setUserLanguage(userLang);


        //     const { uid, token } = { ...data };

        //     yield call(syncContentSaga, {
        //         forceSync: false,
        //         playbackForced: true,
        //         uid,
        //         token,
        //     });
        //     // yield put({
        //     //     type: AuthAction.LOGIN_OTP_VALIDATION_SUCCESS,
        //     //     ...data,
        //     // });

        //     // yield put(syncContentInit(
        //     //     {
        //     //         forceSync: true,
        //     //         isDelay: false,
        //     //     },
        //     // ));

        //     yield put(PopupConfigFetchAction({}));
        //     const userData = yield call(getUserConfig, (deviceUtil));
        //     const { experimentDetails } = userData;
        //     Analytics.initialize({
        //         deviceUtil,
        //         experimentDetails,
        //     });
        //     yield put(userConfigSuccessAction({
        //         userConfig: userData,
        //     }));
        //     if (redirectUrl) {
        //         if (redirectUrl.includes('directPlaybackFromHome') && userData.customerType === 'NON_AIRTEL') {
        //             return;
        //         }
        //         yield put(
        //             redirectTo({ redirectUrl }),
        //         );
        //     }

        //     // refersh user config in cache
        //     // TODO: find a solution
        //     // commented by Ashutosh - this is causing bug in safari 11.0, unable to set localStorage item msisdnDetected
        //     // yield CacheUtil.clearByName(CACHE_CONFIGS.CRITICAL_PRIORITY_CACHE);

        //     // TODO: ask ashu why getting user config again when login call is getting user config
        //     // yield put({
        //     //     type: UserAction.USER_CONFIG_INIT,
        //     // });

        //     Crash.initialize(deviceUtil);
        //     // if (data.userConfig) {
        //     // change user language
        //     // const { lg } = data.userConfig.userProperties || LANGUAGES.ENGLISH;
        //     // getLanguageFile({ languageCode: lg }).then(languages => LanguageProvider(languages));
        //     // }
        //     regOtpResponseEvent('success');
        //     // const programTypeTitle = getLocationPathName().split('/')[1] || '';
        //     // successfulLoginGaEvent({
        //     //     programTypeTitle,
        //     // });
        //     const loginCount = UserInfoUtil.getSuccessfulLoginCount() + 1;
        //     UserInfoUtil.setSuccessfulLoginCount({ loginCount });
        //     UserStorageUtil.clearTempLang();

    // // successfulLoginCountGaEvent(loginCount);
    }
    catch (e) {
        yield put({
            type: AuthAction.ON_LOGIN_ERROR,
            error: e.data ? e.data : e,
            errorCode: e.data ? e.data.errorcode : e,
        });
        yield put(errorValidationLoader());
        AuthenticationUtil.clearMsisdnDetected();
        if (otp) {
            regOtpResponseEvent('failure');
        }
    }
}

function* deviceSessionOut(action) {
    try {
        const { deviceId, triggerLogin: loginAfterLogout = false } = action;
        const authInfo = yield select(getAuthConfigFromReducer);
        const deviceUtil = yield getContext('deviceUtil');
        // sync continue watching data
        // yield call(syncContentSaga, {
        //     forceSync: true,
        //     isDelay: false,
        // });
        // disposing the token on server
        yield call(disposeAuthSession, {
            uid: authInfo.uid,
            token: authInfo.token,
            deviceUtil,
            deviceId,
        });
        // const { deviceResponse: { devices = [] } } = authInfo;
        // const deviceArr = devices.filter(item => item.deviceId !== deviceId);
        yield put(deviceSessionOutSuccess({
            success: true,
            deviceResponse: {},
        }));
        yield clearAuthDataFromLocal();
        yield put(logoutRequestSuccess());
        yield put(hideModalComponentAction({
            showModalValue: false,
            componentNameValue: MODAL_POPUP.DEVICE_LIMIT_REACHED,
            overrideCrossButtonValue: null,
        }));
        if (loginAfterLogout) {
            yield put(isUserOnline());
            yield put(resetMsisdn());
            yield put(triggerLogin({ loginTriggered: true }));
        }
    }

    catch (e) {
        // need other handling, toast?
        console.error(e);
        let error = e;
        if (!isOnline()) {
            error = {
                data: { errorcode: ERROR_CODES.LOCAL1003 },
            };
        }
        ErrorHandler({
            code: error?.data?.errorcode,
            errortitle: error?.data?.errortitle,
        });
    }
}

function* loginGetToken(action) {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const authConfig = yield select(getAuthConfigFromReducer);

        const { token, uid } = authConfig;

        if (!token || !uid) {
            const response = yield call(getTempLoginToken, deviceUtil, getAppId());
            yield put({
                type: AuthAction.LOGIN_REQUEST_SUCCESS,
                ...response,
            });
        }

        if (action.msisdn) {
            yield put({
                type: AuthAction.LOGIN_OTP_GENERATE_INIT,
                msisdn: action.msisdn,
            });
        }
    }
    catch (e) {
        let error = e;

        if (!isOnline()) {
            error = {
                data: { errorcode: ERROR_CODES.LOCAL1003 },
            };
        }

        yield put({
            type: AuthAction.ON_LOGIN_ERROR,
            error: error.data ? error.data : error,
        });
        AuthenticationUtil.clearMsisdnDetected();
        regOtpResponseEvent('failure');
    }
}

function* logoutUser({ redirectUrl = null, isLoginTriggered = false, deviceId }) {
    try {
        const authInfo = yield select(getAuthConfigFromReducer);
        const deviceUtil = yield getContext('deviceUtil');
        // sync continue watching data
        yield call(syncContentSaga, {
            forceSync: true,
            isDelay: false,
        });
        // disposing the token on server
        yield call(disposeAuthSession, {
            uid: authInfo.uid,
            token: authInfo.token,
            deviceUtil,
            deviceId,
        });

        // CLEAR LOCAL STORAGE DATA
        yield clearAuthDataFromLocal();

        yield put(clearAddToBillDetails());
        // *** clear playback data
        yield put(playbackClearAllAction());

        yield put(purgeLayoutData());

        // *** HERE IS YOUR LOGOUT STARTED
        yield put({
            type: AuthAction.LOGOUT_REQUEST,
        });

        // clear sync data
        yield put(syncContentClear({ clearLocal: false }));

        // *** RE-LOGIN FOR A TEMP TOKEN
        const loginResponse = yield call(getTempLoginToken, deviceUtil, getAppId());
        yield put(tempLoginRequestSuccess(loginResponse));
        // *** GET USER CONFIG
        // *** RESET LAYOUT
        // *** SET NEW USER CONFIG
        // yield fetchUserConfigSaga();

        const tempLangFromResponse = lodashGet(loginResponse, 'userConfig.userContentProperties.ln', '');
        UserInfoUtil.setUserLanguage(tempLangFromResponse.split(','));

        const initConfig = JSON.parse(`{
            "subscriptions": [],
            "eligibleOffers": [],
            "availablePlans": [],
            "htoken": "",
            "customerType": "",
            "xlucisveUser": false,
            "showEmailConfirmation": false,
            "updateEmailError": null,
            "updateLanguageError": null,
            "preferredPartnerPlans": []
        }`);
        const userInfo = {
            uid: '',
            msisdn: '',
            name: '',
            email: '',
            lang: tempLangFromResponse.split(','),
            dob: 0,
            gender: '',
            currSeg: '',
            new: true,
        };
        const tempUserConfig = {
            ...loginResponse.userConfig,
            ...initConfig,
            userInfo,
        };
        // for temp login userConfig in login response
        yield put({
            type: UserAction.USER_CONFIG_SUCCESS,
            userConfig: tempUserConfig,
        });
        // *** HERE IS YOUR LOGOUT SUCCESSFULLY
        yield put(logoutRequestSuccess());
        Analytics.initialize({ deviceUtil });
        if (isLoginTriggered) {
            yield put(triggerLogin({ loginTriggered: true }));
        }

        // redirect
        if (redirectUrl) {
            yield put(redirectTo({ redirectUrl }));
        }
    }
    catch (e) {
        console.error(e);
    }
    finally {
        // show Logout Toast Message
    }
}

// clear data from local but not from store
function* logoutUserSilent() {
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const authInfo = yield select(getAuthConfigFromReducer);
        // disposing the token on server
        yield call(disposeAuthSession, {
            uid: authInfo.uid,
            token: authInfo.token,
            deviceUtil,
        });

        yield clearAuthDataFromLocal();

        // clear sync data
        yield put(syncContentClear({ clearLocal: false }));
        yield put(purgeLayoutData());
    }
    catch (e) {
        console.error(e);
    }
}

function* dthSubscriptionPlan(action) {
    const { key } = action;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const authInfo = yield select(getAuthConfigFromReducer);
        const { uid, token } = authInfo;
        const rechargeUrl = yield call(dthRechargeRedirection, {
            uid,
            token,
            deviceUtil,
            key,
        });
        yield put({
            type: AuthAction.DTH_SUBSCRIPTION_SUCCESS,
            rechargeUrl: rechargeUrl.url,
        });
    }

    catch (e) {
        yield put({
            type: AuthAction.ON_LOGIN_ERROR,
            errorCode: e.data ? e.data.errorcode : e,
        });
    }
    finally {
        yield put(hideLoadingBar());
    }
}

function* generateHappyCode(action) {
    const deviceUtil = yield getContext('deviceUtil');
    const { uid, token } = action;

    try {
        const payload = yield call(happyCodeGeneration, {
            uid,
            token,
            deviceUtil,
        });

        yield put(generateHappyCodeSuccess(payload));
    }
    catch (e) {
        console.error('error', e);
    }
}

function* generateQrLoginCode() {
    yield put(showLoadingBar());
    const deviceUtil = yield getContext('deviceUtil');
    try {
        const data = yield call(generateQrLogin, {
            deviceUtil,
        });

        yield put(generateQrLoginSuccess({ payload: data }));
    }
    catch (e) {
        yield put({
            type: AuthAction.ON_LOGIN_ERROR,
            error: e.data ? e.data : e,
            errorCode: e.data ? e.data.errorcode : e,
        });
    }
    finally {
        yield put(hideLoadingBar());
    }
}

function* checkUserLoggedIn(action) {
    const { redirectUrl, qrId } = action;
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const data = yield call(getUserLoggedIn, {
            deviceUtil,
            qrId,
        });
        if (data.success && !data?.deviceResponse?.devices?.length) {
            // yield put(showValidationLoader());
            // delay(200);
            yield put(confirmValidationLoader());
        }
        yield delay(3000);
        yield loginSuccessCallback({
            data,
            deviceUtil,
            redirectUrl,
        });
    }
    catch (err) {
        console.error('err', err);
        const { data } = err?.data || {};
        const { errorcode } = data || {};
        // if (errorcode !== 'ATV331') {
        //     ErrorHandler(err);
        // }
    }
}

export default [
    takeFirst(AuthAction.LOGIN_OTP_GENERATE_INIT, generateOtp),
    takeFirst(AuthAction.LOGIN_OTP_VALIDATION_INIT, validateOtp),
    takeFirst(AuthAction.LOGIN_REQUEST_INIT, loginGetToken),
    takeFirst(AuthAction.LOGOUT_REQUEST_INIT, logoutUser),
    takeFirst(AuthAction.LOGOUT_REQUEST_INIT_SILENT, logoutUserSilent),
    takeFirst(AuthAction.DTH_SUBSCRIPTION_INIT, dthSubscriptionPlan),
    takeFirst(AuthAction.HAPPY_CODE_GENERATE_INIT, generateHappyCode),
    takeFirst(AuthAction.DEVICE_SESSION_OUT, deviceSessionOut),
    takeFirst(AuthAction.GENERATE_QR_LOGIN_CODE, generateQrLoginCode),
    takeFirst(AuthAction.CHECK_USER_LOGGED_IN, checkUserLoggedIn),
];
