import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import LazyLottieFactory from '@airtel-tv/ui-lib/atoms/lottie/LottieFactory';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS, EVENT_SOURCE_NAME, IMAGE_PATHS } from '@airtel-tv/constants';
import { LanguageProviderUtil } from '@airtel-tv/utils/LanguageProviderUtil';
import AnalyticsButtonComponent from '@airtel-tv/analytics/AnalyticsButtonComponent';
import { onInstallClick } from '@airtel-tv/utils/GlobalUtil';
import { popUpVisibleEvent } from '@airtel-tv/analytics/FunctionalEvents';

const LoginSuccess = (props) => {
    const { navigateHomeCallback, sourcePage, qrCode } = props;
    const {
        TV_LOGIN_SUCCESSFULL = 'TV Login Successful',
        OR = 'OR',
        ENJOY_WATCHING_ON_APP = 'Enjoy watching movie & shows on mobile and tablet via app',
        GET_IT_ON = 'Get it on',
        DOWNLOAD_ON_THE = 'Download on the',
        GOOGLE_PLAY = 'Google Play',
        APPLE_STORE = 'Apple Store',
        GO_HOME = 'Go Home',
    } = LanguageProviderUtil.getLanguage();

    const IMAGE_SETTINGS = { 300: 15 };

    const DOWNLOAD_APP = useMemo(() => ({
        PLAY_STORE: {
            id: 'play_store',
            action: 'DOWNLOAD_PLAY_STORE',
            get_it_text: GET_IT_ON,
            appName: GOOGLE_PLAY,
            icon: IMAGE_PATHS.GOOGLE_PLAY,
        },
        APPLE_STORE: {
            id: 'apple_store',
            action: 'DOWNLOAD_APP_STORE',
            get_it_text: DOWNLOAD_ON_THE,
            appName: APPLE_STORE,
            icon: IMAGE_PATHS.APPLE_STORE,
        },
    }), []);

    const installApp = (item) => {
        onInstallClick({ clickItem: item?.action });
    };

    useEffect(() => {
        popUpVisibleEvent({
            source_name: EVENT_SOURCE_NAME.TV_LOGIN_SUCCESSFULL,
            asset_name: ANALYTICS_ASSETS.TV_LOGIN_SUCCESSFUL,
            source_page: sourcePage,
            qr_code: qrCode,
        });
    }, []);

    return (
        <>
            {/* <ThumborImage
                src={IMAGE_PATHS.SUCCESS_CONFETTI}
                imageSettings={IMAGE_SETTINGS}
                className="success-confetti"
            /> */}
             <ThumborImage
                src={IMAGE_PATHS.SUCCESS_LOGIN_BG}
                imageSettings={IMAGE_SETTINGS}
                className = " success-login-bg"
            />
            <LazyLottieFactory
                lottieName="CONFETTI"
                loop={1}
                customStyleObj={{}}
                containerStyleObj={{}}
                containerClassName="success-login-img"
                play
            />
            <div className="success-qr-container">

                <div className="success-qr-wrapper">
                    <ThumborImage
                        src={IMAGE_PATHS.CHECK_SUCCESS}
                        imageSettings={IMAGE_SETTINGS}
                        className="success-round-icon"
                        onClick={navigateHomeCallback}
                    />
                    <span className="login-device-text">{TV_LOGIN_SUCCESSFULL}</span>

                    <span className="or-text">
                        <hr className="or-text-line left" />
                        {OR?.toLowerCase()}
                        <hr className="or-text-line right" />
                    </span>


                    <div className="success-gradient-box">
                        <ThumborImage
                            src={IMAGE_PATHS.XSTREAM_PLAY_ICON}
                            imageSettings={IMAGE_SETTINGS}
                        />
                        <span className="enjoy-text">{ENJOY_WATCHING_ON_APP}</span>
                        <div className="action-btn-area">
                            {Object.values(DOWNLOAD_APP).map(item => (
                                <AnalyticsButtonComponent
                                    id={item?.id}
                                    onClick={() => installApp(item)}
                                    className="download-btn"
                                    meta={{
                                        source_name: EVENT_SOURCE_NAME.TV_LOGIN_SUCCESSFULL,
                                        action: item?.id,
                                        source_page: sourcePage,
                                    }}
                                >
                                    <ThumborImage
                                        src={item?.icon}
                                        imageSettings={IMAGE_SETTINGS}
                                        className="download-icon"
                                    />
                                    <div className="text-wrapper">
                                        <span className="title">{item?.get_it_text}</span>
                                        <span className="text">{item?.appName}</span>
                                    </div>
                                </AnalyticsButtonComponent>
                            ))}
                        </div>

                    </div>

                    <AnalyticsButtonComponent
                        onClick={navigateHomeCallback}
                        className="go-home-btn"
                        meta={{
                            source_name: EVENT_SOURCE_NAME.TV_LOGIN_SUCCESSFULL,
                            action: ANALYTICS_ACTIONS.GO_HOME,
                            source_page: sourcePage,
                        }}
                    >
                        {GO_HOME}
                    </AnalyticsButtonComponent>

                </div>
            </div>
        </>
    );
};

LoginSuccess.defaultProps = {
    sourcePage: '',
    qrCode: '',
    navigateHomeCallback: () => {},
};

LoginSuccess.propTypes = {
    sourcePage: PropTypes.string,
    qrCode: PropTypes.string,
    navigateHomeCallback: PropTypes.func,
};
export default LoginSuccess;
