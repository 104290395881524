import React from 'react';
import PropTypes from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
import withWatchlistDataHOC from '../../hoc/withWatchlistDataHOC';
import WatchListToggleButtonComponent from '../WatchListToggleButtonComponent';
import LockOverlayComponent from '../overlay/LockOverlayComponent';
import isContentLockedHOC from '../../hoc/isContentLockedHOC';
import { ANALYTICS_ACTIONS, ANALYTICS_ASSETS } from '@airtel-tv/constants';
import SOSUtil from '@airtel-tv/utils/SourceOfStreamUtil';
// import { tileClickGaEvent } from '../../../../utilities/GaEvents';

const LandscapeCardTileGridComponent = (props) => {
    const {
        imageUrl,
        tileCtaActionUrl,
        genre,
        title,
        cpId,
        id,
        railPosition,
        tileType,
        tilePosition,
        railId,
        logoUrl,
        // segment,
        imageSettings,
        channelLogoUrl,
        watchListToggleButtonClick,
        inWatchList,
        contentDetails,
        isLocked,
        onSubscribeButtonClick,
    } = props;
    const sourceName = SOSUtil.getRouteSourceNameMappedFromCMS('');
    const tileAnalyticsMeta = {
        cp_name: cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        source_name: sourceName,
    };

    const addToWatchlistAnalyticsMeta = {
        cp_name: cpId,
        content_id: id,
        action: inWatchList ? ANALYTICS_ACTIONS.REMOVE_FROM_WATCHLIST : ANALYTICS_ACTIONS.ADD_TO_WATCHLIST_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        asset_name: ANALYTICS_ASSETS.WATCHLIST,
        source_name: sourceName,
    };

    const hoverLayer = !isLocked ? (
        <div className="overlay">
            <div className="watchlist">
                <WatchListToggleButtonComponent
                    contentDetails={contentDetails}
                    inWatchList={inWatchList}
                    watchListToggleButtonClick={watchListToggleButtonClick}
                    addToWatchlistAnalyticsMeta={addToWatchlistAnalyticsMeta}
                />
            </div>
            <div className="card-center-play-icon">
                <i className="icon40 icon-player-play" />
            </div>
            <div className="card-bottom-left-details">
                <h5 className="title">{title}</h5>
                <div className="title-tagline">
                    <span>{genre}</span>
                </div>
            </div>
        </div>
    ) : (
        <LockOverlayComponent
            contentDetails={contentDetails}
            onBtnClick={onSubscribeButtonClick}
        />
    );

    return (
        <div
            className="cards cards-landscape cards-landscape-grid cards-landscape-grid--large contain-layout"
            style={{ '--aspect-ratio': 0.56 }}
        >
            <AnalyticsLinkComponent
                to={tileCtaActionUrl}
                meta={tileAnalyticsMeta}
                title={title}
                // onClick={() => tileClickGaEvent(id)}
            >
                <ThumborImage
                    className="card-img"
                    src={imageUrl}
                    alt={title}
                    imageSettings={imageSettings}
                />
                <div className="card-title d-flex justify-content-between align-items-bottom">
                    <div className="title">
                        {(logoUrl || channelLogoUrl) ? (
                            <ThumborImage
                                title=""
                                className="logo"
                                alt={cpId}
                                src={logoUrl || channelLogoUrl}
                            />
                        ) : null}
                    </div>
                </div>

                {/* {
                    segment && segment === TAGS.PREMIUM_TAG
                        ? (
                            <div className="premium-label">
                                <span className="premium-label__text">Premium</span>
                                <i className="icon16 icon-premium" />
                            </div>
                        ) : null
                } */}
                <div className="card-title d-none">
                    <h5 className="title">{title}</h5>
                </div>
                {hoverLayer}
            </AnalyticsLinkComponent>
        </div>
    );
};

LandscapeCardTileGridComponent.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    cpId: '',
    genre: '',
    id: '',
    railPosition: 0,
    tileType: '',
    railId: '',
    tilePosition: 0,
    logoUrl: '',
    // segment: '',
    channelLogoUrl: '',
    isLocked: false,
};

LandscapeCardTileGridComponent.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    cpId: PropTypes.string,
    genre: PropTypes.string,
    id: PropTypes.string,
    railPosition: PropTypes.number,
    railId: PropTypes.string,
    tileType: PropTypes.string,
    tilePosition: PropTypes.number,
    logoUrl: PropTypes.string,
    // segment: PropTypes.string,
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    channelLogoUrl: PropTypes.string,
    watchListToggleButtonClick: PropTypes.func.isRequired,
    inWatchList: PropTypes.bool.isRequired,
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isLocked: PropTypes.bool,
    onSubscribeButtonClick: PropTypes.func.isRequired,
};

export default React.memo(isContentLockedHOC(withWatchlistDataHOC(LandscapeCardTileGridComponent)));
