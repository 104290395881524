import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { triggerLogin } from '../AuthAction';
import { LOGIN_SOURCE } from '../../../constants/EventsConst';


const LoginComponent = () => {
    const dispatch = useDispatch();
    const appConfig = useSelector(state => state.appConfig);
    const { isAuthenticated } = appConfig;
    useEffect(() => {
        if (!isAuthenticated) {
            dispatch(triggerLogin({
                loginTriggered: true,
                sourceOfLoginTrigger: LOGIN_SOURCE.DEFAULT_LOGIN,
                redirectUrl: window.location.pathname + window.location.search,
            }));
        }
    }, []);

    return (
        <div> </div>
    );
};

export default LoginComponent;
