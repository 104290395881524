import React from 'react';
import PropTypes from 'prop-types';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import withWatchlistDataHOC from '../../hoc/withWatchlistDataHOC';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';
// import { addOpacityToRGB } from '../../../../utilities/CommonUtil';
import ResizeHOC from '../../hoc/ResizeHOC';
import { convertRailTitle } from '../../../../utilities/CommonUtil';
import { useRef } from 'react';
import { getElementById, withDeviceUtil } from '@airtel-tv/utils';
import { ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';
// import { CHAR_LIMIT } from '../../../../constants/AppConst';
// import { tileClickGaEvent } from '../../../../utilities/GaEvents';

const PartnerChannelTileComponent = (props) => {
    const partnerChannelTileRef = useRef();

    const {
        tileCtaActionUrl,
        cpId,
        tileType,
        id,
        railPosition,
        tilePosition,
        railId,
        showGrid,
        theme,
        cpDetails,
        imageSettings,
        isMaxTabWidth,
        coverImgUrl,
        railTitle,
        contentDetails,
        deviceUtil: { isMobile },
        packageId,
        hoveredTile,
        setHoveredTile,
        useCoverImageUrl = false,
    } = props;
    const clearTimeOutIds = {};
    if (!cpDetails) {
        return null;
    }
    const {
        title, logoUrl, tagLine, cpSquareIcon,
    } = cpDetails;

    const currentTileId = `atm_cp-tile-${railId}-${railPosition}-${tilePosition + 1}`;
    const { images = {} } = contentDetails;
    const rectangular = images.RECTANGULAR;
    const tileAnalyticsMeta = {
        cp_name: !cpId ? '' : cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        package_id: packageId,
    };
    const {
        brandColor, textColor, gradientStartColor, gradientEndColor, brandColorRGB, cardColorRGB, cardColor,
    } = theme;

    // const tileColorRGB = !isMaxTabWidth ? cardColorRGB : brandColorRGB;
    const tileColor = isMaxTabWidth ? cardColor : brandColor;

    const tileGradientMobile = gradientEndColor && gradientStartColor ? `radial-gradient(circle at center, ${gradientStartColor} 0%, ${gradientEndColor} 100%)` : tileColor;
    // const tileGradientDesktop = tileColor;

    const customStyle = {
        background: tileGradientMobile, // !isMaxTabWidth ? tileGradientDesktop : tileGradientMobile, // to be reviewed
        color: `${textColor}`,
    };

    // const tagLineLimit = !isMaxTabWidth ? CHAR_LIMIT.DESKTOP_TAGLINE_LIMIT : CHAR_LIMIT.MOBILE_TAGLINE_LIMIT;

    // const renderTagLine = tagLine && tagLine.length > tagLineLimit ? `${tagLine.slice(0, tagLineLimit)}...` : tagLine;

    /* eslint-disable */

    const addHoverClass = () => {
        if (isMobile()) {
            return;
        }
        const tId = setTimeout(() => {
            if (typeof setHoveredTile === 'function') {
                setHoveredTile(currentTileId);
                return;
            }
            const ele = partnerChannelTileRef && partnerChannelTileRef.current;
            ele && ele?.classList.add('zoom-explore');
            ele && ele?.classList.remove('zoom-out-explore');
            const railTarget = getElementById(railId);
            railTarget && railTarget?.classList.add("padding-booster");
            const targetParent = getElementById(`${ELEMENT_ID.EXPLORE_CHANNEL_RAIL}`);
            targetParent && targetParent?.classList.add('shrink-space');
        }, 500);
        clearTimeOutIds[`atm_cp-tile-${convertRailTitle(railTitle)}-${tilePosition + 1}`] = tId;
    };

    const removeHoverClass = () => {

        const tId = clearTimeOutIds[`atm_cp-tile-${convertRailTitle(railTitle)}-${tilePosition + 1}`];
        if (tId) {
            clearTimeout(tId);
            delete clearTimeOutIds[`atm_cp-tile-${convertRailTitle(railTitle)}-${tilePosition + 1}`];
        }
        if (typeof setHoveredTile === 'function') {
            setHoveredTile('');
            return;
        }
        const ele = partnerChannelTileRef && partnerChannelTileRef.current;
        ele && ele?.classList?.add('zoom-out-explore');

        ele && ele?.classList?.remove('zoom-explore');
        const railTarget = getElementById(railId);
        railTarget && railTarget?.classList.remove("padding-booster");
        const targetParent = getElementById(`${ELEMENT_ID.EXPLORE_CHANNEL_RAIL}`);
        targetParent && targetParent?.classList.remove('shrink-space');
    };

    const thumborUrl =  useCoverImageUrl ? coverImgUrl : rectangular || cpSquareIcon || logoUrl;

    return (
        <div 
            id={`atm_cp-tile-${convertRailTitle(railTitle)}-${tilePosition + 1}`}
            className={`partner-channel-tile ${showGrid ? 'partner-channel-grid-tile' : ''} contain-layout ${hoveredTile === currentTileId ? 'zoom-explore' : 'zoom-out-explore'}${useCoverImageUrl ? ' cp-tile-rectangle' : ''}`}
            onMouseEnter={() => addHoverClass()}
            onMouseLeave={() => removeHoverClass()}
            ref={partnerChannelTileRef}
        >
            <AnalyticsLinkComponent
                to={tileCtaActionUrl}
                meta={tileAnalyticsMeta}
                title={title}
            // onClick={() => tileClickGaEvent(id)}
            >
                <div className="cover-card">
                    
                    {/* {cpSquareIcon ?  */}
                    {/* <ThumborImage
                    imageSettings={{
                        1000:450,
                        500:450,
                        320: 250
                    }}
                    src={cpSquareIcon}
                /> */}
                    {/* :  */}
                    <div style={!thumborUrl && !useCoverImageUrl ? customStyle : undefined} className="card-info">
                        {/* <div className="card-logo"> */}
                            <ThumborImage
                                imageSettings={{
                                    1000:450,
                                    500:450,
                                    320: 250
                                }}
                                src={thumborUrl}
                                alt={!thumborUrl ? title : ''}
                            />
                        {/* </div> */}
                    </div> 
                    {/* } */}
                </div>
            </AnalyticsLinkComponent>
        </div>
    );
    /* eslint-enable */
};
PartnerChannelTileComponent.defaultProps = {
    tileCtaActionUrl: '',
    cpId: '',
    id: '',
    tileType: '',
    railPosition: 0,
    tilePosition: 0,
    railId: '',
    isMaxTabWidth: false,
    coverImgUrl: '',
    cpDetails: null,
    packageId: '',
};
PartnerChannelTileComponent.propTypes = {
    tileCtaActionUrl: PropTypes.string,
    cpId: PropTypes.string,
    id: PropTypes.string,
    railPosition: PropTypes.number,
    tileType: PropTypes.string,
    tilePosition: PropTypes.number,
    railId: PropTypes.string,
    showGrid: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    cpDetails: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    coverImgUrl: PropTypes.string,
    isMaxTabWidth: PropTypes.bool,
    contentDetails: PropTypes.object.isRequired,
    packageId: PropTypes.string,
    hoveredTile: PropTypes.string.isRequired,
    setHoveredTile: PropTypes.func.isRequired,

};
export default React.memo(withDeviceUtil(withWatchlistDataHOC(ResizeHOC(PartnerChannelTileComponent))));
