import { AnalyticsLinkComponent } from '@airtel-tv/analytics';
import { getTimeLabel } from '@airtel-tv/utils/GlobalUtil';
import React, { memo } from 'react';

const PYWTileDetailContentComponent = (props) => {
    const {
        ageRating,
        title,
        genres,
        description,
        durSec,
        tileCtaActionUrl,
        isMobileDevice,
        tileAnalyticsMeta,
    } = props;

    return (
        <>
            <div className="details">
                {isMobileDevice ? (
                    <AnalyticsLinkComponent
                        to={tileCtaActionUrl}
                        meta={tileAnalyticsMeta}
                        className="title"
                    >
                        <div style={{
                            display: 'flex',
                        }}
                        >
                            <div style={{
                                flexGrow: 1,
                                overflow: 'hidden',
                            }}
                            >
                                {title}
                            </div>
                            <div className="arrow-btn" />
                        </div>
                    </AnalyticsLinkComponent>
                )
                    : <h1 className="title">{title}</h1>
                }
                <p className="content-tags">
                    {ageRating && <span className="age-rating">{ageRating}</span>}
                    <span className="content-meta">
                        {/* <span className="rating">IMDb 6.6</span> */}
                        {genres && genres.length ? (
                            <span className="genre-container">
                                {(genres || []).map(genre => <span className="genre">{genre}</span>)}
                            </span>
                        ) : null }
                        {/* {!!durSec && (
                            <span className="duration text-16 text-normal line-height-24 genre mobile-text-12">
                                {getTimeLabel({
                                    duration: durSec,
                                    label: ' hr | mins',
                                    separator: ' ',
                                    durationType: 'sec',
                                })}
                            </span>
                        )} */}
                    </span>
                </p>
                {description && <p className="description">{description}</p>}
            </div>
        </>
    );
};

export default memo(PYWTileDetailContentComponent);
