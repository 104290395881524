// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PlaybackSecurityLabelComponent{position:absolute;color:#fff;text-shadow:1px 0 0 #000,0 -1px 0 #000,0 1px 0 #000,-1px 0 0 #000;font-size:1.5rem;z-index:1000;margin-right:auto;margin-bottom:auto}", ""]);
// Exports
exports.locals = {
	"mobileWidth": "767px",
	"mediaLandscapeHeight": "500px",
	"mediaLandscapeWidth": "990px",
	"tabletLargeWidth": "1200px"
};
module.exports = exports;
