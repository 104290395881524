import React from 'react';

const FooterTileComponent = () => (
    <>
        <hr className="top" />
        <hr className="center" />
        <hr className="bottom" />
    </>
);

export default React.memo(FooterTileComponent);
