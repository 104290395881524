import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import lodashGet from 'lodash/get';
import { connect } from 'react-redux';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { BUTTON_IMAGE_TITLE, TILE_TYPES } from '@airtel-tv/constants/LayoutConstants';
import { AnalyticsLinkComponent } from '@airtel-tv/analytics/AnalyticsLinkComponent';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import SubscriptionUtil from '@airtel-tv/utils/SubscriptionUtil';
import { DEEPLINK_INGRESS_KEYS, ELEMENT_ID } from '@airtel-tv/constants/GlobalConst';
import TileTag from '@airtel-tv/ui-lib/atoms/TileTag/TileTag';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import { MODAL_POPUP, PROGRAM_TYPES, IMAGE_PATHS } from '../../../../constants/AppConst';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import ProgressBarComponent from '../ProgressBarComponent';
import { continueWatchingTileTitle } from '../../../../utilities/CommonUtil';
import isContentLockedHOC from '../../hoc/isContentLockedHOC';
import LockOverlayComponent from '../overlay/LockOverlayComponent';
import LanguageProvider from '../../../../providers/LanguageProvider';
import { hideModalComponentAction, showModalComponentAction } from '../../../modal-popup/ModalPopupActions';
import { getSourceName } from '@airtel-tv/utils/GlobalUtil';
// import { getElementById } from '../../../../utilities/WindowUtil';

const ContinueWatchingTileComponent = (props) => {
    const {
        imageUrl,
        tileCtaActionUrl,
        title,
        contentDetails,
        progressPercentage,
        imageSettings,
        id,
        showProgressBar,
        onRemoveIconClick,
        tileType,
        cpId,
        railPosition,
        tilePosition,
        railId,
        episodeNumber,
        tvShowName,
        seriesId,
        channelName,
        channelId,
        key,
        isLocked,
        onSubscribeButtonClick,
        showNextEpisode,
        deviceUtil: { isMobile },
        isListRail,
        packageId,
        tileTagId,
        tagDetails,
        remainingTime,
        railElementId,
        isWatchListTile,
        zionTileId = '',
        showModalComponentActionDispatch,
        hideModalComponentActionDispatch,
        isXppUser,
        userContentProperties,
        maxAllowedPlaybackTime,
        pageId,
        location,
    } = props;

    const tileRef = useRef();
    const {
        durSec, ageRating, genres, programType, episodeNum, episodeSeasonNum,
        appInAppRedirection = false,
    } = contentDetails;
    const LANGUAGE = LanguageProvider();
    const { VIEW_INFO = 'View Info' } = LANGUAGE;
    const tagConfig = tagDetails && tileTagId ? tagDetails[tileTagId] : null;
    const clearTimeOutIds = {};
    const tileAnalyticsMeta = {
        cp_name: cpId,
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        tile_type: tileType,
        rail_id: railId,
        asset_position: tilePosition,
        content_name: title,
        episode_number: episodeNumber,
        series_name: tvShowName,
        series_id: seriesId,
        channel_name: channelName,
        channel_id: channelId,
        package_id: packageId,
        tile_tag: tileTagId || 'none',
        tileId: zionTileId || 'none',
    };

    const hoverLayer = !isLocked ? (
        <div className="overlay">
            <div className="card-center-play-icon">
                <i className="icon40 icon-player-play" />
            </div>
        </div>
    ) : (
        <LockOverlayComponent
            contentDetails={contentDetails}
            onBtnClick={onSubscribeButtonClick}
        />
    );
    const LANGUAGES = LanguageProvider();

    const tileTitle = continueWatchingTileTitle(contentDetails);
    const wrapKey = key || `${id}-${railId}`;
    const ID_BY_TILE = useMemo(() => ({
        [TILE_TYPES.X_PYW_RAIL]: 'atm_pyw_watchlist-content-',
        [TILE_TYPES.WATCHLIST]: 'atm_watchlist-content-',
        [TILE_TYPES.CONTINUE_WATCHING]: 'atm_cw-content-',
    }), []);
    const tileElementId = `${ID_BY_TILE[tileType]}${tilePosition + 1}`;


    // const getDuration = (duration) => {
    //     let durInMin = 0;
    //     let durInHr = 0;
    //     if (duration) {
    //         durInMin = Math.ceil(duration / 60);
    //     }
    //     if (durInMin > 60) {
    //         durInHr = Math.floor(durInMin / 60);
    //         return `${durInHr}h  ${durInMin % 60}m`;
    //     }
    //     return `${durInMin}m`;
    // };

    const openOptionModal = () => {
        const { showModalComponentActionDispatch } = props;
        showModalComponentActionDispatch({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.CONTINUE_WATCHING_OPTION,
            payload: {
                contentDetails,
                onRemoveIconClick,
                tileCtaActionUrl,
                tileType,
            },
        });
    };


    const getContentTitle = () => {
        const { programType = undefined, tvShowName } = contentDetails;
        if (programType === PROGRAM_TYPES.EPISODE) {
            return tvShowName;
        }
        return title;
    };

    const getRailElementId = () => {
        if (railElementId) {
            return railElementId;
        }
        // if (tileType === TILE_TYPES.WATCHLIST || tileType === TILE_TYPES.X_PYW_RAIL) {
        //     return isListRail ? ELEMENT_ID.LIST_RAIL : tileType === TILE_TYPES.X_PYW_RAIL ? ELEMENT_ID.PYW_WATCHLIST_RAIL : ELEMENT_ID.WATCHLIST_RAIL;
        // }
        return ELEMENT_ID.CONT_WATCHING_RAIL;
    };

    const addHoverClass = () => {
        if (isMobile()) {
            return;
        }
        // const ele = getElementById(getId());
        const eleId = getRailElementId();
        const tId = setTimeout(() => {
            if (tileRef?.current) {
                tileRef?.current.classList.add('zoom-cw');
                tileRef?.current.classList.remove('zoom-out-cw');
                const railTarget = document.getElementById(railId);
                railTarget?.classList?.add('padding-booster');
                const targetParent = document.getElementById(eleId);
                targetParent?.classList?.add('shrink-space');
            }
        }, 500);
        clearTimeOutIds[eleId + contentDetails.id] = tId;
    };

    const removeHoverClass = () => {
        const eleId = getRailElementId();
        const tId = clearTimeOutIds[eleId + contentDetails.id];
        if (tId) {
            clearTimeout(tId);
            delete clearTimeOutIds[tId];
        }
        // const ele = getElementById(getId());
        if (tileRef?.current) {
            tileRef?.current.classList.remove('zoom-cw');
            tileRef?.current.classList.add('zoom-out-cw');
        }
        const railTarget = document.getElementById(railId);
        railTarget?.classList?.remove('padding-booster');
        const targetParent = document.getElementById(eleId);
        targetParent?.classList?.remove('shrink-space');
    };

    const { subscriptionDataById, cpDetailsById, isAuthenticated, deepLinkConfig } = props;
    const chId = SubscriptionUtil.getChannelId({
        cpDetailsById,
        cpId,
    });
    const channelDetails = lodashGet(cpDetailsById, `[${chId}]`, null);
    const subscriptionDetails = lodashGet(subscriptionDataById, `[${chId}]`, null);
    const targetHierarchy = lodashGet(channelDetails, 'targetHierarchy', 0);
    const isSubscribed = SubscriptionUtil.isSubscribed(subscriptionDetails, targetHierarchy);
    let watchNowCtaActionUrl = (isAuthenticated && isSubscribed && tileCtaActionUrl) ? `${tileCtaActionUrl}?showPlayback=true&fromHome=true` : tileCtaActionUrl;
    watchNowCtaActionUrl = (isWatchListTile && isMobile()) || (appInAppRedirection || cpId === 'AMAZON_PRIME') ? tileCtaActionUrl : watchNowCtaActionUrl;

    return (
        <div className="position-holder-for-rail">
            <section
                className={`
                    episodeWrapper contain-layout continue-tile-wrapper 
                    ${isWatchListTile ? 'watchlistWrapper' : 'cwWrapper'}
                `}
                key={wrapKey}
                onMouseEnter={addHoverClass}
                onMouseLeave={removeHoverClass}
                id={tileElementId}
                ref={tileRef}
                atm-name={getContentTitle()}
            >
                {showNextEpisode && !tagConfig ? (
                    <div className="next-episode">
                        {lodashGet(LANGUAGES, 'NEXT_EPISODE_TXT', 'NEXT_EPISODE_TXT')}
                    </div>
                ) : null}
                <AnalyticsLinkComponent
                    to={watchNowCtaActionUrl}
                    onClick={(event) => {
                        if (!ScopedWebviewUtil.canPlayPlayback(maxAllowedPlaybackTime, userContentProperties, contentDetails)) {
                            event.stopPropagation();
                            event.preventDefault();
                            showModalComponentActionDispatch({
                                showModalValue: true,
                                componentNameValue: MODAL_POPUP.DOWNLOAD_XSTREAM_APP_POPUP,
                                overrideCrossButtonValue: true,
                                payload: {
                                    contentDetails,
                                    isSubscribed: isXppUser,
                                    pageId,
                                    meta: {
                                        popup_id: 'download_hard_popup',
                                        source_name: getSourceName(location.pathname),
                                        content_category: 'Content',
                                    },
                                    notNowBtnHandler: () => {
                                        hideModalComponentActionDispatch({
                                            showModalValue: false,
                                            componentNameValue: null,
                                            overrideCrossButtonValue: false,
                                        });
                                    },
                                    downloadNowBtnHandler: () => {
                                        ScopedWebviewUtil.navigateToDeepLinkOnCta(deepLinkConfig, DEEPLINK_INGRESS_KEYS.HARD_POPUP);
                                    },
                                },
                            });
                        }
                    }}
                    className="position-relative"
                    meta={tileAnalyticsMeta}
                // title={title}
                >
                    <div
                        className={`episodeCards ${tagConfig && Object.keys(tagConfig)?.length ? 'no-left-border-radius' : ''}`}
                    >

                        <div className="tile-center-play">
                            <ThumborImage
                                src="/static/play_ic.svg"
                            />
                        </div>
                        <ThumborImage
                            src={imageUrl}
                            alt={title}
                            className="imgCard w-100 h-100 full-height"
                            imageSettings={{
                                320: 400,
                                500: 800,
                                1000: 1000,
                            }}
                        />
                        <div className="gradient-aliner-cw">
                            <div className="gradient-for-img" />
                        </div>


                        {isWatchListTile || TILE_TYPES.CONTINUE_WATCHING || !isMobile() ? (
                            <button
                                type="button"
                                className="close-btn"
                                aria-label="Close"
                                onClick={(e) => {
                                    onRemoveIconClick(contentDetails);
                                    e.preventDefault();
                                }}
                            >
                                <img
                                    src={IMAGE_PATHS.WHITE_CLOSE_BUTTON}
                                    title={BUTTON_IMAGE_TITLE.CLOSE}
                                    alt="Close"
                                    loading="lazy"
                                />
                            </button>
                        ) : null}
                        {/* {hoverLayer} */}
                        <div className="continue-tile-time-wrap">
                            <div className="cw-tile-progress-holder">
                                { ((!isMobile() && remainingTime) || (isMobile() && remainingTime && programType === PROGRAM_TYPES.EPISODE)) && <p className="time">{`${remainingTime} left`}</p> }
                                <div className="tags-wrapper">
                                    { ageRating && (
                                        <div
                                            className="age-rating  text-normal-12 line"
                                            id={`atm_age-rating-cw-${tilePosition + 1}`}
                                        >
                                            { ageRating }
                                        </div>
                                    ) }
                                    {/* <div className="tag yellow-text line">IMDb 6.6</div> */}
                                    {genres && genres.length > 0 && (
                                        genres.slice(0, 2).map((genre, i) => (
                                            <div
                                                className={`tag  text-normal-12  ${i < genres.length - 1 ? 'dot' : ''}`}
                                                id={`atm_genre-cw-${tilePosition + 1}`}
                                            >
                                                { genre }
                                            </div>
                                        ))
                                    )}
                                </div>

                                <ProgressBarComponent
                                    showProgressBar={showProgressBar}
                                    progressPercentage={remainingTime ? progressPercentage : 100}
                                />
                            </div>
                            <AnalyticsLinkComponent
                                to={tileCtaActionUrl}
                                meta={tileAnalyticsMeta}
                                title={title}
                                className="view-info-for-cw"
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="10"
                                        cy="10"
                                        r="7.25"
                                        stroke="#E8EAED"
                                        strokeWidth="1.5"
                                    />
                                    <rect
                                        x="9"
                                        y="8"
                                        width="1.7"
                                        height="6"
                                        rx="0.85"
                                        fill="#E8EAED"
                                    />
                                    <circle
                                        cx="9.85742"
                                        cy="6.42871"
                                        r="1"
                                        fill="#E8EAED"
                                    />
                                </svg>
                                {VIEW_INFO}
                            </AnalyticsLinkComponent>
                        </div>
                        {tagConfig ? <TileTag tagConfig={tagConfig} /> : ''}
                    </div>
                    {/* <h6 className="text-truncate">{tileTitle}</h6> */}
                    {tileType === TILE_TYPES.CONTINUE_WATCHING ? (
                        <div className="dur-title-mweb">
                            <p className="time">
                                {programType === PROGRAM_TYPES.EPISODE && episodeNum && episodeSeasonNum ? (
                                    <>
                                        {`S${episodeSeasonNum} E${episodeNum} `}
                                        {remainingTime ? (
                                            <>
                                                <span className="disc-separator" />
                                                {` ${remainingTime} left`}
                                            </>
                                        ) : null}
                                    </>
                                ) : (
                                    remainingTime ? `${remainingTime} left` : ''
                                )}
                            </p>
                            <div onClick={(e) => {
                                e.preventDefault();
                                openOptionModal();
                            }}
                            >
                                <svg
                                    width="4"
                                    height="13"
                                    viewBox="0 0 4 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2.3998 3.2C3.2798 3.2 3.9998 2.48 3.9998 1.6C3.9998 0.72 3.2798 0 2.3998 0C1.5198 0 0.799805 0.72 0.799805 1.6C0.799805 2.48 1.5198 3.2 2.3998 3.2ZM2.3998 4.8C1.5198 4.8 0.799805 5.52 0.799805 6.4C0.799805 7.28 1.5198 8 2.3998 8C3.2798 8 3.9998 7.28 3.9998 6.4C3.9998 5.52 3.2798 4.8 2.3998 4.8ZM2.3998 9.6C1.5198 9.6 0.799805 10.32 0.799805 11.2C0.799805 12.08 1.5198 12.8 2.3998 12.8C3.2798 12.8 3.9998 12.08 3.9998 11.2C3.9998 10.32 3.2798 9.6 2.3998 9.6Z"
                                        fill="#A3A7AE"
                                    />
                                </svg>
                            </div>
                        </div>
                    ) : null}
                </AnalyticsLinkComponent>
            </section>
        </div>

    );
};
ContinueWatchingTileComponent.defaultProps = {
    imageUrl: '',
    tileCtaActionUrl: '',
    title: '',
    tileType: '',
    cpId: '',
    railPosition: 0,
    tilePosition: 0,
    railId: '',
    episodeNumber: 1,
    tvShowName: '',
    seriesId: '',
    channelId: '',
    channelName: '',
    key: undefined,
    progressPercentage: null,
    isLocked: false,
    isListRail: false,
    packageId: '',
    remainingTime: '',
};
ContinueWatchingTileComponent.propTypes = {
    imageUrl: PropTypes.string,
    tileCtaActionUrl: PropTypes.string,
    title: PropTypes.string,
    progressPercentage: PropTypes.number,
    id: PropTypes.string.isRequired,
    onRemoveIconClick: PropTypes.func.isRequired,
    tileType: PropTypes.string,
    cpId: PropTypes.string,
    railPosition: PropTypes.number,
    tilePosition: PropTypes.number,
    railId: PropTypes.string,
    episodeNumber: PropTypes.number,
    tvShowName: PropTypes.string,
    seriesId: PropTypes.string,
    channelName: PropTypes.string,
    channelId: PropTypes.string,
    showProgressBar: PropTypes.bool.isRequired,
    key: PropTypes.string,
    contentDetails: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    imageSettings: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    isLocked: PropTypes.bool,
    onSubscribeButtonClick: PropTypes.func.isRequired,
    showModalComponentActionDispatch: PropTypes.func.isRequired,
    subscriptionDataById: PropTypes.object.isRequired,
    cpDetailsById: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    isListRail: PropTypes.bool,
    packageId: PropTypes.string,
    tagDetails: PropTypes.object,
    tileTagId: PropTypes.string,
    remainingTime: PropTypes.string,
};

function mapStateToProps(state, props) {
    const {
        appConfig: { cpDetailsById, tagDetails, maxAllowedPlaybackTime, deepLinkConfig },
        userConfig: { subscriptionDataById, userContentProperties, userContentProperties: { oSeg, ut } },
        authConfig,
    } = state;

    const isXppUser = !!(oSeg || '').split(',').filter((item) => item === 'xstreampremium_paid' || item === 'xstreampremium_telco_paid').length;

    return {
        tagDetails,
        cpDetailsById,
        subscriptionDataById,
        isAuthenticated: authConfig.isAuthenticated,
        isXppUser,
        userContentProperties,
        maxAllowedPlaybackTime,
        deepLinkConfig,
    };
}

export default connect(
    mapStateToProps,
    {
        showModalComponentActionDispatch: showModalComponentAction,
        hideModalComponentActionDispatch: hideModalComponentAction,
    },
)(React.memo(withDeviceUtil(isContentLockedHOC(ContinueWatchingTileComponent))));
