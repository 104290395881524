import React, { useState, useEffect } from 'react';
import lodashIsEqual from 'lodash/isEqual';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import TileLoader from '../components/loader/TileLoader';
import IntersectionObservableHOC from './IntersectionObservableHOC';
import { INTERSECTION_OBSERVER_THRESHOLD } from '../../../constants/AppConst';

function HorizontalLazyLoaderHOC(props) {
    const [
        isRemainingContentVisible,
        toggleRemainingContentVisible,
    ] = useState(false);

    const {
        tileElements, deviceUtil, tileLimit, railKey, styleProps,
    } = props;
    const [
        defaultTileElement,
        updateDefaultTileElement,
    ] = useState(tileElements.length ? [
        tileElements.slice(0, tileLimit),
    ] : []);

    const [
        tileToBeAdded,
        updateTileToBeAdded,
    ] = useState([]);


    useEffect(() => {
        const firstTileElement = tileElements.slice(0, tileLimit);
        const remainingTiles = tileElements.slice(tileLimit);
        if (isRemainingContentVisible && !lodashIsEqual(defaultTileElement, tileElements)) {
            updateDefaultTileElement(tileElements);
        }
        else if (!isRemainingContentVisible && !lodashIsEqual(defaultTileElement, firstTileElement)) {
            updateDefaultTileElement(firstTileElement);
            updateTileToBeAdded(remainingTiles);
        }
    }, [
        tileElements,
        tileLimit,
        defaultTileElement,
        isRemainingContentVisible,
    ]);

    if (deviceUtil.isBot()) {
        return tileElements;
    }

    if (!isRemainingContentVisible && tileLimit >= tileElements.length) {
        toggleRemainingContentVisible(true);
    }

    function showRemainingTiles() {
        toggleRemainingContentVisible(true);
        updateDefaultTileElement([
            ...defaultTileElement,
            ...tileToBeAdded,
        ]);
    }

    const loaderElem = <TileLoader style={styleProps} />;
    const openingTile = (
        <IntersectionObservableHOC
            fetchMore={showRemainingTiles}
            hasMore={!isRemainingContentVisible}
            loaderElem={loaderElem}
            startPageNumber={1}
            threshold={INTERSECTION_OBSERVER_THRESHOLD.NOT_LOADED}
        >
            { defaultTileElement}
        </IntersectionObservableHOC>
    );

    return openingTile;
}

export default withDeviceUtil(React.memo(HorizontalLazyLoaderHOC));
