import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from '@airtel-tv/utils';
import { ThumborImage } from '@airtel-tv/ui-lib/atoms';
import { getShowTime } from '../../../../utilities/CommonUtil';
import { IMAGE_PATHS } from '../../../../constants/AppConst';
import LanguageProvider from '../../../../providers/LanguageProvider';
import { ANALYTICS_ACTIONS } from '../../../../constants/AnalyticsConst';
import {AnalyticsLinkComponent} from '@airtel-tv/analytics/AnalyticsLinkComponent';

const ChannelScheduleTileComponent = (props) => {
    const {
        program: {
            contentDetails,
            imageUrl,
            tileCtaActionUrl,
        },
        tileType,
        railTitle,
        railPosition,
        tilePosition,
        packageId,
    } = props;

    const {
        title,
        startTime,
        endTime,
        currentProgramTime,
        hasCatchup,
        id,
    } = contentDetails;

    const LANGUAGE = LanguageProvider();

    const date = new DateTime(startTime).format('dd DD MM');
    const durationContent = `${date} | ${getShowTime(startTime)} - ${getShowTime(endTime)}`;

    let tag = LANGUAGE.LIVE;
    if (startTime < currentProgramTime) {
        tag = LANGUAGE.JUST_AIRED;
    }
    else if (startTime > currentProgramTime) {
        tag = LANGUAGE.UPCOMING;
    }

    const tileAnalyticsMeta = {
        content_id: id,
        action: ANALYTICS_ACTIONS.TILE_CLICK,
        rail_position: railPosition,
        rail_title: railTitle,
        tile_type: tileType,
        rail_id: '',
        asset_position: tilePosition,
        package_id: packageId,
    };

    return (
        <div className="channelSchedule_parentwrapper contain-layout">
            {
                !hasCatchup || tag === LANGUAGE.LIVE
                    ? (
                        <div className={`channelSchedule_wrapper ${tag === LANGUAGE.LIVE ? 'schedule_episod_live' : 'disable_justpassed_episod'}`}>
                            <div className="episode-image-wrapper">
                                <ThumborImage
                                    class="channel_episod_image"
                                    src={imageUrl}
                                    alt={title}
                                />
                            </div>

                            <div className="schedule_episod_detail">
                                <div>
                                    {
                                        tag !== LANGUAGE.LIVE
                                            ? <p className="schedule_episod_status">{tag}</p>
                                            : (
                                                <p className="schedule_episod_status">
                                                    <ThumborImage
                                                        src={IMAGE_PATHS.LIVE_TAG}
                                                        alt=""
                                                    />
                                                </p>
                                            )
                                    }
                                    <p
                                        id="rail-content-name"
                                        className="schedule_episod_name"
                                    >
                                        {title}
                                    </p>
                                    <p
                                        id="rail-content-timing"
                                        className="schedule_episod_timings"
                                    >
                                        {durationContent}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <AnalyticsLinkComponent
                            to={tileCtaActionUrl}
                            title={title}
                            meta={tileAnalyticsMeta}
                        >
                            <div className={`channelSchedule_wrapper ${tag === LANGUAGE.LIVE ? 'schedule_episod_live' : ''}`}>
                                <div className="episode-image-wrapper">
                                    <ThumborImage
                                        class="channel_episod_image"
                                        src={imageUrl}
                                        alt={title}
                                    />
                                </div>

                                <div className="schedule_episod_detail">
                                    <div>
                                        {
                                            tag !== LANGUAGE.LIVE
                                                ? <p className="schedule_episod_status">{tag}</p>
                                                : (
                                                    <p className="schedule_episod_status">
                                                        <ThumborImage
                                                            src={IMAGE_PATHS.LIVE_TAG}
                                                            alt=""
                                                        />
                                                    </p>
                                                )
                                        }
                                        <p className="schedule_episod_name">{title}</p>
                                        <p className="schedule_episod_timings">{durationContent}</p>
                                    </div>

                                    <div className="whitechevron_wrapper">
                                        <ThumborImage
                                            src={IMAGE_PATHS.TILE_LINK_ARROW}
                                            alt=""
                                            className="channelscedule_whiteChevron"
                                        />
                                    </div>

                                </div>

                            </div>
                        </AnalyticsLinkComponent>
                    )
            }
        </div>
    );
};

ChannelScheduleTileComponent.defaultProps = {
    program: {},
    railTitle: '',
    railPosition: 0,
    tilePosition: 0,
    packageId: '',
};

ChannelScheduleTileComponent.propTypes = {
    program: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
    tileType: PropTypes.string.isRequired,
    railTitle: PropTypes.string,
    railPosition: PropTypes.number,
    tilePosition: PropTypes.number,
    packageId: PropTypes.string,
};

export default React.memo(ChannelScheduleTileComponent);
